body {
    background-color: #ecf0f5;
    font-size: .9375rem;
    overflow-x: hidden;
    color: #373a3c;
    font-family: "Open Sans", sans-serif;
    background-attachment: fixed;
    background-image: linear-gradient(rgba(255, 255, 255, 0.3) 200px, rgba(255, 255, 255, 0.9) 0%)
}

ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0
}

*:focus {
    outline: none
}

a {
    font-size: 13px;
    color: #37474f;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

@media only screen and (min-width: 1400px) {
    a {
        font-size: 14px
    }
}

a:focus, a:hover {
    text-decoration: none;
    color: #448aff
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 600
}

h1 {
    font-size: 2.5rem
}

h2 {
    font-size: 2rem
}

h3 {
    font-size: 1.75rem
}

h4 {
    font-size: 1.5rem
}

h5 {
    font-size: 1rem
}

h6 {
    font-size: 0.875rem
}

p {
    font-size: 13px
}

b, strong {
    font-weight: 600
}

code {
    padding: 0 3px;
    border-radius: 3px
}

.img-radius {
    border-radius: 50%
}

.media-left {
    padding-right: 20px
}

.main-body .page-wrapper {
    padding: 0.6rem;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s
}

.card {
    border-radius: 0.25rem;
    -webkit-box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3);
    box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3);
    border: none;
    margin-bottom: 30px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.card .card-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    padding: 15px 20px;
    border-radius: 0 0 5px 5px
}

.card .card-header {
    background-color: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    padding: 20px;
    position: relative
}

.card .card-header.borderless {
    border-bottom: 0px solid transparent
}

.card .card-header.borderless + .card-block, .card .card-header.borderless + .card-block-big, .card .card-header.borderless + .card-block-small {
    padding-top: 0
}

.card .card-header .card-header-left {
    display: inline-block
}

.card .card-header .card-header-right {
    right: 10px;
    top: 13px;
    display: inline-block;
    float: right;
    padding: 7px 0;
    position: absolute
}

@media only screen and (max-width: 575px) {
    .card .card-header .card-header-right {
        display: none
    }
}

.card .card-header .card-header-right i {
    margin: 0 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: #37474f;
    line-height: 1
}

.card .card-header .card-header-right .card-option {
    width: 35px;
    height: 20px;
    overflow: hidden;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out
}

.card .card-header .card-header-right .card-option li {
    display: inline-block
}

.card .card-header span {
    display: block;
    font-size: 13px;
    margin-top: 5px
}

.card .card-header h5 {
    margin-bottom: 0;
    color: #37474f;
    font-size: 0.9375rem;
    font-weight: 600;
    display: inline-block;
    margin-right: 10px;
    line-height: 1.1
}

.card .card-block {
    padding: 1.25rem
}

.card .card-block.card-p-0 {
    padding: 1.25rem 0 0 0
}

.card .card-block.p-b-0 {
    padding-bottom: 0
}

.card .card-block table tr {
    padding-bottom: 20px
}

.card .card-block .sub-title {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px
}

.card .card-block code {
    background-color: #eee;
    margin: 5px;
    display: inline-block
}

.card .card-block .dropdown-menu {
    top: 38px
}

.card .card-block p {
    line-height: 1.4
}

.card .card-block a.dropdown-item {
    margin-bottom: 0;
    font-size: 14px;
    -webkit-transition: 0.25s;
    transition: 0.25s
}

.card .card-block a.dropdown-item:active, .card .card-block a.dropdown-item .active {
    background-color: #448aff
}

.card .card-block.remove-label i {
    margin: 0;
    padding: 0
}

.card .card-block.button-list span.badge {
    margin-left: 5px
}

.card .card-block .dropdown-menu {
    background-color: #fff;
    padding: 0
}

.card .card-block .dropdown-menu .dropdown-divider {
    background-color: #ddd;
    margin: 3px 0
}

.card .card-block .dropdown-menu > a {
    padding: 10px 16px;
    line-height: 1.429
}

.card .card-block .dropdown-menu > li > a:focus, .card .card-block .dropdown-menu > li > a:hover {
    background-color: rgba(202, 206, 209, 0.5)
}

.card .card-block .dropdown-menu > li:first-child > a:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px
}

.card .card-block .badge-box {
    padding: 10px;
    margin: 12px 0
}

.card .card-block-big {
    padding: 1.5625rem
}

.card .card-block-small {
    padding: 0.9375rem
}

.card-footer {
    background: transparent
}

.pcoded .card.full-card {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    -webkit-box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.44);
    box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.44);
    border-radius: 0px;
    width: calc(100vw - 17px);
    height: 100vh
}

.pcoded .card.full-card.card-load {
    position: fixed
}

.pcoded .card.card-load {
    position: relative;
    overflow: hidden
}

.pcoded .card.card-load .card-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 999
}

.pcoded .card.card-load .card-loader i {
    margin: 0 auto;
    color: #448aff;
    font-size: 20px
}

.rotate-refresh {
    -webkit-animation: mymove 0.8s infinite linear;
    animation: mymove 0.8s infinite linear;
    display: inline-block
}

@-webkit-keyframes mymove {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes mymove {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.page-header {
    background-size: cover;
    position: relative;
    border-radius: 0;
    padding-bottom: 50px
}

.page-header h4 {
    font-weight: 400
}

.page-header .page-block {
    padding: 1.25rem
}

.page-header .page-block .breadcrumb {
    background: transparent;
    margin-bottom: 0;
    padding: 0
}

.page-header .page-block .breadcrumb a {
    font-size: 14px;
}

.page-header .page-block .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
    font-family: "feather";
    content: "\e844";
}

.page-header .page-block .breadcrumb .breadcrumb-item:last-child a {
    font-weight: 600
}

@media only screen and (max-width: 768px) {
    .page-header .page-block .breadcrumb {
        float: left;
        margin-top: 10px
    }
}

.sub-title {
    border-bottom: 1px solid rgba(204, 204, 204, 0.35);
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #2c3e50
}

.blockquote {
    border-left: 0.25rem solid #eceeef;
    padding: 0.5rem 1rem
}

.blockquote.blockquote-reverse {
    text-align: right;
    padding-right: 1rem;
    border-right: 0.25rem solid #eceeef;
    border-left: none
}

.typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6 {
    margin: 0 0 20px
}

.typography small {
    margin-left: 10px;
    font-weight: 500;
    color: #777
}

.card-block.list-tag ul li {
    display: block;
    float: none;
    margin-bottom: 5px
}

.card-block.list-tag ol li {
    margin-bottom: 5px
}

.inline-order-list {
    margin-top: 50px
}

.inline-order-list h4, .inline-order-list p {
    margin-bottom: 0
}

.card-block ul li.list-inline-item {
    display: inline-block;
    float: left
}

.modal {
    z-index: 1050
}

.modal .modal-dialog {
    pointer-events: all
}

.bd-example .modal {
    display: block;
    position: inherit;
    background-color: #2C3E50;
    margin-bottom: 20px
}

.card .overflow-container h5 {
    margin-bottom: 5px
}

.button-page .card-block a.nav-link {
    margin-bottom: 0
}

.sweet-alert button.confirm {
    background-color: #448aff
}

.sweet-alert .sa-input-error {
    top: 23px
}

.accordion-msg {
    display: block;
    color: #222222;
    padding: 14px 20px;
    border-top: 1px solid #ddd;
    font-weight: 500;
    cursor: pointer
}

.accordion-msg:focus, .accordion-msg:hover {
    text-decoration: none;
    outline: none
}

.faq-accordion .accordion-desc {
    padding: 20px
}

.accordion-desc {
    color: #222222;
    padding: 0 20px 20px
}

#color-accordion .accordion-desc {
    margin-top: 14px
}

.ui-accordion-header-icon {
    float: right;
    font-size: 20px
}

.accordion-title {
    margin-bottom: 0
}

.accordion-block {
    padding: 0
}

.accordion-block p {
    margin-bottom: 0
}

.color-accordion-block a.ui-state-active, .color-accordion-block a:focus, .color-accordion-block a:hover {
    color: #fff;
    background: #29548E
}

a.bg-default:focus, a.bg-default:hover {
    background-color: #f0f0f0;
    color: #fff
}

a.bg-primary:focus, a.bg-primary:hover {
    background-color: #7af;
    color: #fff
}

a.bg-success:focus, a.bg-success:hover {
    background-color: #b5d98b;
    color: #fff
}

a.bg-info:focus, a.bg-info:hover {
    background-color: #08e3ff;
    color: #fff
}

a.bg-warning:focus, a.bg-warning:hover {
    background-color: #ffcf8a;
    color: #fff
}

a.bg-danger:focus, a.bg-danger:hover {
    background-color: #ff8585;
    color: #fff
}

.tab-list p {
    padding: 10px
}

.tab-with-img i {
    position: absolute;
    padding: 5px
}

.tab-icon {
    margin-bottom: 30px
}

.tab-icon i {
    padding-right: 10px
}

.tab-below {
    border-top: 1px solid #ddd;
    border-bottom: none
}

.tab-below.nav-tabs .nav-link.active {
    border-color: transparent #ddd #ddd #ddd
}

.tab-below .nav-item {
    margin-top: -2px
}

.tab-below.nav-tabs .nav-link {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.card-header ~ .tab-icon .tab-with-img .sub-title i {
    right: 10px
}

.tab-with-img .nav-link {
    position: relative
}

.tabs-left, .tabs-right {
    min-width: 120px;
    vertical-align: top;
    width: 150px
}

.tabs-left, .tabs-left-content, .tabs-right, .tabs-right-content {
    display: table-cell
}

.nav-tabs.tabs-left .slide {
    height: 35px;
    width: 4px;
    bottom: 15px
}

.nav-tabs.tabs-right .slide {
    height: 35px;
    width: 4px;
    bottom: 15px;
    right: 0
}

.md-tabs.tabs-left .nav-item, .md-tabs.tabs-right .nav-item, .tabs-left .nav-item, .tabs-right .nav-item {
    width: 100%;
    position: relative
}

.md-tabs {
    position: relative
}

.md-tabs .nav-item + .nav-item {
    margin: 0
}

.md-tabs .nav-link {
    border: none;
    color: #37474f
}

.md-tabs .nav-item {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    position: relative
}

.md-tabs .nav-link:focus, .md-tabs .nav-link:hover {
    border: none
}

.md-tabs .nav-item .nav-link.active ~ .slide {
    opacity: 1;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out
}

.md-tabs .nav-item .nav-link ~ .slide {
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out
}

.md-tabs .nav-item.open .nav-link, .md-tabs .nav-item.open .nav-link:focus, .md-tabs .nav-item.open .nav-link:hover, .md-tabs .nav-link.active, .md-tabs .nav-link.active:focus, .md-tabs .nav-link.active:hover {
    color: #448aff;
    border: none;
    background-color: transparent;
    border-radius: 0
}

.md-tabs .nav-item a {
    padding: 20px 0;
    color: #37474f
}

.nav-tabs .slide {
    background: #448aff;
    width: 100%;
    height: 4px;
    position: absolute;
    -webkit-transition: left 0.3s ease-out;
    transition: left 0.3s ease-out;
    bottom: 0
}

.nav-tabs .slide .nav-item.show .nav-link, .nav-tabs .slide .nav-link {
    color: #448aff
}

.img-tabs img {
    width: 100px;
    margin: 0 auto
}

.img-tabs a {
    opacity: 0.5;
    -webkit-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s
}

.img-tabs a span i {
    height: 25px;
    width: 25px;
    border-radius: 100%;
    bottom: 10px;
    right: 70px
}

.img-tabs a img {
    border: 3px solid
}

.img-tabs a.active {
    opacity: 1;
    -webkit-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s
}

.img-tabs .nav-item:first-child {
    border-bottom: none
}

#pc-left-panel-menu {
    margin-bottom: 20px
}

.img-circle {
    border-radius: 50%
}

.b-none {
    border: none
}

.table-primary, .table-primary > td, .table-primary > th {
    background-color: #4680FE
}

.table-responsive {
    display: inline-block;
    width: 100%;
    overflow-x: auto
}

.table.table-xl td, .table.table-xl th {
    padding: 1.25rem 2rem
}

.table.table-lg td, .table.table-lg th {
    padding: 0.9rem 2rem
}

.table.table-de td, .table.table-de th {
    padding: 0.75rem 2rem
}

.table.table-sm td, .table.table-sm th {
    padding: 0.6rem 2rem
}

.table.table-xs td, .table.table-xs th {
    padding: 0.4rem 2rem
}

.table-columned > tbody > tr > td:first-child, .table-columned > tbody > tr > th:first-child {
    border-left: 0
}

.table-columned > tfoot > tr > td:first-child, .table-columned > tfoot > tr > th:first-child {
    border-left: 0
}

.table-columned > tbody > tr > td, .table-columned > tbody > tr > th {
    border: 0;
    border-left: 1px solid #ddd
}

.table-columned > tfoot > tr > td, .table-columned > tfoot > tr > th {
    border: 0;
    border-left: 1px solid #ddd
}

.table-border-style {
    padding: 0
}

.table-border-style .table {
    margin-bottom: 0
}

.table > thead > tr > th {
    border-bottom-color: #ccc
}

.table-borderless tbody tr td, .table-borderless tbody tr th {
    border: 0
}

.table-bordered > thead > tr.border-solid > td, .table-bordered > thead > tr.border-solid > th {
    border-bottom-width: 2px
}

.table-bordered > thead > tr.border-solid:first-child > td, .table-bordered > thead > tr.border-solid:first-child th {
    border-bottom-width: 2px
}

.table-bordered > thead > tr.border-double > td, .table-bordered > thead > tr.border-double > th {
    border-bottom-width: 3px;
    border-bottom-style: double
}

.table-bordered > thead > tr.border-double:first-child > td, .table-bordered > thead > tr.border-double:first-child th {
    border-bottom-width: 3px;
    border-bottom-style: double
}

.ie-warning {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    background: #000000;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    font-family: "Courier New", Courier, monospace;
    padding: 50px 0
}

.ie-warning p {
    font-size: 17px
}

.ie-warning .iew-container {
    min-width: 1024px;
    width: 100%;
    height: 200px;
    background: #fff;
    margin: 50px 0
}

.ie-warning .iew-download {
    list-style: none;
    padding: 30px 0;
    margin: 0 auto;
    width: 720px
}

.ie-warning .iew-download > li {
    float: left;
    vertical-align: top
}

.ie-warning .iew-download > li > a {
    display: block;
    color: #000;
    width: 140px;
    font-size: 15px;
    padding: 15px 0
}

.ie-warning .iew-download > li > a > div {
    margin-top: 10px
}

.ie-warning .iew-download > li > a:hover {
    background-color: #eee
}

.progress {
    height: 1rem
}

.progress .progress-bar {
    height: 100%;
    color: inherit
}

.loader-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 9999
}

.loader-bg .loader-bar {
    position: fixed;
    height: 5px;
    width: 80%;
    top: 0;
    left: 0;
    background: #2196f3;
    -webkit-animation: barfiller 2.0s;
    animation: barfiller 2.0s
}

@-webkit-keyframes barfiller {
    0% {
        width: 0
    }
    25% {
        width: 20%
    }
    50% {
        width: 40%
    }
    70% {
        width: 60%
    }
    100% {
        width: 80%
    }
}

@keyframes barfiller {
    0% {
        width: 0
    }
    25% {
        width: 20%
    }
    50% {
        width: 40%
    }
    70% {
        width: 60%
    }
    100% {
        width: 80%
    }
}

@-moz-document url-prefix() {
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background: #FFF url("../images/select-arrow.png") no-repeat 95% center
    }
}

.generic-card-block code {
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px
}

.generic-image-body li {
    padding: 0 20px;
    display: inline-block
}

.generic-image-body code {
    display: block
}

.generic-image-body img {
    display: block;
    margin: 10px auto 20px
}

.p-0 {
    padding: 0px
}

.p-t-0 {
    padding-top: 0px
}

.p-b-0 {
    padding-bottom: 0px
}

.p-l-0 {
    padding-left: 0px
}

.p-r-0 {
    padding-right: 0px
}

.m-0 {
    margin: 0px
}

.m-t-0 {
    margin-top: 0px
}

.m-b-0 {
    margin-bottom: 0px
}

.m-l-0 {
    margin-left: 0px
}

.m-r-0 {
    margin-right: 0px
}

.p-5 {
    padding: 5px
}

.p-t-5 {
    padding-top: 5px
}

.p-b-5 {
    padding-bottom: 5px
}

.p-l-5 {
    padding-left: 5px
}

.p-r-5 {
    padding-right: 5px
}

.m-5 {
    margin: 5px
}

.m-t-5 {
    margin-top: 5px
}

.m-b-5 {
    margin-bottom: 5px
}

.m-l-5 {
    margin-left: 5px
}

.m-r-5 {
    margin-right: 5px
}

.p-10 {
    padding: 10px
}

.p-t-10 {
    padding-top: 10px
}

.p-b-10 {
    padding-bottom: 10px
}

.p-l-10 {
    padding-left: 10px
}

.p-r-10 {
    padding-right: 10px
}

.m-10 {
    margin: 10px
}

.m-t-10 {
    margin-top: 10px
}

.m-b-10 {
    margin-bottom: 10px
}

.m-l-10 {
    margin-left: 10px
}

.m-r-10 {
    margin-right: 10px
}

.p-15 {
    padding: 15px
}

.p-t-15 {
    padding-top: 15px
}

.p-b-15 {
    padding-bottom: 15px
}

.p-l-15 {
    padding-left: 15px
}

.p-r-15 {
    padding-right: 15px
}

.m-15 {
    margin: 15px
}

.m-t-15 {
    margin-top: 15px
}

.m-b-15 {
    margin-bottom: 15px
}

.m-l-15 {
    margin-left: 15px
}

.m-r-15 {
    margin-right: 15px
}

.p-20 {
    padding: 20px
}

.p-t-20 {
    padding-top: 20px
}

.p-b-20 {
    padding-bottom: 20px
}

.p-l-20 {
    padding-left: 20px
}

.p-r-20 {
    padding-right: 20px
}

.m-20 {
    margin: 20px
}

.m-t-20 {
    margin-top: 20px
}

.m-b-20 {
    margin-bottom: 20px
}

.m-l-20 {
    margin-left: 20px
}

.m-r-20 {
    margin-right: 20px
}

.p-25 {
    padding: 25px
}

.p-t-25 {
    padding-top: 25px
}

.p-b-25 {
    padding-bottom: 25px
}

.p-l-25 {
    padding-left: 25px
}

.p-r-25 {
    padding-right: 25px
}

.m-25 {
    margin: 25px
}

.m-t-25 {
    margin-top: 25px
}

.m-b-25 {
    margin-bottom: 25px
}

.m-l-25 {
    margin-left: 25px
}

.m-r-25 {
    margin-right: 25px
}

.p-30 {
    padding: 30px
}

.p-t-30 {
    padding-top: 30px
}

.p-b-30 {
    padding-bottom: 30px
}

.p-l-30 {
    padding-left: 30px
}

.p-r-30 {
    padding-right: 30px
}

.m-30 {
    margin: 30px
}

.m-t-30 {
    margin-top: 30px
}

.m-b-30 {
    margin-bottom: 30px
}

.m-l-30 {
    margin-left: 30px
}

.m-r-30 {
    margin-right: 30px
}

.p-35 {
    padding: 35px
}

.p-t-35 {
    padding-top: 35px
}

.p-b-35 {
    padding-bottom: 35px
}

.p-l-35 {
    padding-left: 35px
}

.p-r-35 {
    padding-right: 35px
}

.m-35 {
    margin: 35px
}

.m-t-35 {
    margin-top: 35px
}

.m-b-35 {
    margin-bottom: 35px
}

.m-l-35 {
    margin-left: 35px
}

.m-r-35 {
    margin-right: 35px
}

.p-40 {
    padding: 40px
}

.p-t-40 {
    padding-top: 40px
}

.p-b-40 {
    padding-bottom: 40px
}

.p-l-40 {
    padding-left: 40px
}

.p-r-40 {
    padding-right: 40px
}

.m-40 {
    margin: 40px
}

.m-t-40 {
    margin-top: 40px
}

.m-b-40 {
    margin-bottom: 40px
}

.m-l-40 {
    margin-left: 40px
}

.m-r-40 {
    margin-right: 40px
}

.p-45 {
    padding: 45px
}

.p-t-45 {
    padding-top: 45px
}

.p-b-45 {
    padding-bottom: 45px
}

.p-l-45 {
    padding-left: 45px
}

.p-r-45 {
    padding-right: 45px
}

.m-45 {
    margin: 45px
}

.m-t-45 {
    margin-top: 45px
}

.m-b-45 {
    margin-bottom: 45px
}

.m-l-45 {
    margin-left: 45px
}

.m-r-45 {
    margin-right: 45px
}

.p-50 {
    padding: 50px
}

.p-t-50 {
    padding-top: 50px
}

.p-b-50 {
    padding-bottom: 50px
}

.p-l-50 {
    padding-left: 50px
}

.p-r-50 {
    padding-right: 50px
}

.m-50 {
    margin: 50px
}

.m-t-50 {
    margin-top: 50px
}

.m-b-50 {
    margin-bottom: 50px
}

.m-l-50 {
    margin-left: 50px
}

.m-r-50 {
    margin-right: 50px
}

.d-none {
    display: none
}

.d-inline-block {
    display: inline-block
}

.d-inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.b-radius-0 {
    border-radius: 0px
}

.b-radius-5 {
    border-radius: 5px
}

.b-radius-10 {
    border-radius: 10px
}

.f-10 {
    font-size: 10px
}

.f-12 {
    font-size: 12px
}

.f-14 {
    font-size: 14px
}

.f-16 {
    font-size: 16px
}

.f-18 {
    font-size: 18px
}

.f-20 {
    font-size: 20px
}

.f-22 {
    font-size: 22px
}

.f-24 {
    font-size: 24px
}

.f-26 {
    font-size: 26px
}

.f-28 {
    font-size: 28px
}

.f-30 {
    font-size: 30px
}

.f-32 {
    font-size: 32px
}

.f-34 {
    font-size: 34px
}

.f-36 {
    font-size: 36px
}

.f-38 {
    font-size: 38px
}

.f-40 {
    font-size: 40px
}

.f-42 {
    font-size: 42px
}

.f-44 {
    font-size: 44px
}

.f-46 {
    font-size: 46px
}

.f-48 {
    font-size: 48px
}

.f-50 {
    font-size: 50px
}

.f-52 {
    font-size: 52px
}

.f-54 {
    font-size: 54px
}

.f-56 {
    font-size: 56px
}

.f-58 {
    font-size: 58px
}

.f-60 {
    font-size: 60px
}

.f-62 {
    font-size: 62px
}

.f-64 {
    font-size: 64px
}

.f-66 {
    font-size: 66px
}

.f-68 {
    font-size: 68px
}

.f-70 {
    font-size: 70px
}

.f-72 {
    font-size: 72px
}

.f-74 {
    font-size: 74px
}

.f-76 {
    font-size: 76px
}

.f-78 {
    font-size: 78px
}

.f-80 {
    font-size: 80px
}

.f-w-100 {
    font-weight: 100
}

.f-w-200 {
    font-weight: 200
}

.f-w-300 {
    font-weight: 300
}

.f-w-400 {
    font-weight: 400
}

.f-w-500 {
    font-weight: 500
}

.f-w-600 {
    font-weight: 600
}

.f-w-700 {
    font-weight: 700
}

.f-w-800 {
    font-weight: 800
}

.f-w-900 {
    font-weight: 900
}

.f-s-normal {
    font-style: normal
}

.f-s-italic {
    font-style: italic
}

.f-s-oblique {
    font-style: oblique
}

.f-s-initial {
    font-style: initial
}

.f-s-inherit {
    font-style: inherit
}

.text-capitalize {
    text-transform: capitalize
}

.text-uppercase {
    text-transform: uppercase
}

.text-lowercase {
    text-transform: lowercase
}

.text-overline {
    text-decoration: overline
}

.text-line-through {
    text-decoration: line-through
}

.text-underline {
    text-decoration: underline
}

.baseline {
    vertical-align: baseline
}

.sub {
    vertical-align: sub
}

.super {
    vertical-align: super
}

.top {
    vertical-align: top
}

.text-top {
    vertical-align: text-top
}

.middle {
    vertical-align: middle
}

.bottom {
    vertical-align: bottom
}

.text-bottom {
    vertical-align: text-bottom
}

.initial {
    vertical-align: initial
}

.inherit {
    vertical-align: inherit
}

.pos-static {
    position: static
}

.pos-absolute {
    position: absolute
}

.pos-fixed {
    position: fixed
}

.pos-relative {
    position: relative
}

.pos-initial {
    position: initial
}

.pos-inherit {
    position: inherit
}

.o-hidden {
    overflow: hidden
}

.o-visible {
    overflow: visible
}

.o-auto {
    overflow: auto
}

.img-20 {
    width: 20px
}

.img-30 {
    width: 30px
}

.img-40 {
    width: 40px
}

.img-50 {
    width: 50px
}

.img-60 {
    width: 60px
}

.img-70 {
    width: 70px
}

.img-80 {
    width: 80px
}

.img-90 {
    width: 90px
}

.img-100 {
    width: 100px
}

.text-primary {
    color: #448aff
}

.text-warning {
    color: #ffba57
}

.text-default {
    color: #d6d6d6
}

.text-danger {
    color: #ff5252
}

.text-success {
    color: #9ccc65
}

.text-inverse {
    color: #37474f
}

.text-info {
    color: #00bcd4
}

.text-custom {
    color: #64b0f2
}

.text-pink {
    color: #ff7aa3
}

.text-dark {
    color: #2b3d51
}

.text-purple {
    color: #9261c6
}

.text-muted {
    color: #78909c
}

.label {
    border-radius: 2px;
    color: #fff;
    font-size: 12px;
    line-height: 1;
    margin-bottom: 0;
    text-transform: capitalize
}

.label-primary {
    background: #448aff
}

.label-warning {
    background: #ffba57
}

.label-default {
    background: #d6d6d6
}

.label-danger {
    background: #ff5252
}

.label-success {
    background: #9ccc65
}

.label-inverse {
    background: #37474f
}

.label-info {
    background: #00bcd4
}

.label-warning {
    color: #fff
}

.badge-primary {
    background: #448aff
}

.badge-warning {
    background: #ffba57
}

.badge-default {
    background: #d6d6d6
}

.badge-danger {
    background: #ff5252
}

.badge-success {
    background: #9ccc65
}

.badge-inverse {
    background: #37474f
}

.badge-info {
    background: #00bcd4
}

.bg-color-box {
    text-align: center;
    display: inline-block;
    padding: 10px 20px;
    -webkit-box-shadow: 0 0 5px 0 rgba(128, 128, 128, 0.49);
    box-shadow: 0 0 5px 0 rgba(128, 128, 128, 0.49);
    font-weight: 600;
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer
}

.bg-color-box span {
    color: #fff
}

.bg-primary {
    background-color: #448aff;
    color: #fff
}

.bg-warning {
    background-color: #ffba57;
    color: #fff
}

.bg-default {
    background-color: #d6d6d6;
    color: #fff
}

.bg-danger {
    background-color: #ff5252;
    color: #fff
}

.bg-success {
    background-color: #9ccc65;
    color: #fff
}

.bg-inverse {
    background-color: #37474f;
    color: #fff
}

.bg-info {
    background-color: #00bcd4;
    color: #fff
}

.color-primary {
    background-color: #448aff
}

.color-warning {
    background-color: #ffba57
}

.color-default {
    background-color: #d6d6d6
}

.color-danger {
    background-color: #ff5252
}

.color-success {
    background-color: #9ccc65
}

.color-inverse {
    background-color: #37474f
}

.color-info {
    background-color: #00bcd4
}

.loader-primary {
    background-color: #448aff
}

.loader-warning {
    background-color: #ffba57
}

.loader-default {
    background-color: #d6d6d6
}

.loader-danger {
    background-color: #ff5252
}

.loader-success {
    background-color: #9ccc65
}

.loader-inverse {
    background-color: #37474f
}

.loader-info {
    background-color: #00bcd4
}

.nestable-primary {
    background-color: #448aff;
    border-color: #448aff;
    color: #fff
}

.nestable-warning {
    background-color: #ffba57;
    border-color: #ffba57;
    color: #fff
}

.nestable-default {
    background-color: #d6d6d6;
    border-color: #d6d6d6;
    color: #fff
}

.nestable-danger {
    background-color: #ff5252;
    border-color: #ff5252;
    color: #fff
}

.nestable-success {
    background-color: #9ccc65;
    border-color: #9ccc65;
    color: #fff
}

.nestable-inverse {
    background-color: #37474f;
    border-color: #37474f;
    color: #fff
}

.nestable-info {
    background-color: #00bcd4;
    border-color: #00bcd4;
    color: #fff
}

table tbody .border-bottom-primary td, table tbody .border-bottom-primary th, table thead .border-bottom-primary th {
    border-bottom: 1px solid #448aff
}

table tbody .border-bottom-warning td, table tbody .border-bottom-warning th, table thead .border-bottom-warning th {
    border-bottom: 1px solid #ffba57
}

table tbody .border-bottom-default td, table tbody .border-bottom-default th, table thead .border-bottom-default th {
    border-bottom: 1px solid #d6d6d6
}

table tbody .border-bottom-danger td, table tbody .border-bottom-danger th, table thead .border-bottom-danger th {
    border-bottom: 1px solid #ff5252
}

table tbody .border-bottom-success td, table tbody .border-bottom-success th, table thead .border-bottom-success th {
    border-bottom: 1px solid #9ccc65
}

table tbody .border-bottom-inverse td, table tbody .border-bottom-inverse th, table thead .border-bottom-inverse th {
    border-bottom: 1px solid #37474f
}

table tbody .border-bottom-info td, table tbody .border-bottom-info th, table thead .border-bottom-info th {
    border-bottom: 1px solid #00bcd4
}

.table-styling .table-primary, .table-styling.table-primary {
    background-color: #448aff;
    color: #fff;
    border: 3px solid #448aff
}

.table-styling .table-primary thead, .table-styling.table-primary thead {
    background-color: #116aff;
    border: 3px solid #116aff
}

.table-styling .table-warning, .table-styling.table-warning {
    background-color: #ffba57;
    color: #fff;
    border: 3px solid #ffba57
}

.table-styling .table-warning thead, .table-styling.table-warning thead {
    background-color: #ffa524;
    border: 3px solid #ffa524
}

.table-styling .table-default, .table-styling.table-default {
    background-color: #d6d6d6;
    color: #fff;
    border: 3px solid #d6d6d6
}

.table-styling .table-default thead, .table-styling.table-default thead {
    background-color: #bdbdbd;
    border: 3px solid #bdbdbd
}

.table-styling .table-danger, .table-styling.table-danger {
    background-color: #ff5252;
    color: #fff;
    border: 3px solid #ff5252
}

.table-styling .table-danger thead, .table-styling.table-danger thead {
    background-color: #ff1f1f;
    border: 3px solid #ff1f1f
}

.table-styling .table-success, .table-styling.table-success {
    background-color: #9ccc65;
    color: #fff;
    border: 3px solid #9ccc65
}

.table-styling .table-success thead, .table-styling.table-success thead {
    background-color: #83bf3f;
    border: 3px solid #83bf3f
}

.table-styling .table-inverse, .table-styling.table-inverse {
    background-color: #37474f;
    color: #fff;
    border: 3px solid #37474f
}

.table-styling .table-inverse thead, .table-styling.table-inverse thead {
    background-color: #222c31;
    border: 3px solid #222c31
}

.table-styling .table-info, .table-styling.table-info {
    background-color: #00bcd4;
    color: #fff;
    border: 3px solid #00bcd4
}

.table-styling .table-info thead, .table-styling.table-info thead {
    background-color: #008fa1;
    border: 3px solid #008fa1
}

.toolbar-primary .tool-item {
    background: #448aff
}

.toolbar-primary .tool-item.selected, .toolbar-primary .tool-item:hover {
    background: #116aff
}

.toolbar-primary.tool-top .arrow {
    border-color: #448aff transparent transparent
}

.toolbar-primary.tool-bottom .arrow {
    border-color: transparent transparent #448aff
}

.toolbar-primary.tool-left .arrow {
    border-color: transparent transparent transparent #448aff
}

.toolbar-primary.tool-right .arrow {
    border-color: transparent #448aff transparent transparent
}

.btn-toolbar-primary.pressed {
    background-color: #448aff
}

.toolbar-warning .tool-item {
    background: #ffba57
}

.toolbar-warning .tool-item.selected, .toolbar-warning .tool-item:hover {
    background: #ffa524
}

.toolbar-warning.tool-top .arrow {
    border-color: #ffba57 transparent transparent
}

.toolbar-warning.tool-bottom .arrow {
    border-color: transparent transparent #ffba57
}

.toolbar-warning.tool-left .arrow {
    border-color: transparent transparent transparent #ffba57
}

.toolbar-warning.tool-right .arrow {
    border-color: transparent #ffba57 transparent transparent
}

.btn-toolbar-warning.pressed {
    background-color: #ffba57
}

.toolbar-light .tool-item {
    background: #d6d6d6
}

.toolbar-light .tool-item.selected, .toolbar-light .tool-item:hover {
    background: #bdbdbd
}

.toolbar-light.tool-top .arrow {
    border-color: #d6d6d6 transparent transparent
}

.toolbar-light.tool-bottom .arrow {
    border-color: transparent transparent #d6d6d6
}

.toolbar-light.tool-left .arrow {
    border-color: transparent transparent transparent #d6d6d6
}

.toolbar-light.tool-right .arrow {
    border-color: transparent #d6d6d6 transparent transparent
}

.btn-toolbar-light.pressed {
    background-color: #d6d6d6
}

.toolbar-danger .tool-item {
    background: #ff5252
}

.toolbar-danger .tool-item.selected, .toolbar-danger .tool-item:hover {
    background: #ff1f1f
}

.toolbar-danger.tool-top .arrow {
    border-color: #ff5252 transparent transparent
}

.toolbar-danger.tool-bottom .arrow {
    border-color: transparent transparent #ff5252
}

.toolbar-danger.tool-left .arrow {
    border-color: transparent transparent transparent #ff5252
}

.toolbar-danger.tool-right .arrow {
    border-color: transparent #ff5252 transparent transparent
}

.btn-toolbar-danger.pressed {
    background-color: #ff5252
}

.toolbar-success .tool-item {
    background: #9ccc65
}

.toolbar-success .tool-item.selected, .toolbar-success .tool-item:hover {
    background: #83bf3f
}

.toolbar-success.tool-top .arrow {
    border-color: #9ccc65 transparent transparent
}

.toolbar-success.tool-bottom .arrow {
    border-color: transparent transparent #9ccc65
}

.toolbar-success.tool-left .arrow {
    border-color: transparent transparent transparent #9ccc65
}

.toolbar-success.tool-right .arrow {
    border-color: transparent #9ccc65 transparent transparent
}

.btn-toolbar-success.pressed {
    background-color: #9ccc65
}

.toolbar-dark .tool-item {
    background: #37474f
}

.toolbar-dark .tool-item.selected, .toolbar-dark .tool-item:hover {
    background: #222c31
}

.toolbar-dark.tool-top .arrow {
    border-color: #37474f transparent transparent
}

.toolbar-dark.tool-bottom .arrow {
    border-color: transparent transparent #37474f
}

.toolbar-dark.tool-left .arrow {
    border-color: transparent transparent transparent #37474f
}

.toolbar-dark.tool-right .arrow {
    border-color: transparent #37474f transparent transparent
}

.btn-toolbar-dark.pressed {
    background-color: #37474f
}

.toolbar-info .tool-item {
    background: #00bcd4
}

.toolbar-info .tool-item.selected, .toolbar-info .tool-item:hover {
    background: #008fa1
}

.toolbar-info.tool-top .arrow {
    border-color: #00bcd4 transparent transparent
}

.toolbar-info.tool-bottom .arrow {
    border-color: transparent transparent #00bcd4
}

.toolbar-info.tool-left .arrow {
    border-color: transparent transparent transparent #00bcd4
}

.toolbar-info.tool-right .arrow {
    border-color: transparent #00bcd4 transparent transparent
}

.btn-toolbar-info.pressed {
    background-color: #00bcd4
}

.card-border-primary {
    border-top: 4px solid #448aff
}

.card-border-warning {
    border-top: 4px solid #ffba57
}

.card-border-default {
    border-top: 4px solid #d6d6d6
}

.card-border-danger {
    border-top: 4px solid #ff5252
}

.card-border-success {
    border-top: 4px solid #9ccc65
}

.card-border-inverse {
    border-top: 4px solid #37474f
}

.card-border-info {
    border-top: 4px solid #00bcd4
}

.card-primary {
    background: #448aff
}

.card-warning {
    background: #ffba57
}

.card-default {
    background: #d6d6d6
}

.card-danger {
    background: #ff5252
}

.card-success {
    background: #9ccc65
}

.card-inverse {
    background: #37474f
}

.card-info {
    background: #00bcd4
}

.b-t-primary {
    border-top: 1px solid #448aff
}

.b-b-primary {
    border-bottom: 1px solid #448aff
}

.b-l-primary {
    border-left: 1px solid #448aff
}

.b-r-primary {
    border-right: 1px solid #448aff
}

.b-primary {
    border: 1px solid #448aff
}

.b-t-warning {
    border-top: 1px solid #ffba57
}

.b-b-warning {
    border-bottom: 1px solid #ffba57
}

.b-l-warning {
    border-left: 1px solid #ffba57
}

.b-r-warning {
    border-right: 1px solid #ffba57
}

.b-warning {
    border: 1px solid #ffba57
}

.b-t-default {
    border-top: 1px solid #d6d6d6
}

.b-b-default {
    border-bottom: 1px solid #d6d6d6
}

.b-l-default {
    border-left: 1px solid #d6d6d6
}

.b-r-default {
    border-right: 1px solid #d6d6d6
}

.b-default {
    border: 1px solid #d6d6d6
}

.b-t-danger {
    border-top: 1px solid #ff5252
}

.b-b-danger {
    border-bottom: 1px solid #ff5252
}

.b-l-danger {
    border-left: 1px solid #ff5252
}

.b-r-danger {
    border-right: 1px solid #ff5252
}

.b-danger {
    border: 1px solid #ff5252
}

.b-t-success {
    border-top: 1px solid #9ccc65
}

.b-b-success {
    border-bottom: 1px solid #9ccc65
}

.b-l-success {
    border-left: 1px solid #9ccc65
}

.b-r-success {
    border-right: 1px solid #9ccc65
}

.b-success {
    border: 1px solid #9ccc65
}

.b-t-inverse {
    border-top: 1px solid #37474f
}

.b-b-inverse {
    border-bottom: 1px solid #37474f
}

.b-l-inverse {
    border-left: 1px solid #37474f
}

.b-r-inverse {
    border-right: 1px solid #37474f
}

.b-inverse {
    border: 1px solid #37474f
}

.b-t-info {
    border-top: 1px solid #00bcd4
}

.b-b-info {
    border-bottom: 1px solid #00bcd4
}

.b-l-info {
    border-left: 1px solid #00bcd4
}

.b-r-info {
    border-right: 1px solid #00bcd4
}

.b-info {
    border: 1px solid #00bcd4
}

.bg-facebook {
    background: #3B5997
}

.text-facebook {
    color: #3B5997
}

.bg-twitter {
    background: #42C0FB
}

.text-twitter {
    color: #42C0FB
}

.bg-dribbble {
    background: #EC4A89
}

.text-dribbble {
    color: #EC4A89
}

.bg-pinterest {
    background: #BF2131
}

.text-pinterest {
    color: #BF2131
}

.bg-youtube {
    background: #E0291D
}

.text-youtube {
    color: #E0291D
}

.bg-googleplus {
    background: #C73E2E
}

.text-googleplus {
    color: #C73E2E
}

.bg-instagram {
    background: #AA7C62
}

.text-instagram {
    color: #AA7C62
}

.bg-viber {
    background: #7B519D
}

.text-viber {
    color: #7B519D
}

.bg-amazon {
    background: #000
}

.text-amazon {
    color: #000
}

.bg-behance {
    background: #0057ff
}

.text-behance {
    color: #0057ff
}

.bg-dropbox {
    background: #3380FF
}

.text-dropbox {
    color: #3380FF
}

.bg-c-blue {
    background: #448aff
}

.text-c-blue {
    color: #448aff
}

.bg-c-red {
    background: #ff5252
}

.text-c-red {
    color: #ff5252
}

.bg-c-green {
    background: #9ccc65
}

.text-c-green {
    color: #9ccc65
}

.bg-c-yellow {
    background: #ffba57
}

.text-c-yellow {
    color: #ffba57 !important;
}

.bg-c-orenge {
    background: #FE8A7D
}

.text-c-orenge {
    color: #FE8A7D
}

.bg-c-lite-green {
    background: #69CEC6
}

.text-c-lite-green {
    color: #69CEC6
}

.bg-c-purple {
    background: #536dfe
}

.text-c-purple {
    color: #536dfe
}

.pcoded[nav-type="st1"] .pcoded-item > li:nth-child(1) > a > .pcoded-micon {
    color: #29548E
}

.pcoded[nav-type="st1"] .pcoded-item > li:nth-child(2) > a > .pcoded-micon {
    color: #ff5252
}

.pcoded[nav-type="st1"] .pcoded-item > li:nth-child(3) > a > .pcoded-micon {
    color: #D81B60
}

.pcoded[nav-type="st1"] .pcoded-item > li:nth-child(4) > a > .pcoded-micon {
    color: #66BB6A
}

.pcoded[nav-type="st1"] .pcoded-item > li:nth-child(5) > a > .pcoded-micon {
    color: #7C4DFF
}

.pcoded[nav-type="st1"] .pcoded-item > li:nth-child(6) > a > .pcoded-micon {
    color: #29548E
}

.pcoded[nav-type="st1"] .pcoded-item > li:nth-child(7) > a > .pcoded-micon {
    color: #ff5252
}

.pcoded[nav-type="st1"] .pcoded-item > li:nth-child(8) > a > .pcoded-micon {
    color: #D81B60
}

.pcoded[nav-type="st1"] .pcoded-item > li:nth-child(9) > a > .pcoded-micon {
    color: #66BB6A
}

.pcoded[nav-type="st1"] .pcoded-item > li:nth-child(10) > a > .pcoded-micon {
    color: #7C4DFF
}

.pcoded[nav-type="st2"] .pcoded-item > li > a > .pcoded-micon {
    color: #37474f
}

.pcoded[nav-type="st1"] .pcoded-item > li > a > .pcoded-micon, .pcoded[nav-type="st2"] .pcoded-item > li > a > .pcoded-micon {
    color: #37474f
}

.pcoded[nav-type="st1"] .pcoded-item > li > a > .pcoded-micon b, .pcoded[nav-type="st2"] .pcoded-item > li > a > .pcoded-micon b {
    display: none
}

.main-menu {
    float: left;
    width: 100%;
    z-index: 99
}

.main-menu .main-menu-header {
    padding: 1rem 0.625rem;
    background-blend-mode: overlay;
    background-size: cover;
    position: relative
}

.main-menu .main-menu-header img {
    position: relative;
    width: 60px;
    margin: 0 auto 15px;
    display: block
}

.main-menu .main-menu-header .user-details {
    display: block;
    position: relative;
    text-align: center
}

.main-menu .main-menu-header .user-details #more-details {
    cursor: pointer;
    font-size: 0.8125rem
}

.main-menu .main-menu-header .user-details p {
    margin-bottom: 0;
    color: #666;
    font-weight: 600;
    font-size: 1rem
}

.main-menu .main-menu-header .user-details span {
    color: #666;
    display: block;
    font-size: 14px;
    text-transform: capitalize
}

.main-menu .main-menu-header .user-details span i {
    font-size: 10px;
    margin-left: 10px
}

.main-menu .main-menu-content .more-details {
    display: none
}

.main-menu .main-menu-content .more-details a {
    padding: 10px 25px;
    display: block;
    color: #000;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s
}

.main-menu .main-menu-content .more-details a:hover {
    color: #448aff
}

.main-menu .main-menu-content .more-details a i {
    margin-right: 10px
}

.main-menu .main-menu-content .menu-search {
    padding: 20px 20px 10px
}

.main-menu .main-menu-content li {
    position: relative
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .main-menu-content {
    display: none
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .main-menu-header .img-menu-user {
    width: 30px
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .main-menu-header .user-details {
    opacity: 0;
    visibility: hidden;
    position: absolute
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > li > a {
    margin: 0 auto;
    border-radius: 0;
    z-index: 1026
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > li > a + .pcoded-submenu {
    margin-top: 0
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar[pcoded-navbar-position="fixed"], .pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar[pcoded-navbar-position="absolute"] {
    position: relative;
    top: 0
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar[pcoded-navbar-position="fixed"] .main-menu, .pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar[pcoded-navbar-position="absolute"] .main-menu {
    width: 50px;
    height: 100%
}

.sidebar_toggle a {
    margin: -5px;
    line-height: 1
}

.header-navbar {
    min-height: 50px;
    padding: 0
}

.header-navbar .navbar-wrapper .navbar-logo {
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    float: left;
    height: 50px;
    text-align: center;
    text-transform: uppercase;
    width: 230px;
    padding: 10px
}

.header-navbar .navbar-wrapper .navbar-logo a {
    margin: 0 auto
}

@media only screen and (max-width: 992px) {
    .header-navbar .navbar-wrapper .navbar-logo {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

.header-navbar .navbar-wrapper .navbar-logo h5 {
    margin-bottom: 0
}

.header-navbar .navbar-wrapper .navbar-logo .mobile-menu {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 16px;
    line-height: 3;
    width: 40px
}

.header-navbar .navbar-wrapper .navbar-logo .mobile-options, .header-navbar .navbar-wrapper .navbar-logo .mobile-search {
    display: none
}

.header-navbar .navbar-wrapper .navbar-container .nav-left, .header-navbar .navbar-wrapper .navbar-container .nav-right {
    float: left;
    margin-bottom: 0
}

.header-navbar .navbar-wrapper .navbar-container .nav-left li, .header-navbar .navbar-wrapper .navbar-container .nav-right li {
    float: left;
    line-height: 3.1;
    padding: 0 20px;
    cursor: pointer;
    position: relative
}

@media only screen and (max-width: 575px) {
    .header-navbar .navbar-wrapper .navbar-container .nav-left li, .header-navbar .navbar-wrapper .navbar-container .nav-right li {
        padding: 0 15px
    }
}

.header-navbar .navbar-wrapper .navbar-container .nav-left li .sidebar_toggle a, .header-navbar .navbar-wrapper .navbar-container .nav-right li .sidebar_toggle a {
    display: none
}

.header-navbar .navbar-wrapper .navbar-container .nav-left li > a, .header-navbar .navbar-wrapper .navbar-container .nav-right li > a {
    font-size: 16px;
    display: block
}

.header-navbar .navbar-wrapper .navbar-container .nav-left li > a > i.flag-icon, .header-navbar .navbar-wrapper .navbar-container .nav-right li > a > i.flag-icon {
    border-radius: 50%;
    width: 20px;
    height: 20px
}

.header-navbar .navbar-wrapper .navbar-container .nav-left a, .header-navbar .navbar-wrapper .navbar-container .nav-right a {
    padding: 0 0.6rem
}

.header-navbar .navbar-wrapper .navbar-container .nav-left .dropdown-toggle::after, .header-navbar .navbar-wrapper .navbar-container .nav-right .dropdown-toggle::after {
    display: none
}

.header-navbar .navbar-wrapper .navbar-container .nav-right {
    float: right
}

.header-navbar .navbar-wrapper .navbar-container .nav-right .user-profile img {
    margin-right: 10px;
    width: 40px
}

.header-navbar .navbar-wrapper .navbar-container .nav-right .user-profile i {
    font-size: 10px;
    margin-left: 10px
}

.header-navbar .navbar-wrapper .navbar-container .nav-right > .header-notification:nth-child(1) .show-notification li:first-child:hover, .header-navbar .navbar-wrapper .navbar-container .nav-right > .header-notification:nth-child(1) .profile-notification li:first-child:hover {
    background-color: #fff
}

.header-navbar .navbar-wrapper .navbar-container .header-notification {
    -webkit-perspective: 1000px;
    perspective: 1000px;
    z-index: 99;
    position: relative
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification {
    background: #fff;
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    border: none;
    opacity: 1;
    position: absolute;
    left: auto;
    right: -15px;
    visibility: visible;
    overflow: visible;
    width: 24rem;
    padding: 15px 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px)
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification.show:before, .header-navbar .navbar-wrapper .navbar-container .header-notification .show.profile-notification:before {
    content: "";
    border: 10px solid transparent;
    border-left-color: #fff;
    border-top-color: #fff;
    position: absolute;
    border-radius: 3px;
    top: -8px;
    right: 15px;
    -webkit-box-shadow: -5px -5px 12px -2px rgba(0, 0, 0, 0.3);
    box-shadow: -5px -5px 12px -2px rgba(0, 0, 0, 0.3);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 1002
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification a, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification a {
    color: #373a3c
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li {
    line-height: initial;
    padding: 1em 20px;
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li:first-child, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:first-child {
    padding: 10px 20px
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li:first-child h6, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:first-child h6 {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li:first-child label, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:first-child label {
    float: right
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li img, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li img {
    width: 40px;
    margin-right: 10px
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li:hover, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:hover {
    background-color: #f1f1f1
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification.row li:hover, .header-navbar .navbar-wrapper .navbar-container .header-notification .row.profile-notification li:hover {
    background-color: transparent
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification .notification-user, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification .notification-user {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 5px
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification .notification-msg, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification .notification-msg {
    margin-bottom: 5px
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification .notification-time, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification .notification-time {
    font-size: 12px;
    color: #919aa3
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification {
    width: 17em
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification a {
    font-size: 15px;
    padding-left: 0
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification a:hover {
    background-color: transparent
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification i {
    font-size: 15px;
    margin-left: 0;
    margin-right: 5px
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li {
    border-top: none;
    padding: 0.7em 20px
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:first-child {
    padding: 0.7em 20px
}

.header-navbar .navbar-wrapper .navbar-container .badge {
    border-radius: 50%;
    right: -12px;
    position: absolute;
    top: 5px;
    padding: 4px;
    width: 19px;
    -webkit-animation: not-blink 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    animation: not-blink 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite
}

.header-navbar .navbar-wrapper .header-search {
    line-height: 0.8
}

.header-navbar .navbar-wrapper .header-search .main-search {
    padding: 9px 0;
    display: block
}

.header-navbar .navbar-wrapper .header-search .main-search .input-group {
    margin-bottom: 0;
    background-color: transparent;
    border-radius: 20px
}

.header-navbar .navbar-wrapper .header-search .main-search .input-group .form-control {
    padding: 0.3rem 0.75rem;
    width: 0;
    border: none;
    background-color: transparent
}

.header-navbar .navbar-wrapper .header-search .main-search .input-group .input-group-append, .header-navbar .navbar-wrapper .header-search .main-search .input-group .input-group-prepend {
    background-color: transparent;
    border: none
}

.header-navbar .navbar-wrapper .header-search .main-search .input-group .input-group-append .input-group-text, .header-navbar .navbar-wrapper .header-search .main-search .input-group .input-group-prepend .input-group-text {
    background-color: transparent;
    color: #fff;
    border: none
}

.header-navbar .navbar-wrapper .header-search .main-search .input-group .input-group-append.search-close, .header-navbar .navbar-wrapper .header-search .main-search .input-group .input-group-prepend.search-close {
    display: none
}

.header-navbar .navbar-wrapper .header-search .main-search.open {
    color: #000;
    line-height: 1
}

.header-navbar .navbar-wrapper .header-search .main-search.open .input-group {
    background-color: #fff
}

.header-navbar .navbar-wrapper .header-search .main-search.open .input-group .form-control {
    display: block
}

.header-navbar .navbar-wrapper .header-search .main-search.open .input-group .input-group-append .input-group-text, .header-navbar .navbar-wrapper .header-search .main-search.open .input-group .input-group-prepend .input-group-text {
    color: #000
}

.header-navbar .navbar-wrapper .header-search .main-search.open .input-group .input-group-append.search-close, .header-navbar .navbar-wrapper .header-search .main-search.open .input-group .input-group-prepend.search-close {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@-webkit-keyframes not-blink {
    0% {
        opacity: 1
    }
    70% {
        opacity: 1
    }
    80% {
        opacity: 0
    }
    100% {
        opacity: 0
    }
}

@keyframes not-blink {
    0% {
        opacity: 1
    }
    70% {
        opacity: 1
    }
    80% {
        opacity: 0
    }
    100% {
        opacity: 0
    }
}

.pcoded .pcoded-container {
    position: relative
}

.pcoded .pcoded-header {
    width: 100%;
    z-index: 1029;
    position: relative;
    display: block
}

.pcoded .pcoded-navbar {
    display: block;
    -webkit-box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3);
    box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3)
}

.pcoded-main-container {
    display: block;
    position: relative;
    background: #ecf0f5;
    min-height: calc(100vh - 50px)
}

.pcoded .pcoded-content {
    position: relative;
    display: block
}

.pcoded-inner-navbar {
    display: block;
    position: relative
}

.pcoded-inner-content {
    padding: 10px;
    margin-top: -50px
}

.pcoded .pcoded-navbar .pcoded-item {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0 0 20px;
    position: relative
}

.pcoded .pcoded-navbar .pcoded-item:after {
    content: "";
    background-color: #efefef;
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: 10px
}

.pcoded .pcoded-navbar .pcoded-item > li {
    display: block;
    list-style: outside none none;
    margin: 0;
    padding: 0;
    position: relative
}

.pcoded .pcoded-navbar .pcoded-item > li > a {
    display: block;
    font-size: 14px;
    padding: 0 15px;
    text-decoration: none;
    position: relative;
    -webkit-transition: all 0s linear;
    transition: all 0s linear
}

.pcoded .pcoded-navbar .pcoded-item > li > a.disabled {
    opacity: 0.5;
    cursor: not-allowed
}

.pcoded .pcoded-navbar .pcoded-item > li.active > a, .pcoded .pcoded-navbar .pcoded-item > li.pcoded-trigger > a, .pcoded .pcoded-navbar .pcoded-item > li:hover > a {
    -webkit-box-shadow: 0 15px 8px -9px rgba(0, 0, 0, 0.25);
    box-shadow: 0 15px 8px -9px rgba(0, 0, 0, 0.25)
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu {
    list-style: outside none none;
    margin: 10px 0;
    padding: 0
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li {
    display: block;
    list-style: outside none none;
    margin: 0;
    padding: 0;
    position: relative
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a {
    display: block;
    font-size: 14px;
    font-weight: 400;
    padding: 15px 20px;
    text-decoration: none;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a > .pcoded-mtext {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a > .pcoded-mtext:before {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-micon {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-size: 10px;
    padding-right: 5px
}

.pcoded .pcoded-navbar .pcoded-item > li > a > .pcoded-micon {
    font-size: 1rem;
    padding: 4px;
    margin-right: 0;
    border-radius: 4px;
    width: 30px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 30px;
    text-align: center
}

.pcoded[vertical-placement="left"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a .pcoded-mtext {
    margin-left: 5px
}

.pcoded[vertical-placement="left"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a .pcoded-mtext:before {
    padding-right: 10px
}

.pcoded[vertical-placement="right"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a .pcoded-mtext {
    margin-right: 5px
}

.pcoded[vertical-placement="right"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a .pcoded-mtext:before {
    padding-left: 10px
}

.pcoded[vertical-placement="right"] .main-body .page-wrapper .page-header-title i {
    margin-right: 0;
    margin-left: 20px
}

.pcoded-inner-navbar {
    height: 100%
}

.pcoded[theme-layout="vertical"][vertical-nav-type="offcanvas"] .pcoded-navbar {
    opacity: 0
}

.pcoded[theme-layout="vertical"] .pcoded-header .sidebar_toggle a {
    border-radius: 4px;
    float: left;
    font-size: 18px;
    height: 35px;
    margin-right: 5px;
    position: relative;
    text-align: center;
    top: 7px;
    width: 40px;
    border-width: 0;
    border-style: solid
}

.pcoded[theme-layout="vertical"] .pcoded-header .sidebar_toggle a i {
    position: relative;
    top: -7px
}

.pcoded[theme-layout="vertical"] .pcoded-header .sidebar_toggle a:hover {
    text-decoration: none
}

.sidebar_toggle a:focus {
    text-decoration: none
}

.pcoded[theme-layout="vertical"] .pcoded-right-header .pcoded-badge {
    background-color: #777;
    border-radius: 10px;
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    line-height: 1;
    min-height: 17px;
    min-width: 10px;
    opacity: 1;
    padding: 4px 7px;
    position: absolute;
    right: -4px;
    text-align: center;
    top: 9px;
    -webkit-transition: opacity 0.3s linear 0s;
    transition: opacity 0.3s linear 0s;
    vertical-align: middle;
    visibility: visible;
    white-space: nowrap
}

.pcoded[theme-layout="vertical"] .pcoded-navbar {
    z-index: 1027;
    height: calc(100% - 50px)
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[pcoded-navbar-position="absolute"] {
    height: 100%
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[pcoded-navbar-position="fixed"] {
    top: 50px
}

.pcoded[theme-layout="vertical"] .pcoded-content {
    position: relative;
    display: block
}

.pcoded[theme-layout="vertical"] .pcoded-container {
    overflow: hidden;
    position: relative;
    margin: 0 auto
}

.pcoded[theme-layout="vertical"].pcoded-wrapper, .pcoded[theme-layout="vertical"] .pcoded-main-container {
    position: relative;
    margin: 0 auto
}

.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item > li > a {
    text-align: left;
    padding: 7px 10px;
    margin: 5px 10px;
    border-radius: 2px
}

.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu {
    position: relative;
    width: 100%
}

.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a {
    text-align: left;
    padding: 10px 15px 10px 20px
}

.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu .pcoded-hasmenu .pcoded-submenu li > a {
    padding-left: 40px
}

.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu {
    list-style: outside none none
}

.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu {
    display: none
}

.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item .pcoded-hasmenu.pcoded-trigger.active > .pcoded-submenu {
    display: block
}

.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item[item-border="true"][item-border-style="dashed"] li > a {
    border-bottom-style: dashed
}

.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item[item-border="true"][item-border-style="dotted"] li > a {
    border-bottom-style: dotted
}

.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item[item-border="true"][item-border-style="solid"] li > a {
    border-bottom-style: solid
}

.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item[item-border="true"][item-border-style="none"] li > a {
    border: none
}

.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item[item-border="false"] > li > a {
    border-bottom-width: 0
}

.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item[item-border="true"] li > a {
    border-bottom-width: 1px
}

.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item[item-border="true"] > li > a {
    border-bottom-width: 0
}

.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item[subitem-border="false"] .pcoded-hasmenu .pcoded-submenu li > a {
    border-bottom-width: 0
}

.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item[subitem-border="true"] .pcoded-hasmenu .pcoded-submenu li > a {
    border-bottom-width: 1px
}

.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item[subitem-border="true"] .pcoded-hasmenu .pcoded-submenu li:last-child > a {
    border-bottom-width: 0
}

.pcoded[theme-layout="vertical"][vertical-layout="wide"] .pcoded-container {
    width: 100%;
    margin: 0 auto
}

.pcoded[theme-layout="vertical"][vertical-layout="box"] #styleSelector, .pcoded[theme-layout="vertical"][vertical-layout="box"] .p-chat-user, .pcoded[theme-layout="vertical"][vertical-layout="box"] .showChat_inner {
    position: absolute
}

.pcoded[theme-layout="vertical"][vertical-layout="box"] #styleSelector {
    top: 0
}

.pcoded[theme-layout="vertical"][vertical-layout="box"] .pcoded-container {
    -webkit-box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3);
    box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3)
}

.pcoded[theme-layout="vertical"][vertical-layout="box"] .selector-toggle > a {
    background: #fff;
    color: #ff5252;
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    padding: 14px 8px;
    left: -105px
}

.pcoded[theme-layout="vertical"][pcoded-device-type="desktop"][vertical-layout="box"] .pcoded-container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto
}

.pcoded[theme-layout="vertical"][pcoded-device-type="desktop"][vertical-layout="box"] .pcoded-container .pcoded-header {
    max-width: 1200px
}

@media only screen and (min-width: 1550px) {
    .pcoded[theme-layout="vertical"][pcoded-device-type="desktop"][vertical-layout="box"] .pcoded-container {
        max-width: 1500px
    }

    .pcoded[theme-layout="vertical"][pcoded-device-type="desktop"][vertical-layout="box"] .pcoded-container .pcoded-header {
        max-width: 1500px
    }
}

@media only screen and (min-width: 2200px) {
    .pcoded[theme-layout="vertical"][pcoded-device-type="desktop"][vertical-layout="box"] .pcoded-container {
        max-width: 2000px
    }

    .pcoded[theme-layout="vertical"][pcoded-device-type="desktop"][vertical-layout="box"] .pcoded-container .pcoded-header {
        max-width: 2000px
    }
}

.pcoded[theme-layout="vertical"][pcoded-device-type="tablet"][vertical-layout="box"] .pcoded-container {
    max-width: 900px;
    width: 100%;
    margin: 0 auto
}

.pcoded[theme-layout="vertical"][pcoded-device-type="phone"][vertical-layout="box"] .pcoded-container {
    max-width: 100%;
    width: 100%;
    margin: 0 auto
}

.pcoded .pcoded-navbar[pcoded-navbar-position="relative"] {
    position: relative
}

.pcoded .pcoded-navbar[pcoded-navbar-position="fixed"] {
    position: fixed
}

.pcoded .pcoded-navbar[pcoded-navbar-position="absolute"] {
    position: absolute
}

.pcoded .pcoded-header[pcoded-header-position="relative"] {
    position: relative
}

.pcoded .pcoded-header[pcoded-header-position="fixed"] {
    position: fixed;
    top: 0;
    z-index: 1028
}

.pcoded .pcoded-header[pcoded-header-position="absolute"] {
    position: absolute
}

.pcoded .pcoded-overlay-box {
    background: #000 none repeat scroll 0 0;
    bottom: 0;
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
    overflow: hidden
}

.pcoded[vertical-nav-type="compact"][vertical-effect="overlay"] .pcoded-overlay-box, .pcoded[vertical-nav-type="expanded"][vertical-effect="overlay"] .pcoded-overlay-box {
    visibility: visible;
    z-index: 1026;
    opacity: 0.3;
    -webkit-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
    overflow: hidden
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-micon {
    display: none;
    font-size: 10px;
    padding-right: 5px
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-mtext:before {
    font-style: normal;
    padding-right: 4px;
    font-size: 10px;
    position: relative;
    left: 0;
    top: 0;
    opacity: 1;
    visibility: visible;
    font-family: 'feather';
    font-size: 12px;
    padding-right: 5px
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon="style1"] .pcoded-submenu li > a .pcoded-mtext:before {
    content: "\e844"
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon="style2"] .pcoded-submenu li > a .pcoded-mtext:before {
    content: "\e83f"
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon="style3"] .pcoded-submenu li > a .pcoded-mtext:before {
    content: "\e856"
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon="style4"] .pcoded-submenu li > a .pcoded-mtext:before {
    content: "\e86b"
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon="style5"] .pcoded-submenu li > a .pcoded-mtext:before {
    content: "\e89f"
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon="style6"] .pcoded-submenu li > a .pcoded-mtext:before {
    content: '\e89b'
}

.pcoded li.pcoded-hasmenu > a:after {
    float: right;
    font-style: normal;
    margin-left: 3px;
    font-size: 14px;
    position: absolute;
    right: 15px;
    top: 5px;
    font-family: 'feather';
    padding-top: 5px;
    -webkit-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear
}

.pcoded li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu > a:after {
    top: 0
}

.pcoded li.pcoded-hasmenu[dropdown-icon="style1"] > a:after {
    content: "\e844"
}

.pcoded li.pcoded-hasmenu.pcoded-trigger[dropdown-icon="style1"] > a:after {
    content: "\e842"
}

.pcoded li.pcoded-hasmenu[dropdown-icon="style2"] > a:after {
    content: "\e847"
}

.pcoded li.pcoded-hasmenu.pcoded-trigger[dropdown-icon="style2"] > a:after {
    content: "\e846"
}

.pcoded li.pcoded-hasmenu[dropdown-icon="style3"] > a:after {
    content: '\e8b1'
}

.pcoded li.pcoded-hasmenu.pcoded-trigger[dropdown-icon="style3"] > a:after {
    content: '\e897'
}

.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item > li:hover > a:before {
    border-left-style: solid;
    border-left-width: 5px;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a:before {
    border-left-style: solid;
    border-left-width: 5px;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0
}

.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item > li.active > a:before {
    border-left-style: solid;
    border-left-width: 5px;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar {
    float: right;
    right: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .main-menu-content, .pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .main-menu-header {
    text-align: right
}

.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .main-menu-content ul {
    padding-right: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-content {
    float: unset;
    left: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="right"] .breadcrumb-item {
    float: right
}

.pcoded[theme-layout="vertical"][vertical-placement="left"] .pcoded-navbar {
    float: left
}

.pcoded[theme-layout="vertical"][vertical-placement="left"] .pcoded-content {
    float: unset;
    right: 0
}

.pcoded[theme-layout="vertical"][vertical-nav-type="expanded"] .pcoded-header .pcoded-left-header, .pcoded[theme-layout="vertical"][vertical-nav-type="expanded"] .pcoded-navbar {
    width: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="push"] .pcoded-header .pcoded-right-header {
    margin-left: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-left: 230px;
    margin-right: -230px
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="push"] .pcoded-content {
    margin-left: 230px;
    margin-right: -230px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="push"] .pcoded-header .pcoded-right-header {
    margin-right: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-right: 0;
    margin-left: -230px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="push"] .pcoded-content {
    margin-right: 230px;
    margin-left: -230px
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="shrink"] .pcoded-header.iscollapsed .pcoded-right-header, .pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="shrink"] .pcoded-header .pcoded-right-header {
    margin-left: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="shrink"] .pcoded-content {
    margin-left: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="shrink"] .pcoded-header.iscollapsed .pcoded-right-header, .pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="shrink"] .pcoded-header .pcoded-right-header {
    margin-right: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="shrink"] .pcoded-content {
    margin-right: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="overlay"] .pcoded-header .pcoded-right-header {
    margin-left: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="overlay"] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-left: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="expanded"][vertical-effect="overlay"] .pcoded-content {
    margin-left: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="overlay"] .pcoded-header .pcoded-right-header {
    margin-right: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="overlay"] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-right: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][vertical-effect="overlay"] .pcoded-content {
    margin-right: 0
}

.pcoded[theme-layout="vertical"][vertical-nav-type="expanded"][pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu, .pcoded[theme-layout="vertical"][vertical-nav-type="expanded"][pcoded-device-type="desktop"] .pcoded-navbar.is-hover .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu, .pcoded[theme-layout="vertical"][vertical-nav-type="expanded"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu, .pcoded[theme-layout="vertical"][vertical-nav-type="expanded"][pcoded-device-type="tablet"] .pcoded-navbar.is-hover .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
    left: 100%;
    position: absolute;
    width: 230px;
    z-index: 1024;
    top: 0
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-container {
    background-color: #ecf0f5
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-header .pcoded-left-header {
    width: 230px
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar {
    width: 50px
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > li > a {
    padding: 10px 13px 4px
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="collapsed"][vertical-effect="push"] .pcoded-content {
    margin-left: 50px;
    margin-right: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="push"] .pcoded-header .pcoded-right-header {
    margin-right: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 50px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-right: 45px;
    margin-left: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="push"] .pcoded-content {
    margin-right: 50px;
    margin-left: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="collapsed"][vertical-effect="shrink"] .pcoded-header .pcoded-right-header {
    margin-left: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="collapsed"][vertical-effect="shrink"] .pcoded-content {
    margin-left: 50px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="shrink"] .pcoded-header .pcoded-right-header {
    margin-right: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="shrink"] .pcoded-content {
    margin-right: 50px
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="collapsed"][vertical-effect="overlay"] .pcoded-header .pcoded-right-header {
    margin-left: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="collapsed"][vertical-effect="overlay"] .pcoded-content {
    margin-left: 50px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="overlay"] .pcoded-header .pcoded-right-header {
    margin-right: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"][vertical-effect="overlay"] .pcoded-content {
    margin-right: 50px
}

.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item > li > a .pcoded-mtext {
    opacity: 1;
    visibility: visible;
    position: absolute;
    top: 12px
}

.pcoded[theme-layout="vertical"] .pcoded-navbar .pcoded-item li.pcoded-hasmenu > a:after {
    opacity: 1;
    visibility: visible
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > li > a .pcoded-mtext {
    opacity: 0;
    visibility: hidden;
    position: absolute
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > li.pcoded-trigger > a .pcoded-mtext {
    opacity: 1;
    visibility: visible;
    position: absolute;
    padding-left: 22px
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item li.pcoded-hasmenu > a:after {
    opacity: 0;
    visibility: hidden
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item li.pcoded-hasmenu.pcoded-trigger a:after {
    opacity: 1;
    visibility: visible
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > li.pcoded-trigger {
    display: block;
    position: relative;
    width: 290px;
    z-index: 1024;
    border-right: none
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
    left: 50px;
    position: absolute;
    width: 240px;
    z-index: 1024;
    border-left-style: solid;
    border-left-width: 1px
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
    position: relative
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu > .pcoded-submenu .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
    position: absolute
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-header .pcoded-left-header {
    width: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-header .pcoded-right-header {
    margin-left: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-left: 0;
    margin-right: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-navbar {
    width: 230px;
    margin-left: -250px
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-content {
    margin-left: 0;
    margin-right: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-header .pcoded-left-header {
    width: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-header .pcoded-right-header {
    margin-right: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-right: 0;
    margin-left: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-navbar {
    width: 230px;
    margin-right: -250px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="push"] .pcoded-content {
    margin-right: 0;
    margin-left: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-header .pcoded-left-header {
    width: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-header .pcoded-right-header {
    margin-left: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-left: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-navbar {
    width: 230px;
    margin-left: -250px
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-content {
    margin-left: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-header .pcoded-left-header {
    width: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-header .pcoded-right-header {
    margin-right: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-right: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-navbar {
    width: 230px;
    margin-right: -230px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="shrink"] .pcoded-content {
    margin-right: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-header .pcoded-left-header {
    width: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-header .pcoded-right-header {
    margin-left: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-left: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-navbar {
    width: 230px;
    margin-left: -250px
}

.pcoded[theme-layout="vertical"][vertical-placement="left"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-content {
    margin-left: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-header .pcoded-left-header {
    width: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-header .pcoded-right-header {
    margin-right: 230px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-right: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-navbar {
    width: 230px;
    margin-right: -230px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="offcanvas"][vertical-effect="overlay"] .pcoded-content {
    margin-right: 0
}

.pcoded-navbar .sidebar_toggle a {
    opacity: 1;
    visibility: visible;
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    position: absolute
}

.pcoded-navbar .sidebar_toggle a:hover {
    color: #fff
}

.pcoded[theme-layout="vertical"][pcoded-device-type="phone"][vertical-nav-type="collapsed"] .pcoded-header.nocollapsed .pcoded-left-header {
    padding-left: 45px
}

.pcoded[theme-layout="vertical"][pcoded-device-type="phone"] .pcoded-header.nocollapsed .sidebar_toggle a {
    background: transparent none repeat scroll 0 0;
    border-radius: 4px;
    color: #fff;
    float: left;
    font-size: 18px;
    height: 35px;
    margin-right: 5px;
    position: fixed;
    right: 0;
    text-align: center;
    top: 6px;
    width: 40px;
    z-index: 1028
}

.pcoded.nocollapsed[theme-layout="vertical"][pcoded-device-type="phone"] .pcoded-navbar {
    top: 0;
    z-index: 1030
}

.pcoded.nocollapsed[theme-layout="vertical"][pcoded-device-type="phone"] .pcoded-main-container {
    position: unset
}

.pcoded .pcoded-navbar .pcoded-navigation-label {
    font-size: 13px;
    font-weight: bold;
    opacity: 1;
    padding: 10px 15px
}

.pcoded .pcoded-navbar .pcoded-navigation-label[menu-title-theme="theme1"] {
    color: #29548E
}

.pcoded .pcoded-navbar .pcoded-navigation-label[menu-title-theme="theme2"] {
    color: #ff5252
}

.pcoded .pcoded-navbar .pcoded-navigation-label[menu-title-theme="theme3"] {
    color: #D81B60
}

.pcoded .pcoded-navbar .pcoded-navigation-label[menu-title-theme="theme4"] {
    color: #66BB6A
}

.pcoded .pcoded-navbar .pcoded-navigation-label[menu-title-theme="theme5"] {
    color: #7C4DFF
}

.pcoded .pcoded-navbar .pcoded-navigation-label[menu-title-theme="theme6"] {
    color: #424242
}

.pcoded .pcoded-navbar .pcoded-navigation-label[menu-title-theme="theme7"] {
    color: #000
}

.pcoded .pcoded-navbar .pcoded-navigation-label[menu-title-theme="theme8"] {
    color: #999
}

.pcoded .pcoded-navbar .pcoded-navigation-label[menu-title-theme="theme9"] {
    color: #fff
}

.pcoded .pcoded-navbar .pcoded-search + .pcoded-navigation-label {
    border-top: 0 solid transparent
}

.pcoded[vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-navigation-label {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    -webkit-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear
}

.pcoded .pcoded-navbar .pcoded-item li a .pcoded-badge {
    position: absolute;
    right: 30px;
    text-align: center;
    top: 10px;
    vertical-align: middle;
    text-transform: lowercase;
    white-space: nowrap;
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-submenu > li a .pcoded-badge {
    top: 10px;
    right: 2px
}

.pcoded[vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > li > a .pcoded-badge {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear
}

.pcoded[vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > li.pcoded-trigger > a .pcoded-badge {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear
}

.pcoded[theme-layout="vertical"][vertical-placement="right"] .card .card-header h5:after {
    right: -30px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item > li.active > a:before {
    left: auto;
    right: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu > a:after {
    left: 8px;
    right: auto;
    top: 7px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item > li > a .pcoded-mtext {
    left: auto;
    right: 0;
    padding-right: 40px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item li a .pcoded-badge {
    position: relative;
    right: 15px;
    top: -2px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item > li:hover > a:before {
    left: auto;
    right: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a:before {
    left: auto;
    right: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a {
    text-align: right
}

.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-mtext:before {
    float: right;
    left: 6px;
    top: 3px;
    padding-right: 10px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item li .pcoded-submenu li > a .pcoded-badge {
    left: 35px;
    position: absolute;
    right: auto;
    top: 11px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-navigation-label {
    text-align: right;
    padding: 10px 15px 2px 20px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-navigation-label:before {
    left: auto;
    right: 20px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu, .pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][pcoded-device-type="desktop"] .pcoded-navbar.is-hover .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu, .pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu, .pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="expanded"][pcoded-device-type="tablet"] .pcoded-navbar.is-hover .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
    left: auto;
    right: 100%
}

.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu.pcoded-trigger .pcoded-submenu li.pcoded-hasmenu > a:after {
    left: 7px;
    right: auto;
    top: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > li.pcoded-trigger {
    left: auto;
    right: 240px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
    left: auto
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item li a .pcoded-badge {
    position: absolute;
    right: auto;
    top: 10px
}

.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-header .sidebar_toggle {
    float: right
}

.pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"] .pcoded-search.open .pcoded-search-box {
    right: 0
}

.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a {
    text-align: right
}

.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-mtext:before {
    padding-left: 10px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

body[themebg-pattern="theme1"] {
    background-color: #29548E
}

body[themebg-pattern="theme2"] {
    background-color: #ff5252
}

body[themebg-pattern="theme3"] {
    background-color: #D81B60
}

body[themebg-pattern="theme4"] {
    background-color: #66BB6A
}

body[themebg-pattern="theme5"] {
    background-color: #7C4DFF
}

body[themebg-pattern="theme6"] {
    background-color: #424242
}

body[themebg-pattern="theme9"] {
    background-color: #fff
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-
navbar[navbar-theme="themelight1"] .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
    border-left-color: #ededed;
    -webkit-box-shadow: 4px 4px 13px -4px rgba(0, 0, 0, 0.3);
    box-shadow: 4px 4px 13px -4px rgba(0, 0, 0, 0.3)
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
    border-left-color: #545454;
    -webkit-box-shadow: 4px 4px 13px -4px rgba(0, 0, 0, 0.3);
    box-shadow: 4px 4px 13px -4px rgba(0, 0, 0, 0.3)
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li.pcoded-trigger > a {
    border-bottom: 1px solid #5c5c5c
}

.pcoded #styleSelector {
    min-height: 300px;
    height: calc(100vh - 50px);
    border: 1px solid #e9e9e9;
    background: #FFF;
    position: fixed;
    margin: 0;
    padding: 20px;
    width: 400px;
    top: 50px;
    z-index: 100;
    -webkit-transition: 0.5s;
    transition: 0.5s
}

.pcoded #styleSelector.open {
    background: #FFF none repeat scroll 0 0;
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2)
}

.pcoded #styleSelector .soc-icon {
    width: 40px;
    height: 40px;
    padding: 10px 6px;
    border-radius: 50%
}

.pcoded #styleSelector .soc-icon i {
    font-size: 20px;
    margin-right: 0
}

@media only screen and (max-width: 992px) {
    .pcoded #styleSelector {
        display: none
    }
}

.pcoded[theme-layout="horizontal"] #styleSelector, .pcoded[vertical-placement="left"] #styleSelector {
    right: -400px;
    border-left: 2px solid rgba(48, 53, 73, 0.28)
}

.pcoded[theme-layout="horizontal"] #styleSelector.open, .pcoded[vertical-placement="left"] #styleSelector.open {
    right: 0
}

.pcoded[vertical-placement="right"] #styleSelector {
    left: -400px;
    border-right: 2px solid rgba(48, 53, 73, 0.28)
}

.pcoded[vertical-placement="right"] #styleSelector.open {
    left: 0
}

.pcoded .pcoded-navbar[navbar-theme="theme1"] {
    background-color: #424242
}

.pcoded .pcoded-navbar[navbar-theme="theme1"] .main-menu .main-menu-header .user-details p {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme="theme1"] .main-menu .main-menu-content .more-details a {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li > a {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li > a .pcoded-micon {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li.active > a {
    color: #FFF;
    border-bottom-color: #424242
}

.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li > a {
    border-bottom-color: #424242
}

.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item li.pcoded-hasmenu .pcoded-submenu {
    background: #424242
}

.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item li.pcoded-hasmenu .pcoded-submenu li > a {
    border-bottom-color: #5c5c5c;
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li.pcoded-hasmenu > a:after, .pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu > a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li.pcoded-hasmenu.pcoded-trigger > a:after, .pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li.pcoded-hasmenu.pcoded-trigger .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger > a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li.active:hover > a, .pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li:hover > a {
    color: #FFF
}

.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a {
    color: #FFF
}

.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a:before {
    border-left-color: transparent
}

.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active > a {
    color: #FFF;
    background-color: #424242
}

.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active > a .pcoded-mtext:before {
    border-bottom-color: #424242
}

.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active > a .pcoded-mtext:after {
    border-left-color: #424242
}

.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-mtext:before {
    border-bottom-color: #fff
}

.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-mtext:after {
    border-left-color: #fff
}

.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li:hover > a:before {
    border-left-color: #424242
}

.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li.pcoded-hasmenu:hover > a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li.pcoded-trigger > a {
    border-bottom-color: #424242
}

.pcoded .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu:hover > a:after {
    color: #fff
}

.pcoded .pcoded-navbar[theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu, .pcoded .pcoded-navbar[theme-layout="vertical"][vertical-nav-type="sub-expanded"] .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
    border-left-color: transparent
}

.pcoded .pcoded-navbar[theme-layout="vertical"][vertical-nav-type="ex-popover"] .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
    border-left-color: transparent
}

.pcoded .pcoded-navbar[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar[navbar-theme="theme1"] {
    background-color: #424242
}

.pcoded .pcoded-navbar[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar[navbar-theme="theme1"] .pcoded-item > li:hover > a {
    color: #FFF
}

.pcoded .pcoded-navbar[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-navbar[navbar-theme="theme1"] .sidebar_toggle a {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme="themelight1"] {
    background-color: #fff
}

.pcoded .pcoded-navbar[navbar-theme="themelight1"] .main-menu .main-menu-header .user-details span {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme="themelight1"] .main-menu .main-menu-content .more-details a {
    color: #666
}

.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item > li.pcoded-hasmenu > a:after, .pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item > li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu > a:after {
    color: #666
}

.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item > li.active:hover > a, .pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item > li:hover > a {
    color: #666
}

.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item > li.pcoded-trigger > a {
    border-bottom-color: #fff
}

.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item > li > a {
    color: #666;
    border-bottom-color: #fff
}

.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item > li.active > a {
    color: #FFF
}

.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item .pcoded-submenu {
    background-color: #fff
}

.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item .pcoded-submenu li > a {
    border-bottom-color: #ededed;
    color: #666
}

.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item .pcoded-submenu li > a .pcoded-mtext:before {
    border-bottom-color: #fff
}

.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item .pcoded-submenu li > a .pcoded-mtext:after {
    border-left-color: #fff
}

.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item .pcoded-submenu li:hover > a:before {
    border-left-color: #fff
}

.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item .pcoded-submenu li.active > a {
    color: #666
}

.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item .pcoded-submenu li.active > a .pcoded-mtext:before {
    border-bottom-color: #fff
}

.pcoded .pcoded-navbar[navbar-theme="themelight1"] .pcoded-item .pcoded-submenu li.active > a .pcoded-mtext:after {
    border-left-color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item li .pcoded-submenu li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item li .pcoded-submenu li:hover > a {
    color: #29548E
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item li .pcoded-submenu li.active > a {
    font-weight: 500
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item li:hover > a {
    color: #29548E
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item li:hover > a .pcoded-micon {
    color: #29548E
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li:hover:hover > a {
    background: #29548E;
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.active > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.active:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.pcoded-trigger > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.pcoded-trigger:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li:hover:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item li .pcoded-submenu li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item li .pcoded-submenu li:hover > a {
    color: #ff5252
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item li .pcoded-submenu li.active > a {
    font-weight: 500
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item li:hover > a {
    color: #ff5252
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item li:hover > a .pcoded-micon {
    color: #ff5252
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li:hover:hover > a {
    background: #ff5252;
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.active > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.active:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.pcoded-trigger > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.pcoded-trigger:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li:hover:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item li .pcoded-submenu li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item li .pcoded-submenu li:hover > a {
    color: #D81B60
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item li .pcoded-submenu li.active > a {
    font-weight: 500
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item li:hover > a {
    color: #D81B60
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item li:hover > a .pcoded-micon {
    color: #D81B60
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li:hover:hover > a {
    background: #D81B60;
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.active > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.active:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.pcoded-trigger > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.pcoded-trigger:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li:hover:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item li .pcoded-submenu li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item li .pcoded-submenu li:hover > a {
    color: #66BB6A
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item li .pcoded-submenu li.active > a {
    font-weight: 500
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item li:hover > a {
    color: #66BB6A
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item li:hover > a .pcoded-micon {
    color: #66BB6A
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li:hover:hover > a {
    background: #66BB6A;
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.active > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.active:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.pcoded-trigger > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.pcoded-trigger:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li:hover:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item li .pcoded-submenu li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item li .pcoded-submenu li:hover > a {
    color: #7C4DFF
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item li .pcoded-submenu li.active > a {
    font-weight: 500
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item li:hover > a {
    color: #7C4DFF
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item li:hover > a .pcoded-micon {
    color: #7C4DFF
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li:hover:hover > a {
    background: #7C4DFF;
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.active > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.active:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.pcoded-trigger > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.pcoded-trigger:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li:hover:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item li .pcoded-submenu li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item li .pcoded-submenu li:hover > a {
    color: #424242
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item li .pcoded-submenu li.active > a {
    font-weight: 500
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item li:hover > a {
    color: #424242
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item li:hover > a .pcoded-micon {
    color: #424242
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li:hover:hover > a {
    background: #424242;
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.active > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.active:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.pcoded-trigger > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.pcoded-trigger:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li:hover:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item li .pcoded-submenu li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item li .pcoded-submenu li:hover > a {
    color: #11c788
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item li .pcoded-submenu li.active > a {
    font-weight: 500
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item li:hover > a {
    color: #11c788
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item li:hover > a .pcoded-micon {
    color: #11c788
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li:hover:hover > a {
    background: #11c788;
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.active > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.active:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.pcoded-trigger > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.pcoded-trigger:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li:hover:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item li .pcoded-submenu li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item li .pcoded-submenu li:hover > a {
    color: #f44
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item li .pcoded-submenu li.active > a {
    font-weight: 500
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item li:hover > a {
    color: #f44
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item li:hover > a .pcoded-micon {
    color: #f44
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li:hover:hover > a {
    background: #f44;
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.active > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.active:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.pcoded-trigger > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.pcoded-trigger:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li:hover:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item li .pcoded-submenu li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item li .pcoded-submenu li:hover > a {
    color: #ff784f
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item li .pcoded-submenu li.active > a {
    font-weight: 500
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item li:hover > a {
    color: #ff784f
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item li:hover > a .pcoded-micon {
    color: #ff784f
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li:hover:hover > a {
    background: #ff784f;
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.active > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.active:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.pcoded-trigger > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.pcoded-trigger:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li:hover:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item li .pcoded-submenu li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item li .pcoded-submenu li:hover > a {
    color: #38a9f9
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item li .pcoded-submenu li.active > a {
    font-weight: 500
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item li:hover > a {
    color: #38a9f9
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item li:hover > a .pcoded-micon {
    color: #38a9f9
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li:hover:hover > a {
    background: #38a9f9;
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.active > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.active:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.pcoded-trigger > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.pcoded-trigger:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li:hover:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item li .pcoded-submenu li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item li .pcoded-submenu li:hover > a {
    color: #038f9e
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item li .pcoded-submenu li.active > a {
    font-weight: 500
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item li:hover > a {
    color: #038f9e
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item li:hover > a .pcoded-micon {
    color: #038f9e
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li:hover:hover > a {
    background: #038f9e;
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.active > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.active:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.pcoded-trigger > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.pcoded-trigger:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li:hover:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item li .pcoded-submenu li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item li .pcoded-submenu li:hover > a {
    color: #39ADB5
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item li .pcoded-submenu li.active > a {
    font-weight: 500
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item li:hover > a {
    color: #39ADB5
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item li:hover > a .pcoded-micon {
    color: #39ADB5
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.active > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li:hover > a, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li:hover:hover > a {
    background: #39ADB5;
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.active > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.active:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.pcoded-trigger > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.pcoded-trigger:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li:hover > a:after, .pcoded[theme-layout="vertical"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li:hover:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item li:hover > a {
    background: #29548E;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item li:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item li.active > a:after, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item li:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li:hover:hover > a {
    background: #29548E;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme1"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item li:hover > a {
    background: #ff5252;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item li:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item li.active > a:after, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item li:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li:hover:hover > a {
    background: #ff5252;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme2"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item li:hover > a {
    background: #D81B60;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item li:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item li.active > a:after, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item li:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li:hover:hover > a {
    background: #D81B60;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme3"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item li:hover > a {
    background: #66BB6A;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item li:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item li.active > a:after, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item li:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li:hover:hover > a {
    background: #66BB6A;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme4"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item li:hover > a {
    background: #7C4DFF;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item li:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item li.active > a:after, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item li:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li:hover:hover > a {
    background: #7C4DFF;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme5"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item li:hover > a {
    background: #424242;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item li:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item li.active > a:after, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item li:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li:hover:hover > a {
    background: #424242;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme6"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item li:hover > a {
    background: #11c788;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item li:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item li.active > a:after, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item li:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li:hover:hover > a {
    background: #11c788;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme7"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item li:hover > a {
    background: #f44;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item li:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item li.active > a:after, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item li:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li:hover:hover > a {
    background: #f44;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme8"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item li:hover > a {
    background: #ff784f;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item li:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item li.active > a:after, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item li:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li:hover:hover > a {
    background: #ff784f;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme9"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item li:hover > a {
    background: #38a9f9;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item li:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item li.active > a:after, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item li:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li:hover:hover > a {
    background: #38a9f9;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme10"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item li:hover > a {
    background: #038f9e;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item li:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item li.active > a:after, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item li:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li:hover:hover > a {
    background: #038f9e;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme11"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item li:hover > a {
    background: #39ADB5;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item li:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item li:hover > a:before {
    border-left-color: transparent
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item li.active > a:after, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item li:hover > a:after {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.active:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.pcoded-trigger > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.pcoded-trigger:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li:hover > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li:hover:hover > a {
    background: #39ADB5;
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.active > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.active:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.pcoded-trigger > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.pcoded-trigger:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li:hover > a .pcoded-micon, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li:hover:hover > a .pcoded-micon {
    color: #fff
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.active > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.active:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.pcoded-trigger > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li.pcoded-trigger:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li:hover > a:before, .pcoded[theme-layout="horizontal"] .pcoded-navbar[active-item-theme="theme12"] .pcoded-item > li:hover:hover > a:before {
    border-left-color: transparent
}

.selector-toggle {
    position: relative
}

.pcoded .selector-toggle > a {
    position: absolute;
    top: 0;
    width: 60px;
    height: 60px;
    display: block;
    cursor: pointer;
    text-align: center;
    background: #ff5252;
    color: #fff;
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    padding: 14px 10px
}

.pcoded[theme-layout="horizontal"] .selector-toggle > a, .pcoded[vertical-placement="left"] .selector-toggle > a {
    left: -80px;
    border-right: 0;
    border-radius: 50% 0 0 50%
}

.pcoded[vertical-placement="right"] .selector-toggle > a {
    right: -80px;
    border-left: 0;
    border-radius: 0 50% 50% 0
}

.pcoded[vertical-placement="right"] .selector-toggle > a:before {
    left: 10px
}

.selector-toggle > a:before {
    font-family: 'feather';
    font-size: 20px;
    content: "\e8c3";
    position: relative;
    float: left;
    left: 13px;
    line-height: 35px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -webkit-animation: mymove 1.3s infinite linear;
    animation: mymove 1.3s infinite linear
}

.open .selector-toggle > a:before {
    content: "\e8f6";
    -webkit-animation: mymove 0s infinite linear;
    animation: mymove 0s infinite linear
}

#styleSelector .nav-tabs {
    margin-bottom: 20px;
    border-bottom: 2px solid #448aff;
    padding-bottom: 5px
}

#styleSelector .nav-tabs .nav-item {
    width: calc(100% / 2);
    margin: 0;
    padding: 0;
    float: left
}

#styleSelector .nav-tabs .nav-item a {
    color: #37474f;
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    padding: 0.50rem 1rem;
    border-radius: 2px;
    border: none;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

#styleSelector .nav-tabs .nav-item a.active {
    color: #fff;
    background: #448aff
}

#styleSelector .style-cont {
    background-color: #F6F7FB;
    padding: 10px
}

#styleSelector .st-main-title {
    font-size: 18px
}

#styleSelector .checkbox-fade label {
    margin-bottom: 0.5rem
}

#styleSelector .sub-title {
    text-transform: capitalize
}

#styleSelector #menu-effect {
    padding-bottom: 15px
}

#styleSelector #menu-effect .radio > label {
    padding-left: 0
}

#styleSelector #menu-effect .radio .helper {
    bottom: -30px;
    left: 3px
}

#styleSelector #menu-effect .radio .helper:after, #styleSelector #menu-effect .radio .helper:before {
    top: auto;
    bottom: 0
}

#styleSelector #menu-effect .micon {
    font-size: 20px;
    padding: 3px;
    width: 30px;
    display: block;
    height: 30px;
    text-align: center
}

#styleSelector #menu-effect .micon.st1 {
    background-color: transparent
}

#styleSelector #menu-effect .micon.st1 i {
    color: #448aff
}

#styleSelector #menu-effect .micon.st2 {
    background-color: transparent
}

#styleSelector #menu-effect .micon.st2 i {
    color: #37474f
}

#styleSelector #bg-pattern-visiblity {
    padding-left: 30px
}

#styleSelector .form-control {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #f1f1f1;
    margin-bottom: 10px
}

#styleSelector .form-control:focus {
    border: none;
    background-color: #f1f1f1
}

#styleSelector ul {
    border: 0 none;
    margin: 0;
    padding: 0;
    width: 100%
}

#styleSelector ul li {
    border-bottom: 0 solid #e9e9e9;
    display: block;
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%
}

#styleSelector ul li p.selector-title, #styleSelector ul li span.selector-title {
    color: #444;
    font-size: 14px;
    margin: 0 0 15px;
    padding: 10px 0;
    text-align: left
}

#styleSelector ul li p.selector-title {
    font-weight: 500;
    border-bottom: 1px solid #e9e9e9
}

#styleSelector ul li p.selector-title.main-title {
    font-weight: 500
}

#styleSelector ul li p.sub-title.drp-title {
    border-bottom: 0 solid transparent;
    margin-bottom: 0;
    margin-top: 10px
}

#styleSelector > ul > li > .sub-title {
    color: #9c9c9c;
    display: block;
    font-size: 13px;
    margin: 0;
    padding: 5px 0;
    position: relative;
    text-align: left
}

#styleSelector li {
    padding: 5px
}

.theme-option select {
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 3px 10px
}

.theme-color {
    padding: 0;
    width: 100%
}

.theme-color a {
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    margin: 5px 8px 5px 0;
    -webkit-box-shadow: 0.4px 0.9px 3px 0 rgba(0, 0, 0, 0.33);
    box-shadow: 0.4px 0.9px 3px 0 rgba(0, 0, 0, 0.33);
    overflow: hidden;
    width: 45px;
    height: 40px
}

.theme-color a:focus, .theme-color a:hover {
    text-decoration: none
}

.theme-color a.small {
    width: 20px;
    height: 20px
}

.theme-color a .cont, .theme-color a .head {
    display: block;
    position: relative
}

.theme-color a .cont:after, .theme-color a .cont:before, .theme-color a .head:after, .theme-color a .head:before {
    content: "";
    height: 100%;
    display: inline-block;
    position: absolute
}

.theme-color a .cont:after, .theme-color a .head:after {
    width: 40%;
    left: 0
}

.theme-color a .cont:before, .theme-color a .head:before {
    width: 60%;
    right: 0
}

.theme-color a .head {
    height: 10px
}

.theme-color a .head:before {
    background: #fff
}

.theme-color a .cont {
    height: 30px
}

.theme-color a .cont:before {
    background: #F6F7FB
}

.theme-color a[menu-caption="theme1"] {
    background: #29548E
}

.theme-color a[menu-caption="theme2"] {
    background: #ff5252
}

.theme-color a[menu-caption="theme3"] {
    background: #D81B60
}

.theme-color a[menu-caption="theme4"] {
    background: #66BB6A
}

.theme-color a[menu-caption="theme5"] {
    background: #7C4DFF
}

.theme-color a[menu-caption="theme6"] {
    background: #424242
}

.theme-color a[menu-caption="theme7"] {
    background: #000
}

.theme-color a[menu-caption="theme8"] {
    background: #999
}

.theme-color a[menu-caption="theme9"] {
    background: #fff
}

.theme-color a[header-theme="theme1"] .head {
    background: #29548E
}

.theme-color a[header-theme="theme1"] .head:after, .theme-color a[header-theme="theme1"] .head:before {
    background: transparent
}

.theme-color a[header-theme="theme2"] .head {
    background: #ff5252
}

.theme-color a[header-theme="theme2"] .head:after, .theme-color a[header-theme="theme2"] .head:before {
    background: transparent
}

.theme-color a[header-theme="theme3"] .head {
    background: #D81B60
}

.theme-color a[header-theme="theme3"] .head:after, .theme-color a[header-theme="theme3"] .head:before {
    background: transparent
}

.theme-color a[header-theme="theme4"] .head {
    background: #66BB6A
}

.theme-color a[header-theme="theme4"] .head:after, .theme-color a[header-theme="theme4"] .head:before {
    background: transparent
}

.theme-color a[header-theme="theme5"] .head {
    background: #7C4DFF
}

.theme-color a[header-theme="theme5"] .head:after, .theme-color a[header-theme="theme5"] .head:before {
    background: transparent
}

.theme-color a[header-theme="theme6"] .head {
    background: #424242
}

.theme-color a[header-theme="theme6"] .head:after, .theme-color a[header-theme="theme6"] .head:before {
    background: transparent
}

.theme-color a[navbar-theme="theme1"] {
    background: #424242
}

.theme-color a[navbar-theme="themelight1"] {
    background: #fff
}

.theme-color a[active-item-theme="theme1"] {
    background: #29548E
}

.theme-color a[active-item-theme="theme2"] {
    background: #ff5252
}

.theme-color a[active-item-theme="theme3"] {
    background: #D81B60
}

.theme-color a[active-item-theme="theme4"] {
    background: #66BB6A
}

.theme-color a[active-item-theme="theme5"] {
    background: #7C4DFF
}

.theme-color a[active-item-theme="theme6"] {
    background: #424242
}

.theme-color a[active-item-theme="theme7"] {
    background: #11c788
}

.theme-color a[active-item-theme="theme8"] {
    background: #f44
}

.theme-color a[active-item-theme="theme9"] {
    background: #ff784f
}

.theme-color a[active-item-theme="theme10"] {
    background: #38a9f9
}

.theme-color a[active-item-theme="theme11"] {
    background: #038f9e
}

.theme-color a[active-item-theme="theme12"] {
    background: #39ADB5
}

.theme-color a[layout-type="dark"] .cont:after {
    background: #424242
}

.theme-color a[layout-type="dark"] .cont:before {
    background: #5c5c5c
}

.theme-color a[layout-type="dark"] .head:after {
    background: #5c5c5c
}

.theme-color a[layout-type="dark"] .head:before {
    background: #424242
}

.theme-color a[layout-type="reset"] {
    padding: 11px 0;
    text-align: center;
    background-color: #ff5252;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.theme-color a[layout-type="reset"] i {
    font-size: 20px;
    margin-right: 0;
    color: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.theme-color a[layout-type="reset"]:hover {
    background-color: rgba(255, 82, 82, 0.8)
}

.theme-color a[layout-type="reset"]:hover i {
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.3)
}

.theme-color a[themebg-pattern="theme1"] {
    background: #29548E
}

.theme-color a[themebg-pattern="theme2"] {
    background: #ff5252
}

.theme-color a[themebg-pattern="theme3"] {
    background: #D81B60
}

.theme-color a[themebg-pattern="theme4"] {
    background: #66BB6A
}

.theme-color a[themebg-pattern="theme5"] {
    background: #7C4DFF
}

.theme-color a[themebg-pattern="theme6"] {
    background: #424242
}

.theme-color a[themebg-pattern="theme9"] {
    background: #fff
}

.pcoded[theme-layout="vertical"][vertical-placement="right"] .pcoded-navbar .pcoded-item > li > a .pcoded-micon {
    float: none;
    position: relative;
    z-index: 1051;
    left: 90%;
    top: 1px
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"][vertical-placement="right"] .pcoded-navbar .pcoded-item > li > a .pcoded-micon i {
    left: 0
}

.pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"][vertical-placement="right"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu.pcoded-trigger > a .pcoded-micon i, .pcoded[theme-layout="vertical"][vertical-nav-type="collapsed"][vertical-placement="right"] .pcoded-navbar .pcoded-item > li.pcoded-trigger.active > a .pcoded-micon i {
    left: 94%
}

.pcoded.nocollapsed[theme-layout="vertical"][pcoded-device-type="phone"] .pcoded-main-container {
    position: static
}

.pcoded[theme-layout="vertical"][vertical-nav-type="fullpage"] .pcoded-main-container, .pcoded[theme-layout="vertical"][vnavigation-view="view3"] .pcoded-main-container {
    position: static
}

.pcoded .pcoded-header[header-theme="theme1"] .dropdown-toggle, .pcoded .pcoded-header[header-theme="theme1"] .input-group-addon, .pcoded .pcoded-header[header-theme="theme1"] a {
    color: #fff
}

.pcoded .pcoded-header[header-theme="theme2"] {
    background: #ff5252
}

.pcoded .pcoded-header[header-theme="theme2"] .dropdown-toggle, .pcoded .pcoded-header[header-theme="theme2"] .input-group-addon, .pcoded .pcoded-header[header-theme="theme2"] a {
    color: #fff
}

.pcoded .pcoded-header[header-theme="theme3"] {
    background: #D81B60
}

.pcoded .pcoded-header[header-theme="theme3"] .dropdown-toggle, .pcoded .pcoded-header[header-theme="theme3"] .input-group-addon, .pcoded .pcoded-header[header-theme="theme3"] a {
    color: #fff
}

.pcoded .pcoded-header[header-theme="theme4"] {
    background: #66BB6A
}

.pcoded .pcoded-header[header-theme="theme4"] .dropdown-toggle, .pcoded .pcoded-header[header-theme="theme4"] .input-group-addon, .pcoded .pcoded-header[header-theme="theme4"] a {
    color: #fff
}

.pcoded .pcoded-header[header-theme="theme5"] {
    background: #7C4DFF
}

.pcoded .pcoded-header[header-theme="theme5"] .dropdown-toggle, .pcoded .pcoded-header[header-theme="theme5"] .input-group-addon, .pcoded .pcoded-header[header-theme="theme5"] a {
    color: #fff
}

.pcoded .pcoded-header[header-theme="theme6"] {
    background: #424242
}

.pcoded .pcoded-header[header-theme="theme6"] .dropdown-toggle, .pcoded .pcoded-header[header-theme="theme6"] .input-group-addon, .pcoded .pcoded-header[header-theme="theme6"] a {
    color: #fff
}

.pcoded[fream-type="theme1"][theme-layout="vertical"] .page-header {
    background: #29548E
}

.pcoded[fream-type="theme1"][theme-layout="horizontal"] .page-header {
    background: #93b5ff
}

.pcoded[fream-type="theme2"][theme-layout="vertical"] .page-header {
    background: #ff5252
}

.pcoded[fream-type="theme2"][theme-layout="horizontal"] .page-header {
    background: #ff9f9f
}

.pcoded[fream-type="theme3"][theme-layout="vertical"] .page-header {
    background: #D81B60
}

.pcoded[fream-type="theme3"][theme-layout="horizontal"] .page-header {
    background: #ea568c
}

.pcoded[fream-type="theme4"][theme-layout="vertical"] .page-header {
    background: #66BB6A
}

.pcoded[fream-type="theme4"][theme-layout="horizontal"] .page-header {
    background: #9bd39e
}

.pcoded[fream-type="theme5"][theme-layout="vertical"] .page-header {
    background: #7C4DFF
}

.pcoded[fream-type="theme5"][theme-layout="horizontal"] .page-header {
    background: #b49aff
}

.pcoded[fream-type="theme6"][theme-layout="vertical"] .page-header {
    background: #424242
}

.pcoded[fream-type="theme6"][theme-layout="horizontal"] .page-header {
    background: #686868
}

.pcoded.pcoded-toggle-animate .pcoded-content {
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out
}

.pcoded.pcoded-toggle-animate .pcoded-header .pcoded-left-header, .pcoded.pcoded-toggle-animate .pcoded-header .pcoded-right-header {
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out
}

.pcoded.pcoded-toggle-animate .pcoded-navbar {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.pcoded.pcoded-toggle-animate .pcoded-navbar .user-details {
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg)
}

.pcoded.pcoded-toggle-animate .pcoded-navbar .pcoded-item > li > a .pcoded-mtext {
    -webkit-transition-delay: 0.20s;
    transition-delay: 0.20s;
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg)
}

.pcoded.pcoded-toggle-animate .pcoded-navbar .pcoded-item > li.pcoded-trigger > a .pcoded-mtext {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg)
}

.pcoded.pcoded-toggle-animate .pcoded-navbar .pcoded-item li a .pcoded-badge {
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg)
}

.pcoded.pcoded-toggle-animate .pcoded-navbar .pcoded-item li.pcoded-hasmenu.pcoded-trigger a:after, .pcoded.pcoded-toggle-animate .pcoded-navbar .pcoded-item li.pcoded-hasmenu > a:after {
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg)
}

.pcoded.pcoded-toggle-animate .pcoded-navbar .pcoded-item > li.pcoded-hasmenu > a:after {
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    -webkit-transition: opacity 0.5s linear;
    transition: opacity 0.5s linear
}

body .pcoded[pcoded-device-type="phone"][vertical-nav-type="expanded"] .pcoded-navbar, body .pcoded[pcoded-device-type="phone"][vertical-nav-type="expanded"] .pcoded-navbar[pcoded-navbar-position="fixed"], body .pcoded[pcoded-device-type="tablet"][vertical-nav-type="expanded"] .pcoded-navbar, body .pcoded[pcoded-device-type="tablet"][vertical-nav-type="expanded"] .pcoded-navbar[pcoded-navbar-position="fixed"] {
    position: absolute;
    top: 50px
}

body .pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > li > a .pcoded-micon {
    left: 0
}

body .pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"] .pcoded-navbar .pcoded-item > li.pcoded-trigger > a .pcoded-micon {
    left: calc(100% - 23px)
}

body .pcoded[theme-layout="vertical"][vertical-placement="right"][vertical-nav-type="collapsed"] .pcoded-item > li.pcoded-trigger > a > .pcoded-mtext {
    padding-right: 90px
}

body .pcoded[vertical-placement="right"] .showChat_inner, body .pcoded[vertical-placement="right"] .users {
    right: auto;
    left: 0
}

body .pcoded[vertical-placement="right"] .main-body {
    direction: rtl
}

body .pcoded[vertical-placement="right"] .main-body .page-wrapper .page-header-breadcrumb {
    float: left
}

body .pcoded[vertical-placement="right"] .main-body .language-markup {
    direction: ltr
}

body .pcoded[vertical-placement="right"] .back_chatBox i, body .pcoded[vertical-placement="right"] .back_friendlist i {
    left: auto;
    right: -40px;
    border-radius: 0 50% 50% 0;
    padding: 12px 7px 12px 0;
    -webkit-box-shadow: 2px 0 9px -2px rgba(0, 0, 0, 0.3);
    box-shadow: 2px 0 9px -2px rgba(0, 0, 0, 0.3)
}

body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-logo {
    float: right
}

body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container {
    margin-left: 0;
    margin-right: 240px
}

body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .nav-left li, body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .nav-right li, body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .nav-right li {
    float: right
}

body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .nav-left, body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .nav-right {
    float: right
}

body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .nav-right {
    float: left
}

body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification, body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification, body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification {
    right: auto;
    left: -15px
}

body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification:before, body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification:before, body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification:before {
    right: auto;
    left: 15px
}

body .pcoded[vertical-placement="right"][vertical-nav-type="collapsed"] .header-navbar .navbar-wrapper .navbar-container {
    margin-right: 0
}

body .pcoded[vertical-nav-type="collapsed"] .header-navbar .navbar-wrapper .navbar-container {
    margin-left: 0
}

body.menu-bottom .navbar-wrapper {
    z-index: 1050
}

body.menu-bottom .p-chat-user, body.menu-bottom .showChat_inner {
    top: 0
}

body.menu-bottom .pcoded[pcoded-device-type="phone"][vertical-nav-type="expanded"] .pcoded-navbar, body.menu-bottom .pcoded[pcoded-device-type="phone"][vertical-nav-type="expanded"] .pcoded-navbar[pcoded-navbar-position="fixed"], body.menu-bottom .pcoded[pcoded-device-type="tablet"][vertical-nav-type="expanded"] .pcoded-navbar, body.menu-bottom .pcoded[pcoded-device-type="tablet"][vertical-nav-type="expanded"] .pcoded-navbar[pcoded-navbar-position="fixed"] {
    top: 0
}

body.menu-bottom .navbar-wrapper {
    bottom: 0;
    top: auto;
    z-index: 1050
}

@media only screen and (max-width: 992px) {
    body.menu-bottom .navbar-wrapper .pcoded-navbar {
        top: -50px
    }
}

body.menu-bottom .pcoded[theme-layout="vertical"] .header-navbar {
    min-height: auto;
    bottom: 0;
    top: auto;
    z-index: 9999
}

body.menu-bottom .pcoded[theme-layout="vertical"] .header-navbar[pcoded-header-position="absolute"], body.menu-bottom .pcoded[theme-layout="vertical"] .header-navbar[pcoded-header-position="relative"] {
    position: fixed
}

body.menu-bottom .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification, body.menu-bottom .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification {
    top: auto;
    bottom: 100px
}

body.menu-bottom .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification:before, body.menu-bottom .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification:before {
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    top: auto;
    bottom: -10px
}

body.menu-bottom .pcoded[pcoded-device-type="tablet"] .pcoded-content, body.menu-bottom .pcoded[pcoded-device-type="phone"] .pcoded-content {
    padding-top: 0
}

body.menu-bottom .pcoded[theme-layout="vertical"] .pcoded-navbar[pcoded-navbar-position="fixed"] {
    top: 0
}

body.menu-bottom #collapse-menu {
    display: none
}

.pcoded[pcoded-device-type="tablet"] .pcoded-header[pcoded-header-position="relative"], .pcoded[pcoded-device-type="tablet"] .pcoded-header[pcoded-header-position="absolute"], .pcoded[pcoded-device-type="phone"] .pcoded-header[pcoded-header-position="relative"], .pcoded[pcoded-device-type="phone"] .pcoded-header[pcoded-header-position="absolute"] {
    position: fixed
}

.pcoded[pcoded-device-type="tablet"] .pcoded-content, .pcoded[pcoded-device-type="phone"] .pcoded-content {
    padding-top: 50px
}

.pcoded[theme-layout="horizontal"] .pcoded-container {
    position: relative
}

.pcoded[theme-layout="horizontal"] .pcoded-header {
    z-index: 1024
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar {
    display: block;
    height: 50px;
    width: 100%;
    z-index: 1023;
    -webkit-box-shadow: 0 2px 7px -3px rgba(0, 0, 0, 0.4);
    box-shadow: 0 2px 7px -3px rgba(0, 0, 0, 0.4)
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
    opacity: 1;
    visibility: visible;
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg)
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar li.pcoded-hasmenu > a:after {
    right: 3px;
    top: -3px
}

.pcoded[theme-layout="horizontal"] #styleSelector {
    z-index: 1024
}

.pcoded[theme-layout="horizontal"][pcoded-device-type="phone"] .p-chat-user, .pcoded[theme-layout="horizontal"][pcoded-device-type="phone"] .showChat_inner, .pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .p-chat-user, .pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .showChat_inner {
    top: 107px
}

.pcoded[theme-layout="horizontal"][pcoded-device-type="desktop"] .navbar-logo .mobile-menu, .pcoded[theme-layout="horizontal"][pcoded-device-type="desktop"] .pcoded-brand .menu-toggle {
    display: none
}

.pcoded[theme-layout="horizontal"] .page-header {
    padding-top: 50px
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item[item-border="true"][item-border-style="dashed"] > li > a {
    border-right-style: dashed
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item[item-border="true"][item-border-style="dotted"] > li > a {
    border-right-style: dotted
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item[item-border="true"][item-border-style="solid"] > li > a {
    border-right-style: solid
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item[item-border="true"][item-border-style="dashed"] .pcoded-hasmenu .pcoded-submenu li > a {
    border-bottom-style: dashed
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item[item-border="true"][item-border-style="dotted"] .pcoded-hasmenu .pcoded-submenu li > a {
    border-bottom-style: dotted
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item[item-border="true"][item-border-style="solid"] .pcoded-hasmenu .pcoded-submenu li > a {
    border-bottom-style: solid
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item[item-border="false"] > li > a {
    border-right-width: 0
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item[item-border="true"] > li > a {
    border-right-width: 1px
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item[subitem-border="false"] .pcoded-hasmenu .pcoded-submenu li > a {
    border-bottom-width: 0
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item[subitem-border="true"] .pcoded-hasmenu .pcoded-submenu li > a {
    border-bottom-width: 1px
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item > li {
    display: block;
    list-style: outside none none;
    margin: 0;
    padding: 5px;
    position: relative
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item > li > a {
    display: block;
    font-size: 13px;
    padding: 5px 20px;
    text-decoration: none;
    position: relative
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
    .pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item > li > a {
        padding: 5px
    }
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu > a {
    line-height: 1.85
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu {
    list-style: outside none none;
    margin: 0;
    padding: 5px 0;
    -webkit-box-shadow: 0 1px 7px -1px rgba(0, 0, 0, 0.4);
    box-shadow: 0 1px 7px -1px rgba(0, 0, 0, 0.4)
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li {
    display: block;
    list-style: outside none none;
    margin: 0;
    padding: 3px 10px;
    position: relative
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a {
    display: block;
    font-size: 14px;
    padding: 7px 10px;
    text-decoration: none;
    position: relative
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a:after {
    padding-top: 7px;
    line-height: 1
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item > li > a > .pcoded-micon {
    font-size: 14px;
    padding: 0
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item.pcoded-search-item > li > a > .pcoded-micon {
    padding: 0 5px 0 7px
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-micon {
    font-size: 13px;
    padding-right: 5px
}

.pcoded[theme-layout="horizontal"][horizontal-layout="widebox"] .pcoded-container {
    width: 100%
}

.pcoded[theme-layout="horizontal"][horizontal-layout="widebox"] .pcoded-content .pcoded-wrapper, .pcoded[theme-layout="horizontal"][horizontal-layout="widebox"] .pcoded-header .pcoded-wrapper, .pcoded[theme-layout="horizontal"][horizontal-layout="widebox"] .pcoded-navbar .pcoded-wrapper {
    display: block;
    margin: 0 auto;
    max-width: 1200px;
    width: 100%
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item > li {
    float: left;
    padding: 5px 10px
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item li a {
    border-radius: 5px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item li.active > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item li.pcoded-trigger > a, .pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item li:hover > a {
    -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2)
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu {
    position: absolute;
    min-width: 200px;
    z-index: 1;
    border-top-width: 0;
    top: 100%
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a {
    font-size: 14px;
    font-weight: 500;
    text-align: left
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a > .pcoded-mtext {
    -webkit-transition: all 0s ease-in-out;
    transition: all 0s ease-in-out
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a > .pcoded-mtext:before {
    -webkit-transition: all 0s ease-in-out;
    transition: all 0s ease-in-out
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item .pcoded-submenu .pcoded-submenu {
    position: absolute;
    min-width: 250px;
    z-index: 1;
    left: 100%;
    top: -1px
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu {
    opacity: 0;
    visibility: hidden;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotateX(-90deg);
    transform: rotateX(-90deg)
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item .pcoded-submenu li.pcoded-hasmenu > a:after {
    margin-left: 3px;
    position: relative;
    float: right;
    top: -3px
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu > a:after, .pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu.active > a:after, .pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu.pcoded-trigger > a:after {
    font-style: normal;
    margin-left: 3px;
    padding-left: 5px;
    position: relative
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.edge.pcoded-trigger > .pcoded-submenu {
    right: 0
}

.pcoded[theme-layout="horizontal"][horizontal-placement="top"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu.pcoded-trigger.active .pcoded-submenu {
    border-top-style: solid;
    border-top-width: 1px
}

.pcoded[theme-layout="horizontal"] .pcoded-navbar .pcoded-item[subitem-border="true"] .pcoded-hasmenu .pcoded-submenu > li:last-child > a {
    border-bottom-color: transparent
}

.pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item.pcoded-search-item > li > a > .pcoded-micon {
    display: block
}

.pcoded[theme-layout="horizontal"][pcoded-device-type="desktop"] .pcoded-navbar .pcoded-inner-navbar, .pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-inner-navbar {
    position: relative
}

.pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .pcoded-navbar, .pcoded[theme-layout="horizontal"][pcoded-device-type="phone"] .pcoded-navbar {
    height: auto;
    padding: 10px 10px 0;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotateX(-90deg);
    transform: rotateX(-90deg)
}

.pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-inner-navbar, .pcoded[theme-layout="horizontal"][pcoded-device-type="phone"] .pcoded-navbar .pcoded-inner-navbar {
    height: auto;
    position: relative
}

.pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .pcoded-navbar.show-menu, .pcoded[theme-layout="horizontal"][pcoded-device-type="phone"] .pcoded-navbar.show-menu {
    opacity: 1;
    visibility: visible;
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg)
}

.pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item, .pcoded[theme-layout="horizontal"][pcoded-device-type="phone"] .pcoded-navbar .pcoded-item {
    display: block;
    float: none;
    position: relative;
    width: 100%;
    z-index: 1
}

.pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item > li, .pcoded[theme-layout="horizontal"][pcoded-device-type="phone"] .pcoded-navbar .pcoded-item > li {
    float: none;
    display: block;
    position: relative
}

.pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item[item-border="true"] > li > a, .pcoded[theme-layout="horizontal"][pcoded-device-type="phone"] .pcoded-navbar .pcoded-item[item-border="true"] > li > a {
    border-right-width: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid
}

.pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item[item-border="true"] > li.pcoded-trigger > a, .pcoded[theme-layout="horizontal"][pcoded-device-type="phone"] .pcoded-navbar .pcoded-item[item-border="true"] > li.pcoded-trigger > a {
    border-bottom-width: 0
}

.pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu > .pcoded-submenu, .pcoded[theme-layout="horizontal"][pcoded-device-type="phone"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu > .pcoded-submenu {
    min-width: 100%;
    z-index: 2
}

.pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu, .pcoded[theme-layout="horizontal"][pcoded-device-type="phone"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
    position: relative
}

.pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu > .pcoded-submenu > .pcoded-hasmenu > .pcoded-submenu, .pcoded[theme-layout="horizontal"][pcoded-device-type="phone"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu > .pcoded-submenu > .pcoded-hasmenu > .pcoded-submenu {
    left: 0;
    min-width: 100%;
    right: 0;
    top: 0;
    z-index: 1
}

.pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu > .pcoded-submenu > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu, .pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu > .pcoded-submenu > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu, .pcoded[theme-layout="horizontal"][pcoded-device-type="phone"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu > .pcoded-submenu > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu, .pcoded[theme-layout="horizontal"][pcoded-device-type="phone"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu > .pcoded-submenu > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
    position: relative
}

.pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu > .pcoded-submenu > .pcoded-hasmenu > .pcoded-submenu > .pcoded-hasmenu > .pcoded-submenu, .pcoded[theme-layout="horizontal"][pcoded-device-type="phone"] .pcoded-navbar .pcoded-item > .pcoded-hasmenu > .pcoded-submenu > .pcoded-hasmenu > .pcoded-submenu > .pcoded-hasmenu > .pcoded-submenu {
    left: 0;
    min-width: 100%;
    right: 0;
    top: 0;
    z-index: 1
}

.pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"][horizontal-placement="bottom"] .pcoded-navbar, .pcoded[theme-layout="horizontal"][pcoded-device-type="phone"][horizontal-placement="bottom"] .pcoded-navbar {
    bottom: auto
}

.pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu > a:after, .pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu.active > a:after, .pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu.pcoded-trigger > a:after, .pcoded[theme-layout="horizontal"][pcoded-device-type="phone"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu > a:after, .pcoded[theme-layout="horizontal"][pcoded-device-type="phone"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu.active > a:after, .pcoded[theme-layout="horizontal"][pcoded-device-type="phone"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu.pcoded-trigger > a:after {
    position: absolute;
    right: 13px
}

.pcoded[theme-layout="horizontal"][pcoded-device-type="phone"] .pcoded-header, .pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .pcoded-header {
    height: auto;
    padding-top: 0
}

.pcoded[theme-layout="horizontal"][pcoded-device-type="phone"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu, .pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu {
    border-top: none;
    margin: 15px auto;
    min-width: 90%;
    width: 90%;
    -webkit-box-shadow: none;
    box-shadow: none
}

.pcoded[theme-layout="horizontal"][pcoded-device-type="phone"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu .pcoded-hasmenu .pcoded-submenu, .pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu .pcoded-hasmenu .pcoded-submenu {
    min-width: 90%;
    width: 90%
}

.pcoded[theme-layout="horizontal"][pcoded-device-type="phone"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu .pcoded-hasmenu .pcoded-submenu .pcoded-hasmenu .pcoded-submenu, .pcoded[theme-layout="horizontal"][pcoded-device-type="tablet"] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu .pcoded-hasmenu .pcoded-submenu .pcoded-hasmenu .pcoded-submenu {
    min-width: 90%
}

.pcoded[theme-layout="horizontal"] .pcoded-header[pcoded-header-position="relative"] ~ .pcoded-main-container {
    top: -1px
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-submenu > li a .pcoded-badge {
    top: 7px;
    right: 20px
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu.edge > .pcoded-submenu {
    left: auto;
    right: 100%
}

.pcoded-main-container {
    clear: both
}

.pcoded .pcoded-navbar .pcoded-item:after {
    display: none
}

body.horizontal-icon .pcoded[theme-layout="horizontal"] .page-header, body.horizontal-icon-fixed .pcoded[theme-layout="horizontal"] .page-header {
    padding-top: 100px
}

body.horizontal-icon .pcoded .pcoded-navbar, body.horizontal-icon-fixed .pcoded .pcoded-navbar {
    height: 80px
}

body.horizontal-icon .pcoded .pcoded-navbar .pcoded-item > li > a > .pcoded-micon, body.horizontal-icon-fixed .pcoded .pcoded-navbar .pcoded-item > li > a > .pcoded-micon {
    display: block;
    text-align: center;
    margin: 0 auto
}

body.horizontal-icon .pcoded .pcoded-navbar .pcoded-item > li.pcoded-hasmenu > a, body.horizontal-icon-fixed .pcoded .pcoded-navbar .pcoded-item > li.pcoded-hasmenu > a {
    line-height: 30px
}

body.horizontal-icon .pcoded[pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu > a, body.horizontal-icon-fixed .pcoded[pcoded-device-type="desktop"] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu > a {
    height: 70px
}

.btn-default .badge {
    background-color: #fff;
    color: #e0e0e0
}

.btn {
    border-radius: 2px;
    text-transform: capitalize;
    font-size: 15px;
    padding: 10px 19px;
    cursor: pointer
}

@media only screen and (max-width: 480px) {
    .btn {
        padding: 10px 15px
    }
}

.close {
    cursor: pointer
}

.button-page .card-block {
    margin-bottom: -20px
}

.button-page .card-block ul {
    margin-bottom: 0;
    display: inline-block
}

.button-page .card-block a {
    margin-bottom: 20px
}

.button-page .card-block ul li {
    display: inline-block;
    margin-right: 20px;
    float: left
}

.button-page .btn {
    margin-bottom: 20px;
    margin-right: 10px
}

.btn.btn-round {
    border-radius: 2rem
}

.btn.btn-square {
    border-radius: 0
}

.btn.btn-skew {
    -webkit-transform: skew(-15deg);
    transform: skew(-15deg)
}

.btn i {
    margin-right: 5px
}

.btn.btn-icon {
    border-radius: 50%;
    width: 40px;
    line-height: 30px;
    height: 40px;
    padding: 3px;
    text-align: center
}

.btn.btn-out {
    outline: 1px solid #fff;
    outline-offset: -5px
}

.btn.btn-out-dashed {
    outline: 1px dashed #fff;
    outline-offset: -5px
}

.btn.btn-out-dotted {
    outline: 1px dotted #fff;
    outline-offset: -5px
}

.btn-group, .btn-group-vertical {
    display: inline-block
}

.btn-group .btn {
    float: left
}

.icon-btn i {
    margin-right: 0
}

.button-page .btn-group {
    margin-right: 10px
}

.show > .dropdown-menu {
    overflow: hidden
}

.dropdown-danger, .dropdown-default, .dropdown-disabled, .dropdown-info, .dropdown-inverse, .dropdown-primary, .dropdown-success, .dropdown-warning, .dropdown-warning {
    display: inline-block;
    position: relative
}

.btn-group [class*=btn-], .dropdown-split [class*=btn-] {
    margin-right: 0
}

.list-group-item.active {
    background-color: #448aff;
    border-color: #448aff
}

.show > .btn-primary.dropdown-toggle, .sweet-alert .show > button.dropdown-toggle.confirm, .wizard > .actions .show > a.dropdown-toggle {
    background-color: #0764ff;
    border-color: #0764ff;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff
}

#Note-list li:hover .Note-delete {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px)
}

#Note-list li:hover .Note-delete:hover {
    background: rgba(0, 0, 0, 0.8)
}

.button-list .btn {
    margin-bottom: 10px;
    margin-right: 30px
}

.Note-delete {
    margin-bottom: 0;
    opacity: 0;
    background: rgba(0, 0, 0, 0.6);
    border: none;
    position: absolute;
    right: 20px;
    color: #fff;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s;
    border-radius: 2px;
    cursor: pointer;
    -webkit-transform: translateX(10px);
    transform: translateX(10px)
}

.popover-body .color-code {
    margin-bottom: 10px
}

.popover-body .display-color {
    width: 75px;
    height: 2.50rem;
    border-radius: 0.25rem;
    margin-right: 30px;
    background-color: rgba(68, 138, 255, 0.5);
    border-color: rgba(68, 138, 255, 0.5)
}

.popover-body span.block {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 14px
}

.popover-title {
    text-align: center
}

.btn.btn-disabled, .dropdown-disabled, .dropdown-split-disabled {
    cursor: not-allowed
}

.nav-pills .nav-link.active, .nav-pills .nav-link.active:focus, .nav-pills .nav-link.active:hover, .nav-pills .nav-link.active, .nav-pills .nav-link.active:focus, .nav-pills .nav-link.active:hover, .nav-pills .nav-link.active.active, .nav-pills .nav-link.active.active:focus, .nav-pills .nav-link.active.active:hover {
    background: transparent;
    color: #0275d8;
    text-align: center;
    border-bottom: 1px solid #0275d8
}

.btn-primary, .sweet-alert button.confirm, .wizard > .actions a {
    background-color: #448aff;
    border-color: #448aff;
    color: #fff;
    cursor: pointer;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s
}

.btn-primary:hover, .sweet-alert button.confirm:hover, .wizard > .actions a:hover {
    background-color: #7af;
    border-color: #7af
}

.btn-primary:active, .sweet-alert button.confirm:active, .wizard > .actions a:active {
    background-color: #0764ff;
    border-color: #0764ff;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff
}

.btn-primary:focus, .sweet-alert button.confirm:focus, .wizard > .actions a:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    background-color: #7af
}

.btn-primary.disabled, .sweet-alert button.disabled.confirm, .wizard > .actions a.disabled {
    background-color: rgba(68, 138, 255, 0.5);
    border-color: rgba(68, 138, 255, 0.5)
}

.btn-warning {
    background-color: #ffba57;
    border-color: #ffba57;
    color: #fff;
    cursor: pointer;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s
}

.btn-warning:hover {
    background-color: #ffcf8a;
    border-color: #ffcf8a;
    color: #fff
}

.btn-warning:active {
    background-color: #ffa11a;
    border-color: #ffa11a;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff
}

.btn-warning:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    background-color: #ffcf8a
}

.btn-warning.disabled {
    background-color: rgba(255, 186, 87, 0.5);
    border-color: rgba(255, 186, 87, 0.5)
}

.btn-danger {
    background-color: #ff5252;
    border-color: #ff5252;
    color: #fff;
    cursor: pointer;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s
}

.btn-danger:hover {
    background-color: #ff8585;
    border-color: #ff8585
}

.btn-danger:active {
    background-color: #ff1515;
    border-color: #ff1515;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff
}

.btn-danger:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    background-color: #ff8585
}

.btn-danger.disabled {
    background-color: rgba(255, 82, 82, 0.5);
    border-color: rgba(255, 82, 82, 0.5)
}

.btn-success {
    background-color: #9ccc65;
    border-color: #9ccc65;
    color: #fff;
    cursor: pointer;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s
}

.btn-success:hover {
    background-color: #b5d98b;
    border-color: #b5d98b
}

.btn-success:active {
    background-color: #7eb73d;
    border-color: #7eb73d;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff
}

.btn-success:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    background-color: #b5d98b
}

.btn-success.disabled {
    background-color: rgba(156, 204, 101, 0.5);
    border-color: rgba(156, 204, 101, 0.5)
}

.btn-inverse {
    background-color: #37474f;
    border-color: #37474f;
    color: #fff;
    cursor: pointer;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s
}

.btn-inverse:hover {
    background-color: #4c626d;
    border-color: #4c626d
}

.btn-inverse:active {
    background-color: #1e272b;
    border-color: #1e272b;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff
}

.btn-inverse:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    background-color: #4c626d
}

.btn-inverse.disabled {
    background-color: rgba(55, 71, 79, 0.5);
    border-color: rgba(55, 71, 79, 0.5)
}

.btn-info {
    background-color: #00bcd4;
    border-color: #00bcd4;
    color: #fff;
    cursor: pointer;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s
}

.btn-info:hover {
    background-color: #08e3ff;
    border-color: #08e3ff
}

.btn-info:active {
    background-color: #008697;
    border-color: #008697;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff
}

.btn-info:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    background-color: #08e3ff
}

.btn-info.disabled {
    background-color: rgba(0, 188, 212, 0.5);
    border-color: rgba(0, 188, 212, 0.5)
}

.btn-disabled {
    background-color: rgba(68, 138, 255, 0.5);
    border-color: rgba(68, 138, 255, 0.5);
    color: #fff;
    cursor: pointer;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s
}

.btn-outline-primary {
    color: #0764ff;
    background-color: #fff;
    background-color: transparent
}

.btn-outline-warning {
    color: #ffa11a;
    background-color: #fff;
    background-color: transparent
}

.btn-outline-danger {
    color: #ff1515;
    background-color: #fff;
    background-color: transparent
}

.btn-outline-success {
    color: #7eb73d;
    background-color: #fff;
    background-color: transparent
}

.btn-outline-inverse {
    color: #1e272b;
    background-color: #fff;
    background-color: transparent
}

.btn-outline-inverse:hover {
    color: #fff
}

.btn-outline-warning {
    color: #ffa11a;
    background-color: #fff;
    background-color: transparent
}

.btn-outline-info {
    color: #008697;
    background-color: #fff;
    background-color: transparent
}

.btn-outline-disabled {
    color: #0764ff;
    background-color: #fff
}

.btn-grd-danger, .btn-grd-disabled, .btn-grd-info, .btn-grd-inverse, .btn-grd-primary, .btn-grd-success, .btn-grd-warning, .btn-grd-warning {
    background-size: 200% auto;
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    color: #fff
}

.btn-grd-danger:hover, .btn-grd-disabled:hover, .btn-grd-info:hover, .btn-grd-inverse:hover, .btn-grd-primary:hover, .btn-grd-success:hover, .btn-grd-warning:hover, .btn-grd-warning:hover {
    background-position: right center
}

.btn-grd-danger.hor-grd, .btn-grd-disabled.hor-grd, .btn-grd-info.hor-grd, .btn-grd-inverse.hor-grd, .btn-grd-primary.hor-grd, .btn-grd-success.hor-grd, .btn-grd-warning.hor-grd, .btn-grd-warning.hor-grd {
    background-size: auto 200%
}

.btn-grd-danger.hor-grd:hover, .btn-grd-disabled.hor-grd:hover, .btn-grd-info.hor-grd:hover, .btn-grd-inverse.hor-grd:hover, .btn-grd-primary.hor-grd:hover, .btn-grd-success.hor-grd:hover, .btn-grd-warning.hor-grd:hover, .btn-grd-warning.hor-grd:hover {
    background-position: bottom center
}

.btn-grd-primary {
    background-image: -webkit-gradient(linear, left top, right top, from(#7af), color-stop(51%, #0764ff), to(#7af));
    background-image: linear-gradient(to right, #7af 0%, #0764ff 51%, #7af 100%)
}

.btn-grd-primary.hor-grd {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#7af), color-stop(51%, #0764ff), to(#7af));
    background-image: linear-gradient(to top, #7af 0%, #0764ff 51%, #7af 100%)
}

.btn-grd-warning {
    background-image: -webkit-gradient(linear, left top, right top, from(#ffcf8a), color-stop(51%, #ffa11a), to(#ffcf8a));
    background-image: linear-gradient(to right, #ffcf8a 0%, #ffa11a 51%, #ffcf8a 100%)
}

.btn-grd-warning.hor-grd {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#ffcf8a), color-stop(51%, #ffa11a), to(#ffcf8a));
    background-image: linear-gradient(to top, #ffcf8a 0%, #ffa11a 51%, #ffcf8a 100%)
}

.btn-grd-danger {
    background-image: -webkit-gradient(linear, left top, right top, from(#ff8585), color-stop(51%, #ff1515), to(#ff8585));
    background-image: linear-gradient(to right, #ff8585 0%, #ff1515 51%, #ff8585 100%)
}

.btn-grd-danger.hor-grd {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#ff8585), color-stop(51%, #ff1515), to(#ff8585));
    background-image: linear-gradient(to top, #ff8585 0%, #ff1515 51%, #ff8585 100%)
}

.btn-grd-success {
    background-image: -webkit-gradient(linear, left top, right top, from(#b5d98b), color-stop(51%, #7eb73d), to(#b5d98b));
    background-image: linear-gradient(to right, #b5d98b 0%, #7eb73d 51%, #b5d98b 100%)
}

.btn-grd-success.hor-grd {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#b5d98b), color-stop(51%, #7eb73d), to(#b5d98b));
    background-image: linear-gradient(to top, #b5d98b 0%, #7eb73d 51%, #b5d98b 100%)
}

.btn-grd-inverse {
    background-image: -webkit-gradient(linear, left top, right top, from(#4c626d), color-stop(51%, #1e272b), to(#4c626d));
    background-image: linear-gradient(to right, #4c626d 0%, #1e272b 51%, #4c626d 100%)
}

.btn-grd-inverse.hor-grd {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#4c626d), color-stop(51%, #1e272b), to(#4c626d));
    background-image: linear-gradient(to top, #4c626d 0%, #1e272b 51%, #4c626d 100%)
}

.btn-grd-info {
    background-image: -webkit-gradient(linear, left top, right top, from(#08e3ff), color-stop(51%, #008697), to(#08e3ff));
    background-image: linear-gradient(to right, #08e3ff 0%, #008697 51%, #08e3ff 100%)
}

.btn-grd-info.hor-grd {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#08e3ff), color-stop(51%, #008697), to(#08e3ff));
    background-image: linear-gradient(to top, #08e3ff 0%, #008697 51%, #08e3ff 100%)
}

.btn-grd-disabled {
    background-image: -webkit-gradient(linear, left top, right top, from(#7af), color-stop(51%, #0764ff), to(#7af));
    background-image: linear-gradient(to right, #7af 0%, #0764ff 51%, #7af 100%)
}

.btn-grd-disabled.hor-grd {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#7af), color-stop(51%, #0764ff), to(#7af));
    background-image: linear-gradient(to top, #7af 0%, #0764ff 51%, #7af 100%)
}

.btn-mat {
    position: relative;
    border-radius: 0;
    border: none
}

.btn-mat:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
    height: 3px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.btn-mat:hover {
    border: none
}

.btn-mat:hover:before {
    left: 0;
    right: 0
}

.btn-primary.btn-mat, .sweet-alert button.btn-mat.confirm, .wizard > .actions a.btn-mat {
    background-color: #448aff
}

.btn-primary.btn-mat:before, .sweet-alert button.btn-mat.confirm:before, .wizard > .actions a.btn-mat:before {
    background-color: #0764ff
}

.btn-warning.btn-mat {
    background-color: #ffba57
}

.btn-warning.btn-mat:before {
    background-color: #ffa11a
}

.btn-danger.btn-mat {
    background-color: #ff5252
}

.btn-danger.btn-mat:before {
    background-color: #ff1515
}

.btn-success.btn-mat {
    background-color: #9ccc65
}

.btn-success.btn-mat:before {
    background-color: #7eb73d
}

.btn-inverse.btn-mat {
    background-color: #37474f
}

.btn-inverse.btn-mat:before {
    background-color: #1e272b
}

.btn-info.btn-mat {
    background-color: #00bcd4
}

.btn-info.btn-mat:before {
    background-color: #008697
}

.btn-outline-disabled {
    background-color: #448aff
}

.btn-outline-disabled:before {
    background-color: #0764ff
}

.btn-xlg {
    padding: 14px 20px;
    font-size: 21px;
    line-height: 40px
}

.btn-lg {
    padding: 12px 18px;
    font-size: 18px;
    line-height: 32px
}

.btn-md {
    padding: 10px 16px;
    font-size: 15px;
    line-height: 23px
}

.btn-sm {
    padding: 8px 14px;
    line-height: 16px;
    font-size: 11px
}

.btn-mini {
    padding: 5px 10px;
    line-height: 14px;
    font-size: 10px
}

.btn-primary .badge, .sweet-alert button.confirm .badge, .wizard > .actions a .badge {
    color: #448aff;
    background-color: #fff
}

.btn-success .badge {
    color: #9ccc65;
    background-color: #fff
}

.btn-info .badge {
    color: #00bcd4;
    background-color: #fff
}

.btn-warning .badge {
    color: #ffba57;
    background-color: #fff
}

.btn-danger .badge {
    color: #ff5252;
    background-color: #fff
}

.btn-dribbble, .btn-dropbox, .btn-facebook, .btn-flickr, .btn-github, .btn-google-plus, .btn-instagram, .btn-linkedin, .btn-pinterest, .btn-skype, .btn-tumblr, .btn-twitter, .btn-youtube {
    color: #fff
}

.btn-dribbble:focus, .btn-dribbble:hover, .btn-dropbox:focus, .btn-dropbox:hover, .btn-facebook:focus, .btn-facebook:hover, .btn-flickr:focus, .btn-flickr:hover, .btn-github:focus, .btn-github:hover, .btn-google-plus:focus, .btn-google-plus:hover, .btn-instagram:focus, .btn-instagram:hover, .btn-linkedin:focus, .btn-linkedin:hover, .btn-pinterest:focus, .btn-pinterest:hover, .btn-skype:focus, .btn-skype:hover, .btn-tumblr:focus, .btn-tumblr:hover, .btn-twitter:focus, .btn-twitter:hover, .btn-youtube:focus, .btn-youtube:hover {
    color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-facebook {
    background-color: #3b5998
}

.btn-twitter {
    background-color: #00ACED
}

.btn-linkedin {
    background-color: #007BB6
}

.btn-dribbble {
    background-color: #EA4C89
}

.btn-google-plus {
    background-color: #DD4B39
}

.btn-instagram {
    background-color: #517FA4
}

.btn-pinterest {
    background-color: #CB2027
}

.btn-dropbox {
    background-color: #32506D
}

.btn-tumblr {
    background-color: #00ACED
}

.dropdown-primary .dropdown-menu a:hover, .dropdown-split-primary .dropdown-menu a:hover {
    background-color: #448aff;
    color: #fff
}

.dropdown-split-success .dropdown-menu a:hover, .dropdown-success .dropdown-menu a:hover {
    background-color: #9ccc65;
    color: #fff
}

.dropdown-info .dropdown-menu a:hover, .dropdown-split-info .dropdown-menu a:hover {
    background-color: #00bcd4;
    color: #fff
}

.dropdown-split-warning .dropdown-menu a:hover, .dropdown-warning .dropdown-menu a:hover {
    background-color: #ffba57;
    color: #fff
}

.dropdown-danger .dropdown-menu a:hover, .dropdown-split-danger .dropdown-menu a:hover {
    background-color: #ff5252;
    color: #fff
}

.dropdown-inverse .dropdown-item, .dropdown-split-inverse .dropdown-item {
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in
}

.dropdown-inverse .dropdown-item:hover, .dropdown-split-inverse .dropdown-item:hover {
    background-color: #37474f;
    color: #fff
}

.col-form-label {
    font-size: 14px
}

.select2-container {
    width: 100%
}

.form-group {
    margin-bottom: 1.25em
}

.form-group .form-control-lg {
    font-size: 1.25em
}

.form-group .form-control-round {
    border-radius: 50px
}

.form-group .form-control-static:focus {
    outline: none
}

.form-control {
    font-size: 14px;
    border-radius: 2px;
    border: 1px solid #ccc
}

.form-control:focus {
    border: 1px solid #448aff
}

.form-control.form-control-normal {
    font-weight: 400
}

.form-control.form-control-bold {
    font-weight: 500
}

.form-control.form-control-capitalize {
    text-transform: capitalize
}

.form-control.form-control-uppercase {
    text-transform: uppercase
}

.form-control.form-control-lowercase {
    text-transform: lowercase
}

.form-control.form-control-variant {
    font-variant: small-caps
}

.form-control.form-control-left {
    text-align: left
}

.form-control.form-control-center {
    text-align: center
}

.form-control.form-control-right {
    text-align: right
}

.form-control.form-control-rtl {
    direction: rtl
}

.form-control:disabled {
    cursor: not-allowed;
    opacity: 0.5
}

.form-control-primary {
    border-color: #448aff;
    color: #448aff
}

.form-control-primary:focus {
    border-color: #448aff
}

.input-group-primary .input-group-append .input-group-text, .input-group-primary .input-group-prepend .input-group-text {
    background-color: #448aff
}

.input-group-primary .form-control {
    border-color: #448aff
}

.form-control-warning {
    border-color: #ffba57;
    color: #ffba57
}

.form-control-warning:focus {
    border-color: #ffba57
}

.input-group-warning .input-group-append .input-group-text, .input-group-warning .input-group-prepend .input-group-text {
    background-color: #ffba57
}

.input-group-warning .form-control {
    border-color: #ffba57
}

.form-control-default {
    border-color: #d6d6d6;
    color: #d6d6d6
}

.form-control-default:focus {
    border-color: #d6d6d6
}

.input-group-default .input-group-append .input-group-text, .input-group-default .input-group-prepend .input-group-text {
    background-color: #d6d6d6
}

.input-group-default .form-control {
    border-color: #d6d6d6
}

.form-control-danger {
    border-color: #ff5252;
    color: #ff5252
}

.form-control-danger:focus {
    border-color: #ff5252
}

.input-group-danger .input-group-append .input-group-text, .input-group-danger .input-group-prepend .input-group-text {
    background-color: #ff5252
}

.input-group-danger .form-control {
    border-color: #ff5252
}

.form-control-success {
    border-color: #9ccc65;
    color: #9ccc65
}

.form-control-success:focus {
    border-color: #9ccc65
}

.input-group-success .input-group-append .input-group-text, .input-group-success .input-group-prepend .input-group-text {
    background-color: #9ccc65
}

.input-group-success .form-control {
    border-color: #9ccc65
}

.form-control-inverse {
    border-color: #37474f;
    color: #37474f
}

.form-control-inverse:focus {
    border-color: #37474f
}

.input-group-inverse .input-group-append .input-group-text, .input-group-inverse .input-group-prepend .input-group-text {
    background-color: #37474f
}

.input-group-inverse .form-control {
    border-color: #37474f
}

.form-control-info {
    border-color: #00bcd4;
    color: #00bcd4
}

.form-control-info:focus {
    border-color: #00bcd4
}

.input-group-info .input-group-append .input-group-text, .input-group-info .input-group-prepend .input-group-text {
    background-color: #00bcd4
}

.input-group-info .form-control {
    border-color: #00bcd4
}

.form-txt-primary {
    color: #448aff
}

.form-txt-primary:focus {
    color: #448aff
}

.form-txt-primary::-moz-placeholder {
    color: #448aff;
    opacity: 1
}

.form-txt-primary:-ms-input-placeholder {
    color: #448aff
}

.form-txt-primary::-webkit-input-placeholder {
    color: #448aff
}

.form-txt-warning {
    color: #ffba57
}

.form-txt-warning:focus {
    color: #ffba57
}

.form-txt-warning::-moz-placeholder {
    color: #ffba57;
    opacity: 1
}

.form-txt-warning:-ms-input-placeholder {
    color: #ffba57
}

.form-txt-warning::-webkit-input-placeholder {
    color: #ffba57
}

.form-txt-default {
    color: #d6d6d6
}

.form-txt-default:focus {
    color: #d6d6d6
}

.form-txt-default::-moz-placeholder {
    color: #d6d6d6;
    opacity: 1
}

.form-txt-default:-ms-input-placeholder {
    color: #d6d6d6
}

.form-txt-default::-webkit-input-placeholder {
    color: #d6d6d6
}

.form-txt-danger {
    color: #ff5252
}

.form-txt-danger:focus {
    color: #ff5252
}

.form-txt-danger::-moz-placeholder {
    color: #ff5252;
    opacity: 1
}

.form-txt-danger:-ms-input-placeholder {
    color: #ff5252
}

.form-txt-danger::-webkit-input-placeholder {
    color: #ff5252
}

.form-txt-success {
    color: #9ccc65
}

.form-txt-success:focus {
    color: #9ccc65
}

.form-txt-success::-moz-placeholder {
    color: #9ccc65;
    opacity: 1
}

.form-txt-success:-ms-input-placeholder {
    color: #9ccc65
}

.form-txt-success::-webkit-input-placeholder {
    color: #9ccc65
}

.form-txt-inverse {
    color: #37474f
}

.form-txt-inverse:focus {
    color: #37474f
}

.form-txt-inverse::-moz-placeholder {
    color: #37474f;
    opacity: 1
}

.form-txt-inverse:-ms-input-placeholder {
    color: #37474f
}

.form-txt-inverse::-webkit-input-placeholder {
    color: #37474f
}

.form-txt-info {
    color: #00bcd4
}

.form-txt-info:focus {
    color: #00bcd4
}

.form-txt-info::-moz-placeholder {
    color: #00bcd4;
    opacity: 1
}

.form-txt-info:-ms-input-placeholder {
    color: #00bcd4
}

.form-txt-info::-webkit-input-placeholder {
    color: #00bcd4
}

.form-bg-primary {
    background-color: #448aff;
    border-color: #448aff;
    color: #fff
}

.form-bg-primary:focus {
    background-color: #448aff;
    border-color: #448aff;
    color: #fff
}

.form-bg-primary::-moz-placeholder {
    color: #fff;
    opacity: 1
}

.form-bg-primary:-ms-input-placeholder {
    color: #fff
}

.form-bg-primary::-webkit-input-placeholder {
    color: #fff
}

.form-bg-warning {
    background-color: #ffba57;
    border-color: #ffba57;
    color: #fff
}

.form-bg-warning:focus {
    background-color: #ffba57;
    border-color: #ffba57;
    color: #fff
}

.form-bg-warning::-moz-placeholder {
    color: #fff;
    opacity: 1
}

.form-bg-warning:-ms-input-placeholder {
    color: #fff
}

.form-bg-warning::-webkit-input-placeholder {
    color: #fff
}

.form-bg-default {
    background-color: #d6d6d6;
    border-color: #d6d6d6;
    color: #fff
}

.form-bg-default:focus {
    background-color: #d6d6d6;
    border-color: #d6d6d6;
    color: #fff
}

.form-bg-default::-moz-placeholder {
    color: #fff;
    opacity: 1
}

.form-bg-default:-ms-input-placeholder {
    color: #fff
}

.form-bg-default::-webkit-input-placeholder {
    color: #fff
}

.form-bg-danger {
    background-color: #ff5252;
    border-color: #ff5252;
    color: #fff
}

.form-bg-danger:focus {
    background-color: #ff5252;
    border-color: #ff5252;
    color: #fff
}

.form-bg-danger::-moz-placeholder {
    color: #fff;
    opacity: 1
}

.form-bg-danger:-ms-input-placeholder {
    color: #fff
}

.form-bg-danger::-webkit-input-placeholder {
    color: #fff
}

.form-bg-success {
    background-color: #9ccc65;
    border-color: #9ccc65;
    color: #fff
}

.form-bg-success:focus {
    background-color: #9ccc65;
    border-color: #9ccc65;
    color: #fff
}

.form-bg-success::-moz-placeholder {
    color: #fff;
    opacity: 1
}

.form-bg-success:-ms-input-placeholder {
    color: #fff
}

.form-bg-success::-webkit-input-placeholder {
    color: #fff
}

.form-bg-inverse {
    background-color: #37474f;
    border-color: #37474f;
    color: #fff
}

.form-bg-inverse:focus {
    background-color: #37474f;
    border-color: #37474f;
    color: #fff
}

.form-bg-inverse::-moz-placeholder {
    color: #fff;
    opacity: 1
}

.form-bg-inverse:-ms-input-placeholder {
    color: #fff
}

.form-bg-inverse::-webkit-input-placeholder {
    color: #fff
}

.form-bg-info {
    background-color: #00bcd4;
    border-color: #00bcd4;
    color: #fff
}

.form-bg-info:focus {
    background-color: #00bcd4;
    border-color: #00bcd4;
    color: #fff
}

.form-bg-info::-moz-placeholder {
    color: #fff;
    opacity: 1
}

.form-bg-info:-ms-input-placeholder {
    color: #fff
}

.form-bg-info::-webkit-input-placeholder {
    color: #fff
}

.has-success .col-form-label {
    color: #9ccc65
}

.has-success .form-control-success {
    border-color: #9ccc65
}

.has-warning .col-form-label {
    color: #ffba57
}

.has-warning .form-control-success {
    border-color: #ffba57
}

.has-danger .col-form-label {
    color: #ff5252
}

.has-danger .form-control-success {
    border-color: #ff5252
}

.left-icon-control {
    position: relative
}

.left-icon-control input {
    padding-left: 50px
}

.left-icon-control .form-icon {
    position: absolute;
    top: 50%;
    left: 17px;
    margin-top: -12px
}

.right-icon-control {
    position: relative
}

.right-icon-control input {
    padding-right: 50px
}

.right-icon-control .form-icon {
    position: absolute;
    bottom: 8px;
    right: 8px
}

.right-icon-control .form-icon .btn.btn-icon {
    width: 35px;
    height: 35px;
    padding: 0
}

.right-icon-control .btn > i {
    margin-right: 0
}

.input-group {
    margin-bottom: 1.25em
}

.input-group-append .input-group-text, .input-group-prepend .input-group-text {
    background-color: #448aff;
    border-color: rgba(0, 0, 0, 0.15);
    color: #fff
}

.input-group-button .input-group-addon {
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s
}

.input-group-button .input-group-addon:hover {
    background-color: #7af;
    border-color: #7af
}

.input-group-button .input-group-addon:hover .btn {
    background-color: #7af;
    border-color: #7af
}

.input-group-dropdown [class*=btn-] {
    margin-bottom: 0
}

.js-dynamic-disable, .js-dynamic-enable {
    vertical-align: inherit
}

.form-radio {
    position: relative
}

.form-radio .form-help {
    position: absolute;
    width: 100%
}

.form-radio label {
    position: relative;
    padding-left: 1.5rem;
    text-align: left;
    color: #333;
    display: block;
    line-height: 1.8
}

.form-radio input {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0
}

.radio .helper {
    position: absolute;
    top: -0.15rem;
    left: -0.25rem;
    cursor: pointer;
    display: block;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #999
}

.radio .helper::after {
    -webkit-transform: scale(0);
    transform: scale(0);
    background-color: #448aff;
    border-color: #448aff
}

.radio .helper::after, .radio .helper::before {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    margin: 0.25rem;
    width: 1rem;
    height: 1rem;
    -webkit-transition: -webkit-transform 0.28s ease;
    transition: -webkit-transform 0.28s ease;
    transition: transform 0.28s ease, -webkit-transform 0.28s ease;
    border-radius: 50%;
    border: 0.125rem solid #448aff
}

.radio label:hover .helper {
    color: #448aff
}

.radio input:checked ~ .helper::after {
    -webkit-transform: scale(0.5);
    transform: scale(0.5)
}

.radio input:checked ~ .helper::before {
    color: #448aff;
    -webkit-animation: ripple 0.3s linear forwards;
    animation: ripple 0.3s linear forwards
}

.radio.radiofill input:checked ~ .helper::after {
    -webkit-transform: scale(1);
    transform: scale(1)
}

.radio.radiofill .helper::after {
    background-color: #448aff
}

.radio.radio-outline input:checked ~ .helper::after {
    -webkit-transform: scale(0.6);
    transform: scale(0.6)
}

.radio.radio-outline .helper::after {
    background-color: #fff;
    border: 0.225rem solid #448aff
}

.radio.radio-matrial input ~ .helper::after {
    background-color: #fff
}

.radio.radio-matrial input:checked ~ .helper::after {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-box-shadow: 0 1px 7px -1px rgba(0, 0, 0, 0.72);
    box-shadow: 0 1px 7px -1px rgba(0, 0, 0, 0.72)
}

.radio.radio-matrial input:checked ~ .helper::before {
    background-color: #448aff
}

.radio.radio-disable {
    opacity: 0.7
}

.radio.radio-disable label {
    cursor: not-allowed
}

.radio-inline {
    display: inline-block;
    margin-right: 20px
}

.radio.radio-primary .helper::after {
    background-color: #448aff;
    border-color: #448aff
}

.radio.radio-primary .helper::before {
    border-color: #448aff
}

.radio.radio-outline.radio-primary .helper::after {
    background-color: #fff;
    border: 0.225rem solid #448aff
}

.radio.radio-outline.radio-primary .helper::before {
    border-color: #448aff
}

.radio.radio-matrial.radio-primary input ~ .helper::after {
    background-color: #fff;
    border-color: #fff
}

.radio.radio-matrial.radio-primary input ~ .helper::before {
    background-color: #448aff;
    border-color: #448aff
}

.radio.radio-warning .helper::after {
    background-color: #ffba57;
    border-color: #ffba57
}

.radio.radio-warning .helper::before {
    border-color: #ffba57
}

.radio.radio-outline.radio-warning .helper::after {
    background-color: #fff;
    border: 0.225rem solid #ffba57
}

.radio.radio-outline.radio-warning .helper::before {
    border-color: #ffba57
}

.radio.radio-matrial.radio-warning input ~ .helper::after {
    background-color: #fff;
    border-color: #fff
}

.radio.radio-matrial.radio-warning input ~ .helper::before {
    background-color: #ffba57;
    border-color: #ffba57
}

.radio.radio-default .helper::after {
    background-color: #d6d6d6;
    border-color: #d6d6d6
}

.radio.radio-default .helper::before {
    border-color: #d6d6d6
}

.radio.radio-outline.radio-default .helper::after {
    background-color: #fff;
    border: 0.225rem solid #d6d6d6
}

.radio.radio-outline.radio-default .helper::before {
    border-color: #d6d6d6
}

.radio.radio-matrial.radio-default input ~ .helper::after {
    background-color: #fff;
    border-color: #fff
}

.radio.radio-matrial.radio-default input ~ .helper::before {
    background-color: #d6d6d6;
    border-color: #d6d6d6
}

.radio.radio-danger .helper::after {
    background-color: #ff5252;
    border-color: #ff5252
}

.radio.radio-danger .helper::before {
    border-color: #ff5252
}

.radio.radio-outline.radio-danger .helper::after {
    background-color: #fff;
    border: 0.225rem solid #ff5252
}

.radio.radio-outline.radio-danger .helper::before {
    border-color: #ff5252
}

.radio.radio-matrial.radio-danger input ~ .helper::after {
    background-color: #fff;
    border-color: #fff
}

.radio.radio-matrial.radio-danger input ~ .helper::before {
    background-color: #ff5252;
    border-color: #ff5252
}

.radio.radio-success .helper::after {
    background-color: #9ccc65;
    border-color: #9ccc65
}

.radio.radio-success .helper::before {
    border-color: #9ccc65
}

.radio.radio-outline.radio-success .helper::after {
    background-color: #fff;
    border: 0.225rem solid #9ccc65
}

.radio.radio-outline.radio-success .helper::before {
    border-color: #9ccc65
}

.radio.radio-matrial.radio-success input ~ .helper::after {
    background-color: #fff;
    border-color: #fff
}

.radio.radio-matrial.radio-success input ~ .helper::before {
    background-color: #9ccc65;
    border-color: #9ccc65
}

.radio.radio-inverse .helper::after {
    background-color: #37474f;
    border-color: #37474f
}

.radio.radio-inverse .helper::before {
    border-color: #37474f
}

.radio.radio-outline.radio-inverse .helper::after {
    background-color: #fff;
    border: 0.225rem solid #37474f
}

.radio.radio-outline.radio-inverse .helper::before {
    border-color: #37474f
}

.radio.radio-matrial.radio-inverse input ~ .helper::after {
    background-color: #fff;
    border-color: #fff
}

.radio.radio-matrial.radio-inverse input ~ .helper::before {
    background-color: #37474f;
    border-color: #37474f
}

.radio.radio-info .helper::after {
    background-color: #00bcd4;
    border-color: #00bcd4
}

.radio.radio-info .helper::before {
    border-color: #00bcd4
}

.radio.radio-outline.radio-info .helper::after {
    background-color: #fff;
    border: 0.225rem solid #00bcd4
}

.radio.radio-outline.radio-info .helper::before {
    border-color: #00bcd4
}

.radio.radio-matrial.radio-info input ~ .helper::after {
    background-color: #fff;
    border-color: #fff
}

.radio.radio-matrial.radio-info input ~ .helper::before {
    background-color: #00bcd4;
    border-color: #00bcd4
}

@-webkit-keyframes ripple {
    0% {
        -webkit-box-shadow: 0 0 0 1px transparent;
        box-shadow: 0 0 0 1px transparent
    }
    50% {
        -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.05)
    }
    100% {
        -webkit-box-shadow: 0 0 0 10px transparent;
        box-shadow: 0 0 0 10px transparent
    }
}

@keyframes ripple {
    0% {
        -webkit-box-shadow: 0 0 0 1px transparent;
        box-shadow: 0 0 0 1px transparent
    }
    50% {
        -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.05)
    }
    100% {
        -webkit-box-shadow: 0 0 0 10px transparent;
        box-shadow: 0 0 0 10px transparent
    }
}

.border-checkbox-section .border-checkbox-group {
    display: inline-block
}

.border-checkbox-section .border-checkbox-group .border-checkbox:checked + .border-checkbox-label:before {
    -webkit-animation: ripple 0.3s linear forwards;
    animation: ripple 0.3s linear forwards
}

.border-checkbox-section .border-checkbox-group .border-checkbox:checked + .border-checkbox-label:after {
    -webkit-animation: check linear 0.5s;
    animation: check linear 0.5s;
    opacity: 1;
    border-color: #448aff
}

.border-checkbox-section .border-checkbox-group .border-checkbox:checked + .border-checkbox-label:after .border-checkbox-label:before {
    border-color: #eee
}

.border-checkbox-section .border-checkbox-group .border-checkbox-label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    height: 20px;
    line-height: 20px;
    padding-left: 30px;
    margin-right: 15px
}

.border-checkbox-section .border-checkbox-group .border-checkbox-label:after {
    content: "";
    display: block;
    width: 6px;
    height: 12px;
    opacity: 0.9;
    border-right: 2px solid #eee;
    border-top: 2px solid #eee;
    position: absolute;
    left: 4px;
    top: 11px;
    -webkit-transform: scaleX(-1) rotate(135deg);
    transform: scaleX(-1) rotate(135deg);
    -webkit-transform-origin: left top;
    transform-origin: left top
}

.border-checkbox-section .border-checkbox-group .border-checkbox-label:before {
    content: "";
    display: block;
    border: 2px solid #448aff;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0
}

.border-checkbox-section .border-checkbox {
    display: none
}

.border-checkbox-section .border-checkbox:disabled ~ .border-checkbox-label {
    cursor: no-drop;
    color: #ccc
}

@-webkit-keyframes check {
    0% {
        height: 0;
        width: 0
    }
    25% {
        height: 0;
        width: 6px
    }
    50% {
        height: 12px;
        width: 6px
    }
}

@keyframes check {
    0% {
        height: 0;
        width: 0
    }
    25% {
        height: 0;
        width: 6px
    }
    50% {
        height: 12px;
        width: 6px
    }
}

.border-checkbox-section .border-checkbox-group-primary .border-checkbox-label:before {
    border: 2px solid #448aff
}

.border-checkbox-section .border-checkbox-group-primary .border-checkbox:checked + .border-checkbox-label:after {
    border-color: #448aff
}

.border-checkbox-section .border-checkbox-group-warning .border-checkbox-label:before {
    border: 2px solid #ffba57
}

.border-checkbox-section .border-checkbox-group-warning .border-checkbox:checked + .border-checkbox-label:after {
    border-color: #ffba57
}

.border-checkbox-section .border-checkbox-group-default .border-checkbox-label:before {
    border: 2px solid #d6d6d6
}

.border-checkbox-section .border-checkbox-group-default .border-checkbox:checked + .border-checkbox-label:after {
    border-color: #d6d6d6
}

.border-checkbox-section .border-checkbox-group-danger .border-checkbox-label:before {
    border: 2px solid #ff5252
}

.border-checkbox-section .border-checkbox-group-danger .border-checkbox:checked + .border-checkbox-label:after {
    border-color: #ff5252
}

.border-checkbox-section .border-checkbox-group-success .border-checkbox-label:before {
    border: 2px solid #9ccc65
}

.border-checkbox-section .border-checkbox-group-success .border-checkbox:checked + .border-checkbox-label:after {
    border-color: #9ccc65
}

.border-checkbox-section .border-checkbox-group-inverse .border-checkbox-label:before {
    border: 2px solid #37474f
}

.border-checkbox-section .border-checkbox-group-inverse .border-checkbox:checked + .border-checkbox-label:after {
    border-color: #37474f
}

.border-checkbox-section .border-checkbox-group-info .border-checkbox-label:before {
    border: 2px solid #00bcd4
}

.border-checkbox-section .border-checkbox-group-info .border-checkbox:checked + .border-checkbox-label:after {
    border-color: #00bcd4
}

.checkbox-fade, .checkbox-zoom {
    display: inline-block;
    margin-right: 15px
}

.checkbox-fade label, .checkbox-zoom label {
    line-height: 20px
}

.checkbox-fade label input[type="checkbox"], .checkbox-fade label input[type="radio"], .checkbox-zoom label input[type="checkbox"], .checkbox-zoom label input[type="radio"] {
    display: none
}

.checkbox-fade label input[type="checkbox"]:checked + .cr > .cr-icon, .checkbox-fade label input[type="radio"]:checked + .cr > .cr-icon, .checkbox-zoom label input[type="checkbox"]:checked + .cr > .cr-icon, .checkbox-zoom label input[type="radio"]:checked + .cr > .cr-icon {
    -webkit-transform: scale(1) rotateZ(0deg);
    transform: scale(1) rotateZ(0deg);
    opacity: 1
}

.checkbox-fade label input[type="checkbox"] + .cr > .cr-icon, .checkbox-fade label input[type="radio"] + .cr > .cr-icon, .checkbox-zoom label input[type="checkbox"] + .cr > .cr-icon, .checkbox-zoom label input[type="radio"] + .cr > .cr-icon {
    -webkit-transform: scale(3) rotateZ(-20deg);
    transform: scale(3) rotateZ(-20deg);
    opacity: 0;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in
}

.checkbox-fade label:after, .checkbox-zoom label:after {
    content: '';
    display: table;
    clear: both
}

.checkbox-fade.fade-in-disable .cr, .checkbox-fade.fade-in-disable label, .checkbox-zoom.fade-in-disable .cr, .checkbox-zoom.fade-in-disable label {
    color: #ccc;
    cursor: no-drop
}

.checkbox-fade .cr, .checkbox-zoom .cr {
    border-radius: 0;
    border: 2px solid #448aff;
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 20px;
    margin-right: 1rem;
    position: relative;
    width: 20px
}

.checkbox-fade .cr .cr-icon, .checkbox-zoom .cr .cr-icon {
    color: #448aff;
    font-size: 0.8em;
    left: 0;
    line-height: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%
}

.checkbox-fade input[type="checkbox"]:checked + .cr, .checkbox-zoom input[type="checkbox"]:checked + .cr {
    -webkit-animation: ripple 0.3s linear forwards;
    animation: ripple 0.3s linear forwards
}

.checkbox-fade label {
    line-height: 20px
}

.checkbox-fade label input[type="checkbox"], .checkbox-fade label input[type="radio"] {
    display: none
}

.checkbox-fade label input[type="checkbox"]:checked + .cr > .cr-icon, .checkbox-fade label input[type="radio"]:checked + .cr > .cr-icon {
    -webkit-transform: scale(1) rotateZ(0deg);
    transform: scale(1) rotateZ(0deg);
    opacity: 1
}

.checkbox-fade label input[type="checkbox"] + .cr > .cr-icon, .checkbox-fade label input[type="radio"] + .cr > .cr-icon {
    -webkit-transform: scale(3) rotateZ(-20deg);
    transform: scale(3) rotateZ(-20deg);
    opacity: 0;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in
}

.checkbox-zoom label {
    line-height: 20px
}

.checkbox-zoom label input[type="checkbox"], .checkbox-zoom label input[type="radio"] {
    display: none
}

.checkbox-zoom label input[type="checkbox"]:checked + .cr > .cr-icon, .checkbox-zoom label input[type="radio"]:checked + .cr > .cr-icon {
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    opacity: 1
}

.checkbox-zoom label input[type="checkbox"] + .cr > .cr-icon, .checkbox-zoom label input[type="radio"] + .cr > .cr-icon {
    -webkit-transform: scale3d(0.2, 0.2, 0.1) translate3d(0, 0, 0);
    transform: scale3d(0.2, 0.2, 0.1) translate3d(0, 0, 0);
    opacity: 0;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in
}

.checkbox-fade.fade-in-primary .cr, .checkbox-fade.zoom-primary .cr, .checkbox-zoom.fade-in-primary .cr, .checkbox-zoom.zoom-primary .cr {
    border: 2px solid #448aff
}

.checkbox-fade.fade-in-primary .cr .cr-icon, .checkbox-fade.zoom-primary .cr .cr-icon, .checkbox-zoom.fade-in-primary .cr .cr-icon, .checkbox-zoom.zoom-primary .cr .cr-icon {
    color: #448aff
}

.checkbox-fade.fade-in-warning .cr, .checkbox-fade.zoom-warning .cr, .checkbox-zoom.fade-in-warning .cr, .checkbox-zoom.zoom-warning .cr {
    border: 2px solid #ffba57
}

.checkbox-fade.fade-in-warning .cr .cr-icon, .checkbox-fade.zoom-warning .cr .cr-icon, .checkbox-zoom.fade-in-warning .cr .cr-icon, .checkbox-zoom.zoom-warning .cr .cr-icon {
    color: #ffba57
}

.checkbox-fade.fade-in-default .cr, .checkbox-fade.zoom-default .cr, .checkbox-zoom.fade-in-default .cr, .checkbox-zoom.zoom-default .cr {
    border: 2px solid #d6d6d6
}

.checkbox-fade.fade-in-default .cr .cr-icon, .checkbox-fade.zoom-default .cr .cr-icon, .checkbox-zoom.fade-in-default .cr .cr-icon, .checkbox-zoom.zoom-default .cr .cr-icon {
    color: #d6d6d6
}

.checkbox-fade.fade-in-danger .cr, .checkbox-fade.zoom-danger .cr, .checkbox-zoom.fade-in-danger .cr, .checkbox-zoom.zoom-danger .cr {
    border: 2px solid #ff5252
}

.checkbox-fade.fade-in-danger .cr .cr-icon, .checkbox-fade.zoom-danger .cr .cr-icon, .checkbox-zoom.fade-in-danger .cr .cr-icon, .checkbox-zoom.zoom-danger .cr .cr-icon {
    color: #ff5252
}

.checkbox-fade.fade-in-success .cr, .checkbox-fade.zoom-success .cr, .checkbox-zoom.fade-in-success .cr, .checkbox-zoom.zoom-success .cr {
    border: 2px solid #9ccc65
}

.checkbox-fade.fade-in-success .cr .cr-icon, .checkbox-fade.zoom-success .cr .cr-icon, .checkbox-zoom.fade-in-success .cr .cr-icon, .checkbox-zoom.zoom-success .cr .cr-icon {
    color: #9ccc65
}

.checkbox-fade.fade-in-inverse .cr, .checkbox-fade.zoom-inverse .cr, .checkbox-zoom.fade-in-inverse .cr, .checkbox-zoom.zoom-inverse .cr {
    border: 2px solid #37474f
}

.checkbox-fade.fade-in-inverse .cr .cr-icon, .checkbox-fade.zoom-inverse .cr .cr-icon, .checkbox-zoom.fade-in-inverse .cr .cr-icon, .checkbox-zoom.zoom-inverse .cr .cr-icon {
    color: #37474f
}

.checkbox-fade.fade-in-info .cr, .checkbox-fade.zoom-info .cr, .checkbox-zoom.fade-in-info .cr, .checkbox-zoom.zoom-info .cr {
    border: 2px solid #00bcd4
}

.checkbox-fade.fade-in-info .cr .cr-icon, .checkbox-fade.zoom-info .cr .cr-icon, .checkbox-zoom.fade-in-info .cr .cr-icon, .checkbox-zoom.zoom-info .cr .cr-icon {
    color: #00bcd4
}

.checkbox-color {
    display: inline-block;
    margin-right: 20px;
    cursor: pointer
}

.checkbox-color label {
    display: inline-block;
    position: relative;
    padding-left: 10px;
    line-height: 20px
}

.checkbox-color label::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 20px;
    height: 20px;
    left: 0;
    right: 0;
    text-align: center;
    margin-left: -20px;
    border: 1px solid #ccc;
    border-radius: 0;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out
}

.checkbox-color label::after {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: -1px;
    top: 0;
    margin-left: -17px;
    padding-left: 3px;
    padding-top: 1px;
    font-size: 11px;
    color: #fff
}

.checkbox-color input[type="checkbox"] {
    opacity: 0
}

.checkbox-color input[type="checkbox"]:focus + label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

.checkbox-color input[type="checkbox"]:checked + label::after {
    font-family: 'FontAwesome';
    content: "\f00c"
}

.checkbox-color input[type="checkbox"]:checked + label::before {
    -webkit-animation: ripple 0.3s linear forwards;
    animation: ripple 0.3s linear forwards
}

.checkbox-color input[type="checkbox"]:disabled + label {
    opacity: 0.65
}

.checkbox-color input[type="checkbox"]:disabled + label::before {
    background-color: #eee;
    cursor: not-allowed
}

.checkbox-color.checkbox-circle label::before {
    border-radius: 50%
}

.checkbox-color.checkbox-inline {
    margin-top: 0
}

.checkbox-danger input[type="checkbox"]:checked + label::after, .checkbox-info input[type="checkbox"]:checked + label::after, .checkbox-primary input[type="checkbox"]:checked + label::after, .checkbox-success input[type="checkbox"]:checked + label::after, .checkbox-warning input[type="checkbox"]:checked + label::after {
    color: #fff
}

.checkbox-primary input[type="checkbox"]:checked + label::before {
    background-color: #2196f3;
    border-color: #2196f3
}

.checkbox-primary input[type="checkbox"]:checked + label::before {
    background-color: #448aff
}

.checkbox-warning input[type="checkbox"]:checked + label::before {
    background-color: #ffba57
}

.checkbox-default input[type="checkbox"]:checked + label::before {
    background-color: #d6d6d6
}

.checkbox-danger input[type="checkbox"]:checked + label::before {
    background-color: #ff5252
}

.checkbox-success input[type="checkbox"]:checked + label::before {
    background-color: #9ccc65
}

.checkbox-inverse input[type="checkbox"]:checked + label::before {
    background-color: #37474f
}

.checkbox-info input[type="checkbox"]:checked + label::before {
    background-color: #00bcd4
}

.bootstrap-tagsinput {
    border: 1px solid #448aff;
    line-height: 30px;
    border-radius: 2px
}

.bootstrap-tagsinput .tag {
    padding: 6px;
    border-radius: 2px
}

.select2-container--default:focus {
    border-color: #448aff
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #fff transparent
}

.select2-container--default .select2-search__field:focus {
    border: 1px solid #448aff
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #448aff
}

.select2-container--default .select2-selection--multiple {
    padding: 3px
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #448aff;
    border: 1px solid #448aff;
    padding: 5px 15px;
    color: #fff
}

.select2-container--default .select2-selection--multiple .select2-selection__choice span {
    color: #fff
}

.select2-container--default .select2-selection--multiple .select2-search__field {
    border: none
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: 1px solid #448aff
}

.select2-container--default .select2-selection--single {
    color: #fff;
    height: auto
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    background-color: #448aff;
    color: #fff;
    padding: 8px 30px 8px 20px
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 10px;
    right: 15px
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #fff transparent transparent transparent
}

.ms-container .ms-list.ms-focus {
    border-color: #448aff;
    -webkit-box-shadow: inset 0 1px 1px rgba(26, 188, 156, 0.49), 0 0 8px rgba(26, 188, 156, 0.5);
    box-shadow: inset 0 1px 1px rgba(26, 188, 156, 0.49), 0 0 8px rgba(26, 188, 156, 0.5)
}

.ms-container .ms-selectable li.ms-hover, .ms-container .ms-selection li.ms-hover {
    background-color: #448aff
}

.ms-selectable .custom-header, .ms-selection .custom-header {
    background-color: #448aff;
    color: #fff;
    text-align: center
}

.form-group .messages p {
    margin-bottom: 0;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s
}

.form-group .popover-valid {
    position: absolute;
    right: 40px;
    top: 7px
}

.wizard > .steps .current a {
    background: #448aff;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s;
    cursor: pointer;
    border-radius: 2px;
    outline: 1px solid #fff;
    outline-offset: -7px
}

.wizard > .steps .current a:hover {
    background: #5e9aff;
    outline-offset: 0
}

#design-wizard .steps li {
    position: relative;
    z-index: 99;
    margin-bottom: 20px
}

#design-wizard .steps li:after {
    content: "";
    position: absolute;
    height: 2px;
    background: #448aff;
    width: 100%;
    top: 30px;
    z-index: -1
}

#design-wizard .steps li a {
    width: 20%;
    margin: 0 auto;
    text-align: center;
    border-radius: 2px
}

.wizard > .steps .done a {
    background: #d6d6d6
}

.card .card-block .wizard-form .steps ul {
    display: block
}

.card .card-block .wizard-form li {
    margin-right: 0
}

.wizard > .content {
    border: 1px solid #ccc;
    border-radius: 2px;
    background: #fff
}

.form-material .form-group {
    position: relative
}

.form-material .form-group.form-static-label .form-control ~ .float-label {
    top: -14px;
    font-size: 11px
}

.form-material .form-control {
    display: inline-block;
    height: 43px;
    width: 100%;
    border: none;
    border-radius: 0;
    font-size: 16px;
    font-weight: 400;
    padding: 0;
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid #ccc
}

.form-material .form-control:focus {
    border-color: transparent;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.form-material .form-control:focus ~ .form-bar:after, .form-material .form-control:focus ~ .form-bar:before {
    width: 50%
}

.form-material textarea {
    height: 100px
}

.form-material .form-bar {
    position: relative;
    display: block;
    width: 100%
}

.form-material .form-bar:after, .form-material .form-bar:before {
    content: '';
    height: 2px;
    width: 0;
    bottom: 0;
    position: absolute;
    transition: 0.3s ease all;
    -moz-transition: 0.3s ease all;
    -webkit-transition: 0.3s ease all
}

.form-material .form-bar:before {
    left: 50%
}

.form-material .form-bar:after {
    right: 50%
}

.form-material .float-label {
    pointer-events: none;
    position: absolute;
    top: 12px;
    left: 0;
    font-size: 14px;
    font-weight: 400;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all
}

.form-material .material-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1.25em
}

.form-material .material-group .material-addone {
    padding: 0.5rem 0.75rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.form-material .material-group .material-addone i, .form-material .material-group .material-addone span {
    font-size: 20px
}

.form-material .material-group .form-group {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0
}

.form-material .material-group.material-group-lg .material-addone {
    padding: 0.5rem 1rem
}

.form-material .material-group.material-group-lg .material-addone i, .form-material .material-group.material-group-lg .material-addone span {
    font-size: 1.55rem;
    font-weight: 500
}

.form-material .material-group.material-group-lg .form-control {
    padding: 0.5rem 0;
    font-size: 1.25rem;
    line-height: 1.5
}

.form-material .material-group.material-group-lg .form-control:focus ~ .float-label, .form-material .material-group.material-group-lg .form-control:valid ~ .float-label {
    top: -15px;
    font-size: 14px
}

.form-material .material-group.material-group-lg .float-label {
    font-size: 1.25rem
}

.form-material .material-group.material-group-sm .material-addone {
    padding: 0.25rem 0.5rem
}

.form-material .material-group.material-group-sm .material-addone i, .form-material .material-group.material-group-sm .material-addone span {
    font-size: 0.775rem
}

.form-material .material-group.material-group-sm .form-control {
    padding: 0.25rem 0;
    font-size: 0.875rem;
    line-height: 1.5
}

.form-material .material-group.material-group-sm .form-control:focus ~ .float-label, .form-material .material-group.material-group-sm .form-control:valid ~ .float-label {
    top: -9px;
    font-size: 11px
}

.form-material .material-group.material-group-sm .float-label {
    font-size: 0.775rem
}

.form-material .form-control:focus ~ .float-label, .form-material .form-material .form-control:valid ~ .float-label {
    top: 12px;
    font-size: 14px
}

.form-material .form-control.fill ~ .float-label, .form-material .form-material .form-control.fill ~ .float-label {
    top: -14px;
    font-size: 11px
}

.form-material .form-primary .form-control:focus ~ label {
    color: #448aff
}

.form-material .form-primary .form-control:focus ~ .form-bar:after, .form-material .form-primary .form-control:focus ~ .form-bar:before {
    background: #448aff
}

.form-material .form-primary.form-static-label .float-label {
    color: #448aff
}

.form-material .form-primary.form-static-label .form-control {
    border-bottom-color: #448aff
}

.form-material .material-group-primary .material-addone i, .form-material .material-group-primary .material-addone span {
    color: #448aff
}

.form-material .form-warning .form-control:focus ~ label {
    color: #ffba57
}

.form-material .form-warning .form-control:focus ~ .form-bar:after, .form-material .form-warning .form-control:focus ~ .form-bar:before {
    background: #ffba57
}

.form-material .form-warning.form-static-label .float-label {
    color: #ffba57
}

.form-material .form-warning.form-static-label .form-control {
    border-bottom-color: #ffba57
}

.form-material .material-group-warning .material-addone i, .form-material .material-group-warning .material-addone span {
    color: #ffba57
}

.form-material .form-default .form-control:focus ~ label {
    color: #d6d6d6
}

.form-material .form-default .form-control:focus ~ .form-bar:after, .form-material .form-default .form-control:focus ~ .form-bar:before {
    background: #d6d6d6
}

.form-material .form-default.form-static-label .float-label {
    color: #d6d6d6
}

.form-material .form-default.form-static-label .form-control {
    border-bottom-color: #d6d6d6
}

.form-material .material-group-default .material-addone i, .form-material .material-group-default .material-addone span {
    color: #d6d6d6
}

.form-material .form-danger .form-control:focus ~ label {
    color: #ff5252
}

.form-material .form-danger .form-control:focus ~ .form-bar:after, .form-material .form-danger .form-control:focus ~ .form-bar:before {
    background: #ff5252
}

.form-material .form-danger.form-static-label .float-label {
    color: #ff5252
}

.form-material .form-danger.form-static-label .form-control {
    border-bottom-color: #ff5252
}

.form-material .material-group-danger .material-addone i, .form-material .material-group-danger .material-addone span {
    color: #ff5252
}

.form-material .form-success .form-control:focus ~ label {
    color: #9ccc65
}

.form-material .form-success .form-control:focus ~ .form-bar:after, .form-material .form-success .form-control:focus ~ .form-bar:before {
    background: #9ccc65
}

.form-material .form-success.form-static-label .float-label {
    color: #9ccc65
}

.form-material .form-success.form-static-label .form-control {
    border-bottom-color: #9ccc65
}

.form-material .material-group-success .material-addone i, .form-material .material-group-success .material-addone span {
    color: #9ccc65
}

.form-material .form-inverse .form-control:focus ~ label {
    color: #37474f
}

.form-material .form-inverse .form-control:focus ~ .form-bar:after, .form-material .form-inverse .form-control:focus ~ .form-bar:before {
    background: #37474f
}

.form-material .form-inverse.form-static-label .float-label {
    color: #37474f
}

.form-material .form-inverse.form-static-label .form-control {
    border-bottom-color: #37474f
}

.form-material .material-group-inverse .material-addone i, .form-material .material-group-inverse .material-addone span {
    color: #37474f
}

.form-material .form-info .form-control:focus ~ label {
    color: #00bcd4
}

.form-material .form-info .form-control:focus ~ .form-bar:after, .form-material .form-info .form-control:focus ~ .form-bar:before {
    background: #00bcd4
}

.form-material .form-info.form-static-label .float-label {
    color: #00bcd4
}

.form-material .form-info.form-static-label .form-control {
    border-bottom-color: #00bcd4
}

.form-material .material-group-info .material-addone i, .form-material .material-group-info .material-addone span {
    color: #00bcd4
}

select.form-control, select.form-control:focus, select.form-control:hover {
    border-top: none;
    border-right: none;
    border-left: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.md-input-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0)
}

.md-input-file {
    overflow: hidden;
    padding-left: 10px
}

.md-add-on-file {
    float: left;
    height: 3rem;
    line-height: 3rem
}

.md-add-on-file .btn {
    padding: 7px 20px
}

.md-form-file ~ .md-form-control {
    opacity: 0;
    position: absolute;
    top: 0
}

.md-input-wrapper .md-check {
    padding-left: 0;
    top: 0
}

.label {
    border-radius: 4px;
    font-size: 75%;
    padding: 4px 7px;
    margin-right: 5px;
    font-weight: 400;
    color: #fff
}

.label-main {
    display: inline-block;
    vertical-align: middle;
    margin: 8px 5px
}

.badge {
    border-radius: 10px;
    padding: 3px 7px
}

.badge-top-left {
    margin-right: -10px;
    right: 100%;
    top: -3px
}

.badge-top-right {
    margin-left: -10px;
    top: -3px
}

.badge-bottom-left {
    margin-left: -33px;
    bottom: -12px;
    right: 97%
}

.badge-bottom-right {
    margin-left: -10px;
    bottom: -12px
}

.label.label-lg {
    padding: 8px 21px
}

.label.label-md {
    padding: 6px 14px
}

.badge-lg {
    padding: 5px 9px;
    font-size: 14px
}

.badge-md {
    padding: 4px 8px;
    font-size: 14px
}

span.badge {
    display: inline-block
}

.label-default {
    background-color: #d6d6d6;
    border-color: #d6d6d6;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff
}

.label-inverse-default {
    border: 1px solid;
    border-color: #d6d6d6;
    color: #d6d6d6
}

.label-inverse-primary {
    border: 1px solid;
    border-color: #448aff;
    color: #448aff
}

.label-inverse-success {
    border: 1px solid;
    border-color: #9ccc65;
    color: #9ccc65
}

.label-inverse-warning {
    border: 1px solid;
    border-color: #ffba57;
    color: #ffba57
}

.label-inverse-danger {
    border: 1px solid;
    border-color: #ff5252;
    color: #ff5252
}

.label-inverse-info {
    border: 1px solid;
    border-color: #00bcd4;
    color: #00bcd4
}

.label-inverse-info-border {
    border: 1px solid;
    border-color: #37474f;
    color: #37474f
}

.badge-inverse-default {
    border: 1px solid;
    border-color: #d6d6d6;
    color: #d6d6d6
}

.badge-inverse-primary {
    border: 1px solid;
    border-color: #448aff;
    color: #448aff
}

.badge-inverse-success {
    border: 1px solid;
    border-color: #9ccc65;
    color: #9ccc65
}

.badge-inverse-warning {
    border: 1px solid;
    border-color: #ffba57;
    color: #ffba57
}

.badge-inverse-danger {
    border: 1px solid;
    border-color: #ff5252;
    color: #ff5252
}

.badge-inverse-info {
    border: 1px solid;
    border-color: #37474f;
    color: #37474f
}

.label-icon {
    position: relative
}

.label-icon label {
    position: absolute
}

.icofont.icofont-envelope {
    font-size: 20px
}

table.table-bordered.dataTable tbody td:focus, table.table-bordered.dataTable tbody th:focus {
    outline: none
}

.card .card-block ul.pagination li {
    margin-right: 0
}

.page-item.active .page-link {
    background-color: #448aff;
    border-color: #448aff
}

.page-link {
    color: #222
}

.table-hover tbody tr:hover {
    background-color: rgba(68, 138, 255, 0.1)
}

td.highlight {
    font-weight: 500;
    color: #00bcd4;
    background-color: #f5f5f5
}

.table.compact td, .table.compact th {
    padding: 0.45rem
}

.dataTables_paginate .pagination {
    float: right
}

#multi-table_wrapper .dataTables_paginate .pagination {
    float: none
}

#dt-live-dom .form-control, #footer-search tfoot .form-control, #footer-select tfoot .form-control, #form-input-table .form-control, .search-api .form-control {
    width: 90%
}

.search-api .column_filter, .search-api .global_filter {
    margin: 0 auto
}

.search-api .checkbox-fade {
    display: block;
    text-align: center
}

td.details-control {
    background: url("../images/details_open.png") no-repeat center center;
    cursor: pointer
}

tr.shown td.details-control {
    background: url("../images/details_close.png") no-repeat center center
}

#row-delete .selected, #row-select .selected {
    background-color: #448aff;
    color: #fff
}

div.dt-autofill-list div.dt-autofill-button button {
    background-color: #448aff;
    border-color: #448aff
}

table.dataTable {
    border-collapse: collapse
}

table.dataTable tbody > tr > .selected, table.dataTable tbody > tr.selected {
    background-color: #448aff;
    color: #fff
}

table.dataTable td.select-checkbox:before, table.dataTable th.select-checkbox:before {
    margin-top: 0
}

table.dataTable tr.selected td.select-checkbox:after, table.dataTable tr.selected th.select-checkbox:after {
    margin-top: -6px;
    margin-left: -5px
}

a.dt-button, a.dt-button.active:not(.disabled), a.dt-button:active:not(.disabled), a.dt-button:focus:not(.disabled), button.dt-button, button.dt-button.active:not(.disabled), button.dt-button:active:not(.disabled), button.dt-button:focus:not(.disabled), div.dt-button, div.dt-button.active:not(.disabled), div.dt-button:active:not(.disabled), div.dt-button:focus:not(.disabled) {
    background-color: #448aff;
    border-color: #448aff;
    border-radius: 2px;
    color: #fff;
    background-image: none;
    font-size: 14px
}

a.dt-button.btn-warning, button.dt-button.btn-warning, div.dt-button.btn-warning {
    background-color: #ffba57;
    border-color: #ffba57;
    border-radius: 2px;
    color: #fff;
    background-image: none
}

a.dt-button.btn-danger, button.dt-button.btn-danger, div.dt-button.btn-danger {
    background-color: #ff5252;
    border-color: #ff5252;
    border-radius: 2px;
    color: #fff;
    background-image: none
}

a.dt-button.btn-inverse, button.dt-button.btn-inverse, div.dt-button.btn-inverse {
    background-color: #37474f;
    border-color: #37474f;
    border-radius: 2px;
    color: #fff;
    background-image: none
}

a.dt-button:hover:not(.disabled), button.dt-button:hover:not(.disabled), div.dt-button:hover:not(.disabled) {
    background-image: none;
    background-color: #5e9aff;
    border-color: #448aff
}

a.dt-button.btn-warning:hover:not(.disabled), button.dt-button.btn-warning:hover:not(.disabled), div.dt-button.btn-warning:hover:not(.disabled) {
    background-image: none;
    background-color: #ffcf8a;
    border-color: #ffba57
}

a.dt-button.btn-danger:hover:not(.disabled), button.dt-button.btn-danger:hover:not(.disabled), div.dt-button.btn-danger:hover:not(.disabled) {
    background-image: none;
    background-color: #ff8585;
    border-color: #ff5252
}

a.dt-button.btn-inverse:hover:not(.disabled), button.dt-button.btn-inverse:hover:not(.disabled), div.dt-button.btn-inverse:hover:not(.disabled) {
    background-image: none;
    background-color: #4c626d;
    border-color: #37474f
}

div.dt-button-collection a.dt-button.active:not(.disabled), div.dt-button-collection a.dt-button:active:not(.disabled), div.dt-button-collection button.dt-button.active:not(.disabled), div.dt-button-collection button.dt-button:active:not(.disabled), div.dt-button-collection div.dt-button.active:not(.disabled), div.dt-button-collection div.dt-button:active:not(.disabled) {
    background-color: #448aff;
    border-color: #448aff;
    background-image: none
}

div.dt-buttons {
    clear: both
}

.card .table-card-header b {
    display: block;
    color: #448aff;
    margin-top: 15px
}

.card .table-card-header span {
    color: #116aff;
    display: inline-block;
    margin-top: 0
}

div.dataTables_wrapper div.dataTables_info {
    display: inline-block
}

table.DTCR_clonedTable.dataTable {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 202
}

div.DTCR_pointer {
    width: 1px;
    background-color: #0259C4;
    z-index: 201
}

table.DTFC_Cloned tfoot, table.DTFC_Cloned thead {
    background-color: white
}

div.DTFC_Blocker {
    background-color: white
}

div.DTFC_LeftWrapper table.dataTable, div.DTFC_RightWrapper table.dataTable {
    margin-bottom: 0;
    z-index: 2
}

div.DTFC_LeftWrapper table.dataTable.no-footer, div.DTFC_RightWrapper table.dataTable.no-footer {
    border-bottom: none
}

td, th {
    white-space: nowrap
}

table.fixedHeader-floating {
    position: fixed;
    background-color: white
}

table.fixedHeader-floating.no-footer {
    border-bottom-width: 0
}

table.fixedHeader-locked {
    position: absolute;
    background-color: white
}

@media print {
    table.fixedHeader-floating {
        display: none
    }
}

table.dataTable td.focus, table.dataTable th.focus {
    outline: 3px solid #448aff;
    outline-offset: -1px
}

table.dataTable td.focus {
    outline: 1px solid #ff5252;
    outline-offset: -3px;
    background-color: #f8e6e6
}

#events {
    margin-bottom: 1em;
    padding: 1em;
    background-color: #f6f6f6;
    border: 1px solid #999;
    border-radius: 3px;
    height: 100px;
    overflow: auto
}

table.dt-rowReorder-float {
    position: absolute;
    opacity: 0.8;
    table-layout: fixed;
    outline: 2px solid #888;
    outline-offset: -2px;
    z-index: 2001
}

tr.dt-rowReorder-moving {
    outline: 2px solid #555;
    outline-offset: -2px
}

body.dt-rowReorder-noOverflow {
    overflow-x: hidden
}

table.dataTable td.reorder {
    text-align: center;
    cursor: move
}

#result {
    border: 1px solid #888;
    background: #f7f7f7;
    padding: 1em;
    margin-bottom: 1em
}

div.DTS {
    display: block
}

div.DTS tbody td, div.DTS tbody th {
    white-space: nowrap
}

div.DTS div.DTS_Loading {
    z-index: 1
}

div.DTS div.dataTables_scrollBody table {
    z-index: 2
}

div.DTS div.dataTables_length, div.DTS div.dataTables_paginate {
    display: none
}

.table td, .table th {
    padding: 1.25rem 0.75rem
}

.table thead th {
    border-bottom: 1px solid #d6dde1
}

.invoice-box .table td, .invoice-box .table th, .invoive-info .table td, .invoive-info .table th {
    padding: 3px 0
}

table.invoice-table td, table.invoice-table th {
    padding: 3px 0
}

th {
    font-weight: 600
}

table.dataTable.display tbody tr.odd, table.dataTable.stripe tbody tr.odd {
    background-color: #ddeaff
}

table.dataTable.display tbody tr.even > .sorting_1, table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
    background-color: #f7faff
}

table.dataTable.display tbody tr.odd > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
    background-color: #c4daff
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(68, 138, 255, 0.1)
}

.dataTables_filter .form-control {
    margin-top: 5px;
    margin-right: 5px
}

.dataTable.table td, .dataTable.table th {
    padding: 0.75rem
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
    top: 14px
}

.user-box {
    height: 100%
}

.user-box .media-object {
    height: 45px;
    width: 45px;
    display: inline-block
}

.p-chat-user {
    position: fixed;
    top: 0;
    right: 0;
    width: 280px;
    height: 100%;
    z-index: 1030;
    background-color: #fff;
    -webkit-box-shadow: -2px 0 11px -2px rgba(0, 0, 0, 0.3);
    box-shadow: -2px 0 11px -2px rgba(0, 0, 0, 0.3)
}

.p-chat-user .chat-search-box {
    padding: 20px
}

.p-chat-user .userlist-box {
    cursor: pointer;
    border-bottom: 1px solid #efefef
}

.p-chat-user .userlist-box .media-left {
    padding-right: 10px
}

.showChat_inner {
    position: fixed;
    top: 0;
    background-color: #fff;
    right: 0;
    display: none;
    width: 280px;
    height: 100%;
    z-index: 1032
}

.showChat_inner .chat-reply-box {
    background-color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px 20px 0;
    border-top: 1px solid #ccc
}

.showChat_inner .chat-inner-header {
    text-align: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    padding: 20px
}

.showChat_inner .chat-messages {
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px
}

.showChat_inner .chat-messages .chat-menu-content > div, .showChat_inner .chat-messages .chat-menu-reply > div {
    position: relative;
    overflow: visible;
    display: inline-block
}

.showChat_inner .chat-messages .chat-menu-content > div .chat-cont, .showChat_inner .chat-messages .chat-menu-reply > div .chat-cont {
    padding: 10px;
    margin-bottom: 0
}

.showChat_inner .chat-messages .chat-menu-content .chat-time, .showChat_inner .chat-messages .chat-menu-reply .chat-time {
    margin: 9px 8px 0 10px
}

.showChat_inner .chat-messages .chat-menu-reply {
    text-align: right;
    padding-right: 10px
}

.showChat_inner .chat-messages .chat-menu-reply > div {
    border-radius: 10px 10px 0;
    background: #ecf0f5
}

.showChat_inner .chat-messages .chat-menu-reply > div:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: -10px;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-left-color: #ecf0f5;
    border-bottom-color: #ecf0f5
}

.showChat_inner .chat-messages .chat-menu-content > div {
    background: #ddeaff;
    border-radius: 0 10px 10px
}

.showChat_inner .chat-messages .chat-menu-content > div:before {
    content: '';
    position: absolute;
    top: 0;
    left: -14px;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-right-color: #ddeaff;
    border-top-color: #ddeaff
}

.back_chatBox, .back_friendlist {
    margin: 0 auto;
    font-weight: 600
}

.back_chatBox i, .back_friendlist i {
    position: absolute;
    line-height: 18px;
    font-size: 20px;
    left: -40px;
    top: 80px;
    cursor: pointer;
    text-align: center;
    background-color: #fff;
    -webkit-box-shadow: -2px 0 9px -2px rgba(0, 0, 0, 0.3);
    box-shadow: -2px 0 9px -2px rgba(0, 0, 0, 0.3);
    padding: 12px 0 12px 7px;
    border-radius: 50% 0 0 50%;
    width: 40px;
    height: 40px
}

.users-main {
    height: 100%;
    width: 280px;
    position: fixed;
    border-radius: 0
}

.users-main .media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 0;
    padding-bottom: 10px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative
}

.users-main .media .chat-header {
    font-size: 14px;
    font-weight: 600
}

.users-main .media-body div + div {
    font-size: 12px
}

.photo-table img {
    display: inline-block;
    width: 40px;
    margin-bottom: 5px
}

.live-status {
    height: 10px;
    width: 10px;
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 100%;
    border: 1px solid
}

.showChat {
    display: none
}

.progress-xl {
    height: 30px
}

.progress-lg {
    height: 24px
}

.progres-md {
    height: 18px
}

.progress-sm {
    height: 12px
}

.progress-xs {
    height: 6px
}

.progress-bar-page .progress {
    margin-bottom: 2rem
}

.progress-bar-default {
    background-color: #d6d6d6
}

.progress-bar-primary {
    background-color: #448aff
}

.progress-bar-success {
    background-color: #9ccc65
}

.progress-bar-info {
    background-color: #00bcd4
}

.progress-bar-warning {
    background-color: #ffba57
}

.progress-bar-danger {
    background-color: #ff5252
}

.progress-bar-pink {
    background-color: #ff7aa3
}

.progress-bar-purple {
    background-color: #9261c6
}

.progress-bar-orange {
    background-color: #e67e22
}

.progress-bar-yellow {
    background-color: #f1c40f
}

.progress-bar-emrald {
    background-color: #2ecc71
}

@media only screen and (max-width: 1366px) {
    .img-tabs img {
        width: 75px
    }

    .card .card-block .sub-title {
        font-size: 12px
    }
}

@media only screen and (max-width: 1199px) {
    .pagination {
        float: inherit;
        text-align: center;
        display: inline-block;
        margin-top: 10px
    }

    .pagination li {
        float: left;
        margin-bottom: 10px
    }
}

@media only screen and (max-width: 992px) {
    .navbar, .navbar .navbar-logo {
        width: 100%
    }

    #dt-live-dom .form-control, #footer-search tfoot .form-control, #footer-select tfoot .form-control, #form-input-table .form-control, .search-api .form-control {
        width: 80%
    }

    .header-navbar {
        z-index: 1209;
        top: 0
    }

    .header-navbar[pcoded-header-position="relative"] {
        top: 0
    }

    .header-navbar .navbar-wrapper .navbar-container {
        margin-left: 0
    }

    .header-navbar .navbar-wrapper .navbar-logo {
        width: 100%
    }

    .header-navbar .navbar-wrapper .navbar-logo a img {
        display: block
    }

    .header-navbar .navbar-wrapper .navbar-logo .mobile-menu {
        right: auto;
        left: 0;
        top: 0;
        width: 60px
    }

    .header-navbar .navbar-wrapper .navbar-logo .mobile-search {
        display: block;
        position: absolute;
        left: 70px;
        top: 0
    }

    .header-navbar .navbar-wrapper .navbar-logo .mobile-options {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 16px;
        line-height: 3.5;
        width: 60px
    }

    .header-navbar .navbar-container .nav-left, .header-navbar .navbar-wrapper .navbar-container .nav-right {
        display: none
    }
}

@media only screen and (max-width: 991px) {
    .main-body .page-wrapper .align-items-end .page-header-title {
        text-align: center;
        display: block
    }

    .main-body .page-wrapper .align-items-end .page-header-title i {
        float: none;
        margin-bottom: 10px;
        margin-right: 0
    }

    .main-body .page-wrapper .align-items-end .page-header-breadcrumb {
        width: 100%;
        text-align: center;
        margin-top: 10px;
        display: block
    }

    .page-header-breadcrumb .breadcrumb-item {
        display: inline-block;
        float: none
    }
}

@media only screen and (max-width: 768px) {
    h4 {
        font-size: 1rem;
        font-weight: 500
    }

    .card h5 {
        font-size: 16px
    }

    .header-navbar .navbar-wrapper .navbar-logo {
        display: inherit
    }

    .header-navbar .navbar-wrapper .navbar-logo a {
        display: inline-block
    }

    body.horizontal-icon .page-header, body.horizontal-icon-fixed .page-header {
        margin-top: 0
    }

    body.horizontal-icon .pcoded .pcoded-navbar .pcoded-item > li > a > .pcoded-micon, body.horizontal-icon-fixed .pcoded .pcoded-navbar .pcoded-item > li > a > .pcoded-micon {
        display: inline-block;
        margin-right: 20px
    }
}

@media only screen and (max-width: 991px) {
    .main-body .page-wrapper .align-items-end .page-header-title {
        text-align: center;
        display: block
    }

    .main-body .page-wrapper .align-items-end .page-header-title i {
        float: none;
        margin-bottom: 10px
    }

    .main-body .page-wrapper .align-items-end .page-header-breadcrumb {
        width: 100%;
        text-align: center;
        margin-top: 10px;
        display: block
    }

    .page-header-breadcrumb .breadcrumb-item {
        display: inline-block;
        float: none
    }
}

@media only screen and (max-width: 993px) {
    .navbar, .navbar .navbar-logo {
        width: 100%
    }
}

@media only screen and (max-width: 573px) {
    .md-tabs {
        display: initial
    }

    .md-tabs .nav-item {
        width: calc(100% / 1);
        position: relative
    }

    .nav-tabs.tabs {
        display: inherit
    }

    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
        border: none
    }
}

@media only screen and (max-width: 480px) {
    .main-body .page-wrapper {
        padding: 10px
    }
}

@media only screen and (max-width: 576px) {
    .pcoded .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification, .pcoded .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification {
        min-width: 280px;
        left: -30px;
        right: auto;
        width: auto
    }

    .pcoded .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification:before, .pcoded .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification:before {
        right: calc(100% - 40px)
    }

    .pcoded .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification {
        left: auto;
        right: 0
    }

    .pcoded .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification:before {
        right: 40px
    }

    .nav-tabs .nav-link.active {
        margin-bottom: 0
    }

    body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification, body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification {
        left: auto;
        right: -40px
    }

    body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification:before, body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification:before {
        left: auto;
        right: 40px
    }

    body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification {
        right: auto;
        left: 0
    }

    body .pcoded[vertical-placement="right"] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification:before {
        right: calc(100% - 40px)
    }
}

.pcoded[layout-type="dark"] {
    color: #fff
}

.pcoded[layout-type="dark"] a {
    color: #fff
}

.pcoded[layout-type="dark"] p {
    color: #dcdcdc
}

.pcoded[layout-type="dark"] text {
    fill: #fff
}

.pcoded[layout-type="dark"] .card {
    background-color: #4f4f4f
}

.pcoded[layout-type="dark"] .card code {
    background-color: #5c5c5c;
    color: #fff
}

.pcoded[layout-type="dark"] .card code[class*="language-"], .pcoded[layout-type="dark"] .card pre[class*="language-"] {
    color: #5c5c5c
}

.pcoded[layout-type="dark"] .card .card-header h5 {
    color: #fff
}

.pcoded[layout-type="dark"] .card .card-header .card-header-right i {
    color: #fff
}

.pcoded[layout-type="dark"] .card .card-block code {
    background-color: #5c5c5c
}

.pcoded[layout-type="dark"] .card .card-block code.language-markup {
    background-color: #fff
}

.pcoded[layout-type="dark"] .card .card-block .dropdown-menu {
    background-color: #545454
}

.pcoded[layout-type="dark"] .card-footer {
    background-color: #4a4a4a
}

.pcoded[layout-type="dark"] .dropdown-menu {
    background-color: #545454
}

.pcoded[layout-type="dark"] .dropdown-menu .dropdown-item .dropdown-divider {
    border-top: 1px solid #424242
}

.pcoded[layout-type="dark"] .dropdown-menu .dropdown-item:focus, .pcoded[layout-type="dark"] .dropdown-menu .dropdown-item:hover {
    background-color: #424242
}

.pcoded[layout-type="dark"] .sub-title {
    color: #757575
}

.pcoded[layout-type="dark"] .b-l-default, .pcoded[layout-type="dark"] .b-l-theme {
    border-left: 1px solid #5c5c5c
}

.pcoded[layout-type="dark"] .b-r-default, .pcoded[layout-type="dark"] .b-r-theme, .pcoded[layout-type="dark"] .messages-content {
    border-right: 1px solid #5c5c5c
}

.pcoded[layout-type="dark"] .btn-default {
    background-color: #5c5c5c;
    color: #fff
}

.pcoded[layout-type="dark"] .pcoded-main-container {
    background-color: #5c5c5c
}

.pcoded[layout-type="dark"][vertical-layout="box"] .pcoded-container, .pcoded[layout-type="dark"][theme-layout="vertical"][vertical-nav-type="collapsed"] .pcoded-container {
    background-color: #5c5c5c
}

.pcoded[layout-type="dark"] .progress {
    background-color: #5c5c5c
}

.pcoded[layout-type="dark"] .alert.alert-danger, .pcoded[layout-type="dark"] .alert.alert-default, .pcoded[layout-type="dark"] .alert.alert-info, .pcoded[layout-type="dark"] .alert.alert-primary, .pcoded[layout-type="dark"] .alert.alert-success, .pcoded[layout-type="dark"] .alert.alert-warning {
    background-color: #424242
}

.pcoded[layout-type="dark"] .alert.background-default {
    background-color: #d6d6d6;
    color: #fff
}

.pcoded[layout-type="dark"] .alert.background-primary {
    background-color: #448aff;
    color: #fff
}

.pcoded[layout-type="dark"] .alert.background-success {
    background-color: #9ccc65;
    color: #fff
}

.pcoded[layout-type="dark"] .alert.background-info {
    background-color: #00bcd4;
    color: #fff
}

.pcoded[layout-type="dark"] .alert.background-warning {
    background-color: #ffba57;
    color: #fff
}

.pcoded[layout-type="dark"] .alert.background-danger {
    background-color: #ff5252;
    color: #fff
}

.pcoded[layout-type="dark"] .accordion-msg {
    color: #fff;
    border-top: 1px solid #5c5c5c
}

.pcoded[layout-type="dark"] .accordion-desc {
    color: #fff
}

.pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .nav-right > .header-notification:nth-child(1) .show-notification li:first-child:hover, .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .nav-right > .header-notification:nth-child(1) .profile-notification li:first-child:hover {
    background-color: #424242 !important
}

.pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification, .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification, .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification {
    color: #fff;
    background-color: #424242
}

.pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification.show:before, .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification.show:before, .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .show.profile-notification:before {
    border-left-color: #424242;
    border-top-color: #424242
}

.pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification a, .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification a, .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification a {
    color: #fff
}

.pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:hover, .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li:hover, .pcoded[layout-type="dark"] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:hover {
    background-color: #5c5c5c
}

.pcoded[layout-type="dark"] .p-chat-user {
    background-color: #4f4f4f
}

.pcoded[layout-type="dark"] .p-chat-user .userlist-box {
    border-bottom: 1px solid #5c5c5c
}

.pcoded[layout-type="dark"] .p-chat-user .userlist-box .chat-header {
    color: #fff
}

.pcoded[layout-type="dark"] .back_chatBox i, .pcoded[layout-type="dark"] .back_friendlist i {
    background-color: #4f4f4f;
    color: #fff
}

.pcoded[layout-type="dark"] .showChat_inner {
    background-color: #4f4f4f
}

.pcoded[layout-type="dark"] .showChat_inner .chat-messages .chat-menu-content > div {
    background-color: #424242
}

.pcoded[layout-type="dark"] .showChat_inner .chat-messages .chat-menu-content > div:before {
    border-right-color: #424242;
    border-top-color: #424242
}

.pcoded[layout-type="dark"] .showChat_inner .chat-messages .chat-menu-reply > div {
    background-color: #5c5c5c
}

.pcoded[layout-type="dark"] .showChat_inner .chat-messages .chat-menu-reply > div:before {
    border-left-color: #5c5c5c;
    border-bottom-color: #5c5c5c
}

.pcoded[layout-type="dark"] .showChat_inner .chat-inner-header {
    border-bottom: 1px solid #424242
}

.pcoded[layout-type="dark"] .showChat_inner .chat-reply-box {
    background-color: #4f4f4f;
    border-top: 1px solid #424242
}

.pcoded[layout-type="dark"] .form-control {
    background-color: transparent;
    color: #fff
}

.pcoded[layout-type="dark"] select.form-control {
    background-color: #4f4f4f
}

.pcoded[layout-type="dark"] .bg-white {
    background: #4f4f4f !important
}

.pcoded[layout-type="dark"] .card-header-img h5, .pcoded[layout-type="dark"] .card-header-img h6 {
    color: #fff
}

.pcoded[layout-type="dark"] .text-inverse {
    color: #fff !important
}

.pcoded[layout-type="dark"] #styleSelector {
    color: #424242
}

.pcoded[layout-type="dark"] #styleSelector .form-control {
    color: #424242
}

.pcoded[layout-type="dark"] .chat-card .chat-control .chat-form .form-control {
    background-color: transparent
}

.pcoded[layout-type="dark"] .chat-card .chat-control .chat-form .form-control:active, .pcoded[layout-type="dark"] .chat-card .chat-control .chat-form .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.pcoded[layout-type="dark"] .table > thead > tr > th {
    border-bottom-color: #5c5c5c
}

.pcoded[layout-type="dark"] .table td, .pcoded[layout-type="dark"] .table th {
    border-top-color: #5c5c5c
}

.pcoded[layout-type="dark"] .modal-content {
    background-color: #424242
}

.pcoded[layout-type="dark"] .modal-content .close {
    color: #fff
}

.pcoded[layout-type="dark"] .modal-content .modal-header {
    border-bottom-color: #5c5c5c
}

.pcoded[layout-type="dark"] .modal-content .modal-footer {
    border-top-color: #5c5c5c
}

.pcoded[layout-type="dark"] .main-menu .main-menu-header:before, .pcoded[layout-type="dark"] .page-header:before {
    background-color: #4f4f4f
}

.pcoded[layout-type="dark"] .bg-c-blue {
    background: #448aff
}

.pcoded[layout-type="dark"] .bg-c-red {
    background: #ff5252
}

.pcoded[layout-type="dark"] .bg-c-green {
    background: #9ccc65
}

.pcoded[layout-type="dark"] .bg-c-yellow {
    background: #ffba57
}

.pcoded[layout-type="dark"] .bg-c-orenge {
    background: #FE8A7D
}

.pcoded[layout-type="dark"] .bg-c-lite-green {
    background: #69CEC6
}

.pcoded[layout-type="dark"] .bg-c-purple {
    background: #536dfe
}

/*
	Buy now button code
*/

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

.fixed-button {
    position: fixed;
    bottom: -50px;
    right: 30px;
    -webkit-box-shadow: 0 15px 50px rgba(0, 0, 0, 0.35);
    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.35);
    opacity: 0;
    z-index: 9;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    animation: 1s pulse infinite;
}

.fixed-button.active {
    bottom: 50px;
    opacity: 1;
}

.btn-buy {
    border: 1px solid #7AA93C;
    background-color: #7AA93C;
    color: #fff;
    transition: all 0.3s ease-in-out;
}

.btn-buy:hover {
    border: 1px solid #7AA93C;
    background-color: #fff;
    color: #7AA93C;
}

/** MOC Changes */
#carousel-principal .carousel-item > img {
    max-height: 350px;
    height: 350px;
    min-width: 100%;
}

#outros-destaques .carousel-item img {
    max-height: 250px !important;
    height: 250px !important;
    min-height: 250px !important;
    min-width: 100%;
}

.bg-theme1, .bg-pms {
    background-color: #29548E !important;
}

.text-theme1, .text-pms {
    color: #29548E !important;
}

#carousel-principal .carousel-caption, #outros-destaques .carousel-caption {
    text-align: left !important;
    left: 5%;
}

#carousel-principal .carousel-caption span, #outros-destaques .carousel-caption span {
    margin: -2px;
}

#carousel-principal .carousel-caption p, #outros-destaques .carousel-caption p {
    margin: -6px;
}

span.recentes, span.populares {
    position: absolute !important;
    margin-top: -15px !important;
}

.carousel-control-next, .carousel-control-prev {
    z-index: 10 !important;
}

.btn-group-xs > .btn, .btn-xs {
    padding: .25rem .4rem;
    font-size: .875rem;
    line-height: .5;
    border-radius: .2rem;
}

.conteudo p {
    font-size: 150%;
}

@media only screen and (max-width: 600px) {
    .pcoded-navbar {
        top: 40px;
    }
}
